<div class="modal-container">
  <div class="modal-dialog">
    <div class="modal-content px-5 py-5 bg-white rounded">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"></h5>

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeMe()"></button>
    </div>
        <div class="modal-body px-4 py-4">

          <div class="text1 mt-2">{{ title }}.</div>
          <div class="text1">{{ body }}</div>


          <div class="btn-container">

          </div>

            <!-- <button class="btn btn-primary" (click)='confirmaBorrar(rutaBorrar);'>Continuar</button> -->

        </div>
        <div class="modal-footer justify-content-center mt-2 pt-3">
          <!-- <button (click)="closeMe()" class="btn btn-primary" >Cancelar</button> -->
          <button (click)="confirm()" class="btn btn-secondary btn-pop" >Continuar</button>
        </div>
    </div>
</div>






</div>

