<section class="section" id="avisolegal">
    <div class="container d-flex justify-content-center">
        <div class="row" style="width: 950px; max-width: 950px">
            <div class="row" style="padding-top: 20px">
                <div class="col-md-12" style="
              padding: 0px 40px;
              display: flex;
  
              flex-flow: column wrap;
            ">
                    <h1>Política de cookies</h1>
                </div>
            </div>
            <div id="misrutasContainer" class="rounded" #container>
                <div class="row">
                    <div class="col-12 px-5 pt-4">
                        <h3>Información general</h3>
                        <p>Debido a la entrada en vigor de la referente modificación de la &ldquo;Ley de Servicios de la Sociedad de la Información&rdquo; (LSSICE) establecida por el Real Decreto 13/2012, es de obligación obtener el consentimiento expreso
                            del usuario de todas las páginas web que usan cookies prescindibles, antes de que éste navegue por ellas.</p>
                        <h4>¿Qué son las cookies?</h4>
                        <p>Las cookies y otras tecnologías similares tales como local shared objects, flash cookies o píxeles, son herramientas empleadas por los servidores Web para almacenar y recuperar información acerca de sus visitantes, así como para
                            ofrecer un correcto funcionamiento del sitio.</p>
                        <p>Mediante el uso de estos dispositivos se permite al servidor Web recordar algunos datos concernientes al usuario, como sus preferencias para la visualización de las páginas de ese servidor, nombre y contraseña, productos que más
                            le interesan, etc.</p>
                        <h4>Cookies afectadas por la normativa y cookies exceptuadas</h4>
                        <p>Según la directiva de la UE, las cookies que requieren el consentimiento informado por parte del usuario son las cookies de analítica y las de publicidad y afiliación, quedando exceptuadas las de carácter técnico y las necesarias
                            para el funcionamiento del sitio web o la prestación de servicios expresamente solicitados por el usuario.</p>
                        <h4>¿Qué tipos de cookies existen?</h4>
                        <br>
                        <h5>Según su titularidad</h5>
                        <div>
                            <div><strong>Cookies propias</strong>: Son aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio Sodivcitado por el usuario.</div>
                            <div><strong>Cookies de terceros</strong>: Son aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las
                                cookies.
                            </div>
                        </div>
                        <br>
                        <h5>Según su duración</h5>
                        <div>
                            <div><strong>Cookies de sesión:</strong> Son un tipo de cookies diseñadas para recabar y almacenar datos mientras el usuario accede a una página web.</div>
                            <div><strong>Cookies persistentes</strong>: Son un tipo de cookies en el que los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede
                                ir de unos minutos a varios años.</div>
                        </div>
                        <br>
                        <h5>Según su funcionalidad</h5>
                        <div>
                            <div><strong>Cookies necesarias</strong>: son aquellas imprescindibles para el correcto funcionamiento del sitio web. No recopilan ningún tipo de datos de usuario y recogen información como la preferencia de idioma, si se ha aceptado
                                la política de cookies, etc.</div>
                            <div><strong>Cookies de análisis</strong>: Son aquéllas que bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen
                                los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.</div>
                            <div><strong>Cookies publicitarias o de marketing</strong>: Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación
                                o plataforma desde la que presta el servicio solicitado en base a criterios como el contenido editado o la frecuencia en la que se muestran los anuncios.</div>
                            <div><strong>Cookies de publicidad comportamental o de marketing personalizado</strong>: Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido
                                en una página web, aplicación o plataforma desde la que presta el servicio solicitado. Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos
                                de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo. Asimismo es posible que al visitar alguna página web o al abrir algún email donde se publique algún anuncio o
                                alguna promoción sobre nuestros productos o servicios se instale en tu navegador alguna cookie que nos sirve para mostrarte posteriormente publicidad relacionada con la búsqueda que hayas realizado, desarrollar un control
                                de nuestros anuncios en relación, por ejemplo, con el número de veces que son vistos, donde aparecen, a qué hora se ven, etc.</div>
                        </div>
                        <br>
                        <!-- <h3>Declaración de Cookies</h3>
                        <p>Completa la siguiente tabla con información de todas las cookies que se utilizan en tu sitio web.</p>
                        <table>
                            <thead>
                                <tr>
                                    <th><strong>Cookie</strong></th>
                                    <th><strong>Propietario</strong></th>
                                    <th><strong>Duración</strong></th>
                                    <th><strong>Finalidad</strong></th>
                                    <th><strong>Tipo</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><em>Nombre cookie</em></td>
                                    <td><em>www.dominio.com</em></td>
                                    <td><em>X horas</em></td>
                                    <td><em>Propósito de esta cookie</em></td>
                                    <td><em>Tipo</em></td>
                                </tr>
                            </tbody>
                        </table> -->
                        <!-- <h3>Revocación del consentimiento para instalar Cookies</h3>
                        <p>Explica en este apartado cómo  pueden los usuarios retirar o modificar su consentimiento para el uso de cookies.</p> -->
                        <h4>Cómo eliminar las Cookies del navegador.</h4>
                        <div>
                            <p>Si no deseas que los sitios web pongan ninguna cookie en tu equipo, puedes adaptar la configuración del navegador de modo que se te notifique antes de que se descargue alguna. De igual modo, puedes adaptar la configuración
                                de forma que el navegador rechace todas las cookies, o únicamente las cookies de terceros. También puedes eliminar cualquiera de las cookies que ya se encuentren en tu equipo. Ten en cuenta que tendrás que adaptar por separado
                                la configuración de cada navegador y equipo que utilices.</p>
                            <p>Docentes en Ruta pone a disposición de los usuarios que quieran impedir la instalación de las mencionadas cookies, enlaces facilitados al efecto por navegadores cuyo uso se considera más extendido:</p>
                        </div>
                        <h4>Cómo eliminar las Cookies del navegador.</h4>
                        <div>
                            <p>

                                Chrome:
                                <a href="https://support.google.com/chrome/answer/95647" target="_blank">Pincha aquí para ver cómo elimiar cookies de Chrome</a>
                            </p>


                            <p>Internet Explorer:<a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Pincha aquí para ver cómo elimiar cookies de Internet Explorer</a></p>

                            <p>Firefox:<a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias" target="_blank">Pincha aquí para ver cómo elimiar cookies de Mozilla</a></p>

                            <p>Safari:<a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac" target="_blank">Pincha aquí para ver cómo elimiar cookies de Safari</a></p>

                            <p>Opera:<a href="https://www.opera.com/help/tutorials/security/privacy/" target="_blank">Pincha aquí para ver cómo elimiar cookies de Opera</a></p>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    </div>
</section>