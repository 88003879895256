<section class="section" id="login">
  <div class="container d-flex justify-content-center">
    <div class="row" style="width: 950px; max-width: 950px">
      <div class="row" style="padding-top: 20px">
        <div class="col-md-12" style="
          padding: 0px 40px;
          display: flex;

          flex-flow: column wrap;
        ">

          <h1>Introduce la nueva contraseña</h1>
        </div>
      </div>
        <!-- modal -->
        <div class="fade none modal-container" #exampleModal tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
              <div class="modal-content p-3">
                  <div class="modal-header pb-0 border-0">
                      <h5 class="modal-title" id="exampleModalLabel"></h5>

                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
                  </div>
                  <div class="modal-body p-4 pb-3">
                    <div class="d-flex justify-content-center">
                        <img class="img-fluid" src="./assets/images/ok_1.jpg" alt="" />
                    </div>
                    <div class="d-flex justify-content-center mt-4 text-center">
                  {{this.mensajePop}}


                </div>

                <div class="modal-footer border-0 d-flex justify-content-center pb-5 mt-4">
                  <button *ngIf="verified" class="btn btn-primary me-1" [routerLink]="['/login']">Ir a login</button>
                    <button type="button" class="btn btn-secondary btn-pop ms-1" data-bs-dismiss="modal" (click)="close()">
                        Cerrar
                    </button>
                </div>
              </div>
          </div>
      </div>
      </div>
      <!------>
      <div id="misrutasContainer" class="rounded" #container>
        <div class="row">
          <div class="col-lg-7">
            <div class="g-4 maxWidth1100" style="margin: 0 auto; text-align: left">
              <div class="container-fluid" style="padding-top: 20px">
                <div *ngIf="!verified" class="alert alert-warning"><p>Tu solicitud ha caducado, vuelve a solicitar la recuperación de la contraseña</p>
                  <button class="btn btn-primary" [routerLink]="['/reset-password']">Ir a recuperar contraseña</button>
                  </div>

                  <div class="wrap-login100 p-t-50 p-b-90 " *ngIf="!cambioOk && verified">

                    <form class="login100-form validate-form flex-sb flex-w" (ngSubmit)="onSubmit( registroForm)"
                      #registroForm="ngForm" >



                      <div id="error1"></div>
                      <div class="wrap-input100 ">
                        <input class="input100 form-control" type="password" name="password" placeholder="Contraseña"
                          [(ngModel)]="usuario.password" required minlength="4">

                        <div *ngIf="registroForm.submitted && registroForm.controls['password'].errors"
                        class="text-danger animated fadeIn mt-2"> El pass tiene que tener mínimo 4 letras
                      </div>
                        <span class="focus-input100"></span>
                      </div>

                      <div id="error2"></div>
                    <div class="wrap-input100 mt-3 ">
                      <input class="input100 form-control" type="password" name="confirmPassword" placeholder="Confirmar contraseña"
                        [(ngModel)]="confirmPassword" required minlength="4">
                        <div *ngIf="passwordOk === false"
                        class="text-danger animated fadeIn mt-2"> Las contraseñas no coinciden
                      </div>
                      <span class="focus-input100"></span>
                    </div>
                      <br>

                      <button class="btn btn-primary">Enviar</button>

                    </form>
                  </div>
                  <div id="alertSuccess"></div>
                  <div *ngIf="cambioOk" class="alert alert-warning" id="alertaOk"><p>Tu contraseña se ha cambiado correctamente</p>
                    <button class="btn btn-primary" [routerLink]="['/login']">Ir a login</button>
                  </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 d-flex align-items-lg-end mb-5">
            <img width="200" class="img-fluid mt-5 mt-lg-0" src="./assets/images/login.png" alt="" />
          </div>
        </div>
      </div>
      <!-- login container-->
    </div>
  </div>
</section>
