
import { LocalidadesService, Localidad } from './../../services/localidades.service';
import { ProvinciasService, Provincia } from './../../services/provincias.service';
import { CentrosService, Centro } from './../../services/centros.service';
import { UserService } from './../../services/user.service';
import { RutaModel } from './../../models/ruta.model';
import { ProvinciaModel } from './../../models/provincia.model';
import { LocalidadModel } from 'src/app/models/localidades.model';
import { RutaComponent } from './../ruta/ruta.component';
import { Router } from '@angular/router';
import { RutasService } from './../../services/rutas.service';
import { Component, OnInit, Renderer2, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { EmailValidator, NgForm } from '@angular/forms';
import { NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';





@Component({
  selector: 'app-crear-ruta',
  templateUrl: './crear-ruta.component.html',
  styleUrls: ['./crear-ruta.component.css']
})
export class CrearRutaComponent implements OnInit {

  ruta: RutaModel;
  provincia: ProvinciaModel;
  localidad: LocalidadModel;
  public centros: Centro[] = [];
  public localidadesFiltradas: Localidad[] = [];
  public localidadesDestino: Localidad[] = [];
  public localidadesOrigen: Localidad[] = [];
  public provincias: Provincia[] = [];
  showFormOk = false;
  selectDestino;
  selectOrigen;
  origenLocalidad;
  destinoLocalidad;
  destinoCentro;
  modalMensaje;
  unCentro;
  toggleClass = false;
  public searchInput = '';
  public searchResult: Array<any> = [];
  public seriesList: Array<any> = [];
  centroOk = false;
  submitForm = false;
  fechaInicio;
  fechaFin;
  fechaFinDia;
  fechaFinYear;
  fechaFinMes;
  fechaInicioPicker;
  fechaFinPicker;
  fechaIniSelected;
  fechaFinSelected;
  //finDeSemana;

  constructor(
    private rutasService: RutasService,
    private userService: UserService,
    private centrosService: CentrosService,
    private localidadesService: LocalidadesService,
    private provinciasService: ProvinciasService,
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer2) { }

  @ViewChild('exampleModal', { static: false }) divModal: ElementRef;
  @ViewChild('modalfecha', { static: false }) divModalFecha: ElementRef;

  fetchSeries(event: any) {

    if (event.target.value === '') {
      return this.searchResult = [];

    }

    this.searchResult = this.centros.filter((centros) => {

      return centros.nombre.toLowerCase().replace(/é/g, 'e').replace(/é/g, 'e').replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u').includes(event.target.value.toLowerCase().replace(/é/g, 'e').replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u'));
    });
  }
  clica(value) {

    this.searchResult = [];
    this.destinoCentro = value;
    this.centroOk = true;

  }

  borraCentro() {
    this.searchResult = [];
    this.destinoCentro = '';
  }


  ngOnInit(): void {
    this.ruta = new RutaModel();

    this.provincia = new ProvinciaModel();
   // this.ruta.finDeSemana = 'no';
    // this.getCentros();
    this.getProvincias();
    // this.getLocalidades();
    if (this.rutasService.params.origenProvincia !== '') {
      this.getParams();
    }



  }

  selectInicio(event: any){
    this.fechaIniSelected = true;

  }
  selectFin(event: any){
    this.fechaFinSelected = true;
  }
  selectChange() {
    this.getLocalidadesDestino();
  }

  selectChangeOrigen() {
    this.getLocalidadesOrigen();
    this.toggleClass = !this.toggleClass;
  }

  selectChangeCentro() {

    this.getCentros();
  }

  getProvincias() {

    this.provinciasService.getProvincias().subscribe((res: any) => {

      this.provincias = res.provincias;

    });
  }

  getLocalidadesDestino() {
    let id = this.selectDestino;
    this.localidadesService.getLocalidades(id).subscribe((res: any) => {

      this.localidadesDestino = res.localidades;

    });
  }
  getLocalidadesOrigen() {
    let id = this.selectOrigen;
    this.localidadesService.getLocalidades(id).subscribe((res: any) => {

      this.localidadesOrigen = res.localidades;

    });
  }

  getCentros() {
    let id = this.destinoLocalidad;

    this.centrosService.getCentros(id).subscribe((res: any) => {
      this.centros = res.centros;
      if (this.centros.length < 2) {
        this.unCentro = true;

      } else { this.unCentro = false; }

    });

  }



  onSubmit(form: NgForm) {

    if (form.invalid) {
      this.submitForm = true;
      //  this.modalMensaje = 'ERROR',  this.open();
      return;
    }



    // this.ruta.creador = this.userService.usuarioLogged._id;
    this.ruta.cerrada = false;
    this.ruta.creador = this.userService.usuarioLogged;

    this.ruta.origenLocalidad = this.origenLocalidad;
    this.ruta.destinoCentro = this.destinoCentro;
    this.ruta.destinoLocalidad = this.destinoLocalidad;
    //this.ruta.fechaInicio = this.fechaInicio;

    // this.fechaFinMes = this.fechaFin.month;
    // this.fechaFinYear = this.fechaFin.year;
    // this.fechaFinDia = this.fechaFin.day;


    let dateInicio : Date = new Date(this.fechaInicioPicker.year, (this.fechaInicioPicker.month) - 1, (this.fechaInicioPicker.day) + 1);
    let dateInicioISO = dateInicio.toISOString().slice(0, -14);
    this.ruta.fechaInicio = dateInicioISO;


    let dateFin : Date = new Date(this.fechaFinPicker.year, (this.fechaFinPicker.month) - 1, (this.fechaFinPicker.day) + 1);
    let dateFinISO = dateFin.toISOString().slice(0, -14);
    this.ruta.fechaFin = dateFinISO;

    let dateStart = new Date(this.ruta.fechaFin.slice(0, 4), this.ruta.fechaFin.slice(5, 7) - 1, this.ruta.fechaFin.slice(8, 10));
    let dateEnd = new Date(this.ruta.fechaInicio.slice(0, 4), this.ruta.fechaInicio.slice(5, 7) - 1, this.ruta.fechaInicio.slice(8, 10));

    if ( this.fechaIniSelected && this.fechaFinSelected){
      if (Date.parse(dateStart.toString()) < Date.parse(dateEnd.toString())){

        this.openFecha();

       }else{

         if (this.unCentro) { this.ruta.aceptaParadas = 'no'; }
         this.showFormOk = true;

         this.rutasService.agregarRuta(this.ruta).subscribe((res: any) => {
         });


     }

    }else{


    }



    // this.ruta.fechaFin = this.fechaFinYear + '-' + this.fechaFinMes + '-' + this.fechaFinDia;
    // this.ruta.fechaFin = this.fechaFin;


    // if (this.ruta.destinoCentro == null){
    //   this.ruta.destinoCentro = null;
    // }

    // this.modalMensaje = 'ERROR',  this.open();


  }

  open() {
    this.renderer.removeClass(this.divModal.nativeElement, 'none');
    this.renderer.addClass(this.divModal.nativeElement, 'modal');
  }

  close() {
    this.renderer.removeClass(this.divModal.nativeElement, 'modal');
    this.renderer.addClass(this.divModal.nativeElement, 'none');
  }

  openFecha() {
    this.renderer.removeClass(this.divModalFecha.nativeElement, 'none');
    this.renderer.addClass(this.divModalFecha.nativeElement, 'modal');
  }

  closeFecha() {
    this.renderer.removeClass(this.divModalFecha.nativeElement, 'modal');
    this.renderer.addClass(this.divModalFecha.nativeElement, 'none');
  }
  getParams() {

    this.selectOrigen = this.rutasService.params.origenProvincia;
    this.origenLocalidad = this.rutasService.params.origenLocalidad;
    this.destinoLocalidad = this.rutasService.params.destinoLocalidad;
    this.selectDestino = this.rutasService.params.destinoProvincia;
    this.destinoCentro = this.rutasService.params.destinoCentro;
   // this.finDeSemana = this.rutasService.params.finDeSemana;


    this.selectChangeOrigen();
    this.selectChange();
    this.selectChangeCentro();


  }

  change() {

    if (this.ruta.finDeSemana === false) {

      this.ruta.finDeSemana = 'no';


    } else {
      this.ruta.finDeSemana = 'si';



    }

  }

}
