<section class="section" id="editarRuta">

    <div class="container">
        <div class="row mt-3 pt-2">
            <div class="col-md-2" style=" background: url('./assets/images/img_rutas_right.jpg') no-repeat 0 -36px;">
            </div>
            <!--col lat izq-->

            <div class="col-md-8 px-lg-4 px-md-4 px-3">

                <h1>Editar ruta</h1>


                <form class="login100-form validate-form flex-sb flex-w" (ngSubmit)="onSubmit(editarForm)" #editarForm="ngForm">
                    <div class="container-fluid px-0">

                        <!-------------------------------PRIMERA FILA----------------------------------->

                        <div class="row mt-4 mb-4">
                            <div class="col-md-6">
                                <div class="width-150">
                                    <label for="origen" class="col-form-label">Origen</label>
                                </div>
                                <div class="col-auto">
                                    <h5>{{ ruta.origenLocalidad }}</h5>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="width-150">
                                    <label for="destino" class="col-form-label">Destino</label>
                                </div>
                                <div class="col-auto">
                                    <h5>
                                        {{ ruta.destinoLocalidad }}
                                        <span *ngIf="ruta.destinoCentro"></span><br> {{ ruta.destinoCentro }}
                                    </h5>
                                    <h5></h5>
                                </div>
                            </div>
                        </div>
                        <hr>

                        <div class="row mt-4 mb-2">
                            <div class="col-md-6">
                                <div class="width-150">
                                    <label for="origen" class="col-form-label">Fecha inicio</label>
                                </div>
                                <div class="col-auto">
                                    <h5>{{ ruta.fechaInicio }}</h5>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="width-150">
                                    <label for="destino" class="col-form-label">Fecha Fin</label>
                                </div>
                                <div class="col-auto">
                                    <h5>
                                        {{ ruta.fechaFin }}
                                        <span *ngIf="ruta.destinoCentro"></span><br> {{ ruta.destinoCentro }}
                                    </h5>
                                    <h5></h5>
                                </div>
                            </div>
                        </div>


                      <div class="row pb-4">

                        <div class="col-12 col-md-6">
                          <label class="col-form-label mb-0 ">
                        Nueva fecha de inicio
                          </label>
                      <div class="input-group">
                        <input class="form-control" placeholder="Selecciona fecha"
                               name="dpinicio" [(ngModel)]="fechaInicioPicker" ngbDatepicker #d="ngbDatepicker">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                      </div>
                    </div>
                        <div class="col-12 col-md-6">

                          <label class="col-form-label mb-0">
                      Nueva fecha de fin
                          </label>
                      <div class="input-group">
                        <input class="form-control" placeholder="Selecciona fecha"
                               name="dpfin" [(ngModel)]="fechaFinPicker" ngbDatepicker #f="ngbDatepicker">
                        <button class="btn btn-outline-secondary calendar" (click)="f.toggle()" type="button"></button>
                      </div>
                    </div>

                    </div>
                    <hr>
                        <!---------------------------------SEGUNDA FILA-------------------------------->

                        <div class="row mt-3">
                            <div class="col-md-12">
                                <div class="width-150 mb-2" *ngIf="usuarios">
                                    <p class="col-form-label mb-0 bold">Usuarios</p>
                                    <p class="mt-0 extraInfo py-2">
                                        * Si quieres emilinar a algún usuario, seleccionalo y no olvides pinchar en actualizar datos
                                    </p>
                                    <ul class="option-row position-relative list-unstyled">

                                        <div *ngFor="let li of ruta.usuarios; index as i" class="option-group">
                                            <li class="w-100 d-flex align-items-center mt-3">
                                                <input type="checkbox" style="width: 20px; height: 20px" class="p-2 shadow-none form-check-input ms-3 me-3 mt-0 check" id="option-{{ li }}" [value]="li" [(ngModel)]="li.selected" (click)="setCheckbox(li, i)" />
                                                <div class="rounded pt-2 pb-2 ps-3 pe-4 mb-0 row w-100" [ngClass]="{ 'alert-danger': li.selected }" for="option-{{ li }}">
                                                    <div class="col-md-12 col-12"><span class="bold">{{
                                                            ruta.usuarios[i].username
                                                            }}</span></div>
                                                    <div class="col-md-12 col-12"> {{ ruta.usuarios[i].email }}</div>

                                                    <div class="col-md-12 col-12">
                                                        <div *ngFor="let parada of getParadasByUser(ruta.usuarios[i].email)">

                                                            <div>
                                                                {{ parada.parada }}
                                                            </div>

                                                        </div>
                                                    </div>



                                                    <!-- <div class="col-md-4" *ngFor="
                                                    let parada of ruta.paradas | unique;
                                                    index as i
                                                ">
                                                        <div>{{ ruta.paradas[i].parada }}</div>
                                                    </div> -->



                                                </div>
                                                <!-- <div class=" bg-white text-black pt-2 pb-2 ps-4 pe-4 rounded mt-1" [ngClass]="{showButtonUsuario: !disableUsuario, hideButtonUsuario: disableUsuario}">Vas a eliminar a este usuario de la ruta

                                              </div> -->
                                            </li>
                                        </div>

                                        <!-- <button type="button" (click)="saveOptions();">Submit</button> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <hr *ngIf="usuarios" class="my-4" />
                        <!-------------------------------TERCERA FILA----------------------------------->

                        <div class="row mt-2">
                            <div class="col-md-2">
                                <div class="width-150">
                                    <label for="plazas" class="col-form-label bold">Plazas</label>
                                </div>
                                <div class="col-auto mb-3">
                                    <input id="plazas" class="form-control shadow-none" type="text" name="plazas" placeholder="plazas" [(ngModel)]="ruta.plazas" style="width: 50px" />
                                </div>
                                <!-- <div class="col-auto">
  <span id="horaHelpInline" class="form-text">
    Indica el número de plazas
  </span>
</div> -->
                            </div>

                            <div class="col-md-5">
                                <div class="width-150 mb-1 mt-xl-0 mt-2">
                                    <label for="sincoche" class="col-form-label bold">Admite usuarios sin coche</label>
                                </div>
                                <div class="col-auto">
                                    <div class="btn-group wrap-input100 mb-3" role="group" aria-label="Basic radio toggle button group">
                                        <input type="radio" class="btn-check shadow-none" name="btnradio5" id="btnradio5" autocomplete="off" [(ngModel)]="ruta.sinCoche" value="si" />
                                        <label class="btn btn-outline-primary" for="btnradio5">Sí</label>

                                        <input type="radio" class="btn-check shadow-none" name="btnradio6" id="btnradio6" autocomplete="off" [(ngModel)]="ruta.sinCoche" value="no" />
                                        <label class="btn btn-outline-primary" for="btnradio6">No</label>
                                    </div>
                                </div>
                                <!-- <div class="col-auto">
  <span id="horaHelpInline" class="form-text">
    Selecciona si o no
  </span>
</div> -->
                            </div>

                            <div class="col-md-4">
                                <div class="width-150 mb-1 mt-xl-0 mt-2">
                                    <label for="sincoche" class="col-form-label bold">Admite gente de otros
                                        centros</label>
                                </div>
                                <div class="col-auto">
                                    <div class="btn-group wrap-input100 mb-3" role="group" aria-label="Basic radio toggle button group">
                                        <input type="radio" class="btn-check shadow-none" name="btnradio7" id="btnradio7" autocomplete="off" [(ngModel)]="ruta.aceptaParadas" value="si" />
                                        <label class="btn btn-outline-primary" for="btnradio7">Sí</label>

                                        <input type="radio" class="btn-check shadow-none" name="btnradio8" id="btnradio8" autocomplete="off" [(ngModel)]="ruta.aceptaParadas" value="no" />
                                        <label class="btn btn-outline-primary" for="btnradio8">No</label>
                                    </div>
                                </div>
                                <!-- <div class="col-auto">
<span id="horaHelpInline" class="form-text">
  Selecciona si o no
</span>
</div> -->
                            </div>
                        </div>
                        <hr class="my-4" />
                        <!-------------------------------CUARTA FILA----------------------------------->

                        <div class="row">
                            <div class="col-md-12">
                                <div>
                                    <div class="row m-0 pt-2">
                                        <div class="p-0 col-12">
                                            <p class="card-text d-flex align-items-center">
                                                Cerrar Ruta
                                                <label class="switch">
                                                    <input type="checkbox" [checked]="ruta.cerrada"
                                                        (change)="change()" />
                                                    <span class="slider round ms-3"></span>
                                                </label>
                                            </p>
                                        </div>

                                        <div class="col-12 d-flex align-items-center p-0">
                                            <p class="m-0 mt-2 extraInfo">
                                                Si cierras la ruta el resto de usuarios ya no podrá verla
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!------------------------------- QUINTA FILA----------------------------------->
                        <hr class="my-5" />
                        <div class="row mb-5">
                            <div class="col-md-12">
                                <button (click)="back()" type="button" class="btn btn-primary me-4">
                                    Volver
                                </button>
                                <button type="submit" class="btn btn-primary btn-block">
                                    Actualizar datos
                                </button>
                            </div>
                        </div>
                    </div>
                </form>





            </div>

            <div class="col-md-2" style=" background: url('./assets/images/img_rutas_right.jpg') no-repeat -200px -36px;">
            </div>
            <!--col lat izq-->
        </div>
    </div>
    <div class="bg-white">
        <!-- Modal -->
        <div class="fade none" style="background: #ffffffc9" #modal tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header border-0 pt-3 pb-1">
                        <h5 class="modal-title" id="exampleModalLabel"></h5>

                         <button
             type="button"
             class="btn-close"
             data-bs-dismiss="modal"
             aria-label="Close"
             (click)="this.close()"
           ></button>
                    </div>
                    <div class="modal-body p-4 pb-3">
                        <div class="d-flex justify-content-center">
                            <img class="img-fluid" src="./assets/images/ok_1.jpg" alt="" />
                        </div>
                        <div class="d-flex justify-content-center mt-4">
                            {{ this.mensajePop }}
                        </div>

                    </div>
                    <div class="modal-footer border-0 d-flex justify-content-center pb-5">
                        <button type="button" class="btn btn-secondary btn-pop" data-bs-dismiss="modal" (click)="close()">
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- fin modal-->
                       <!-- Modal -->
                       <div class="fade none" style="background: #ffffffc9" #modalfecha tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header border-0 pt-3 pb-1">
                                    <h5 class="modal-title" id="exampleModalLabel"></h5>

                          <button
                         type="button"
                         class="btn-close"
                         data-bs-dismiss="modal"
                         aria-label="Close"
                         (click)="this.closeFecha()"
                       ></button>
                                </div>
                                <div class="modal-body p-4 pb-3">
                                    <div class="d-flex justify-content-center">
                                        <img class="img-fluid" src="./assets/images/alert_1.jpg" alt="" />
                                    </div>
                                    <div class="d-flex justify-content-center mt-4">
                                       <div>La fecha de inicio no puede ser posterior a la de finalización </div>
                                    </div>


                                </div>
                                <div class="modal-footer border-0 d-flex justify-content-center pb-5">
                                    <button type="button" class="btn btn-secondary btn-pop" data-bs-dismiss="modal" (click)=" this.closeFecha()">
                                        Ok
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- fin modal-->

                <!-- Modal -->
                <div class="fade none" style="background: #ffffffc9" #modalconfirma tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog">
                      <div class="modal-content">
                          <div class="modal-header border-0 pt-3 pb-1">
                              <h5 class="modal-title" id="exampleModalLabel"></h5>

                    <button
                   type="button"
                   class="btn-close"
                   data-bs-dismiss="modal"
                   aria-label="Close"
                   (click)="this.closeConfirma()"
                 ></button>
                          </div>
                          <div class="modal-body p-4 pb-3">
                              <div class="d-flex justify-content-center">
                                  <img class="img-fluid" src="./assets/images/alert_1.jpg" alt="" />
                              </div>
                              <div class="d-flex justify-content-center mt-4">
                                 <div>Vas a realizar cambios en tu ruta. </div>
                              </div>
                              <div *ngIf="options.length > 0" class="px-4">

                                <div class="text2 alert alert-warning my-3">Los siguientes usuarios van a ser eliminados</div>
                              <div *ngFor="let user of options; index as i " class="border-bottom  py-3 listausers">
                                {{options[i].username}}
                                {{options[i].email}}
                              </div>
                              <div class="mt-4 text1">¿Quieres continuar?</div>
                            </div>

                          </div>
                          <div class="modal-footer border-0 d-flex justify-content-center pb-5">
                              <button type="button" class="btn btn-secondary btn-pop" data-bs-dismiss="modal" (click)=" this.confirmaEdit()">
                                  Continuar
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- fin modal-->



    </div>
</section>
