import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortbyName'
})
export class SortbyNamePipe implements PipeTransform {

  // transform(array: any, field: string): any[] {
  //   if (!Array.isArray(array)) {
  //     return;
  //   }
  //   array.sort((a: any, b: any) => {
  //     if (a[field] < b[field]) {
  //       return -1;
  //     } else if (a[field] > b[field]) {
  //       return 1;
  //     } else {
  //       return 0;
  //     }
  //   });
  //   return array;
  // }

  transform(array: any, field: string, isAscending: boolean): any[] {
    if (isAscending) {

      array.sort((a: any, b: any) =>
      b[field].localeCompare(a[field], 'tr'));
      return array;
    } else {

array.sort((a: any, b: any) =>
        a[field].localeCompare(b[field], 'tr'));
      return array;
    }
  }

}
