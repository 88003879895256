import { UserService } from './../../services/user.service';
import { ModalService } from './../../services/modal.service';
import { Component, OnInit, OnDestroy, Output, Input, EventEmitter } from '@angular/core';



@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit, OnDestroy {
  constructor(
    private modalService: ModalService,
    public userService: UserService
  ) {}

  usersToRemove: [];

  @Input() title = '';
  @Input() body = '';
  @Output() closeMeEvent = new EventEmitter();
  @Output() confirmEvent = new EventEmitter();

  ngOnInit(): void {
  //  console.log('Modal init');
    this.usersToRemove = this.modalService.usersToRemove;
  }

  closeMe() {
    this.closeMeEvent.emit();
  }
  confirm() {
    this.confirmEvent.emit();
  }

 ngOnDestroy(): void {
  //  console.log(' Modal destroyed');
  }

}
