<!-- <div class="" style="max-width:1200px; margin: 0 auto;margin-top:150px">
  <br><br>
  <h1>RECUPERA LA CONTRASEÑA</h1>

  <div class="wrap-login100 p-t-50 p-b-90 ">
    <form class="login100-form validate-form flex-sb flex-w" (ngSubmit)="onSubmit( registroForm)"
      #registroForm="ngForm">

      <span *ngIf="registroForm.submitted && registroForm.controls['email'].errors" class="text-danger animated fadeIn">
        Introduce un email válido
      </span>


      <div class="wrap-input100 mb-3">
        <input class="input100 form-control" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" type="text" name="email" placeholder="Email" [(ngModel)]="email "
          required email="true" minlength="9" >
        <span class="focus-input100"></span>
      </div>

      <br>
      <button class="btn btn-primary">Enviar</button>
      <br><br>
      <div *ngIf="showMessage" class="alert alert-success">Hemos enviado un enlace para recuperar la contraseña a tu email</div>
    </form>
  </div>

</div> -->




<section class="section" id="resetPass">
  <div class="container d-flex justify-content-center">

    <div class="row" style="width:950px;max-width: 950px">

      <div
      id="misrutasContainer"
      class="rounded"
      #container

    >
    <div class="row">
      <div class="col-lg-6">
    <div class="g-4 maxWidth1100" style="margin: 0 auto; text-align: left">
      <div class="container-fluid" style="padding-top: 20px">


  <h1>Recupera tu contraseña </h1>
        <!-- modal -->
        <div class="fade none modal-container" #exampleModal tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
              <div class="modal-content p-3">
                  <div class="modal-header pb-0 border-0">
                      <h5 class="modal-title" id="exampleModalLabel"></h5>

                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
                  </div>
                  <div class="modal-body p-4 pb-3">
                    <div class="d-flex justify-content-center">
                        <img class="img-fluid" src="./assets/images/ok_1.jpg" alt="" />
                    </div>
                    <div class="d-flex justify-content-center mt-4 text-center">
                      Hemos enviado un enlace para recuperar la contraseña a tu email
                </div>
                <div class="modal-footer border-0 d-flex justify-content-center pb-5">
                    <button type="button" class="btn btn-secondary btn-pop" data-bs-dismiss="modal" (click)="close()">
                        OK
                    </button>
                </div>
              </div>
          </div>
      </div>
      </div>
      <!------>

    <div class="wrap-login100 p-t-50 p-b-90">
        <form class="login100-form validate-form flex-sb flex-w pt-4" (ngSubmit)=" onSubmit(registroForm)" #registroForm="ngForm">
          <div id="success"></div>

          <div *ngIf="showMessage" class="alert alert-success">Hemos enviado un enlace para recuperar la contraseña a tu email</div>
          <div  *ngIf="!showMessage" class="">Para recuperar tu contraseña, introduce el email con el que te registraste.</div>

          <div id="error"></div>
          <div *ngIf="!showMessage" class="wrap-input100 mb-3 mt-4">
            <input class="input100 form-control shadow-none" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$" type="text" name="email" placeholder="Introduce tu email" [(ngModel)]="email "
              required email="true" minlength="9">
              <div *ngIf="registroForm.submitted && registroForm.controls['email'].errors"  class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                Introduce un email válido
              </div>
              <div *ngIf="showErrorMessage"  class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                Este email no está registrado
              </div>
            <span class="focus-input100"></span>
          </div>

          <button *ngIf="!showMessage" class="btn btn-primary mt-2">Enviar</button>
          <br><br>

        </form>
        <!-- <div *ngIf="showErrorMessage == true" class="alert alert-danger">EL usuario o el mail no son correctos</div> -->
    </div>
</div></div>
</div>

<div class="col-lg-6 d-flex align-items-lg-end mb-5 pb-4">
  <img width="200"
    class="img-fluid mt-5 mt-lg-0"
    src="./assets/images/login.png"
    alt=""
  />
</div>
</div>





</div>

</div><!--container-->

</div>
</section>
