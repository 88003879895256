<section class="section mispisos" id="pisoInfo">
    <div class="container">
      <div class="row mt-3 pt-2">

        <div class="col-md-2" style=" background: url('./assets/images/img_rutas_right.jpg') no-repeat 0 -36px;">
        </div>

        <div class="col-md-8  px-lg-5 px-md-5 px-3">
            <h1>Mis pisos</h1>
            <div *ngIf="showMessage" class="alert alert-success mt-4 mb-0">{{mensajeConfirma}}</div>

       <!-- modal -->
       <div class="fade none" #exampleModal tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content p-3">
                <div class="modal-header pb-0">
                    <h5 class="modal-title" id="exampleModalLabel"></h5>

                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
                </div>
                <div class="modal-body">
                  <div class="d-flex justify-content-center">
                    <img class="img-fluid" src="./assets/images/alert_1.jpg" alt="" />
                </div>
                    <p class="text-center mt-4 alert-danger alert">{{mensajePop}}</p>

                    <!-- <button class="btn btn-primary" (click)='confirmaBorrar(rutaBorrar);'>Continuar</button> -->

                </div>
                <div class="modal-footer pt-0 justify-content-center">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)='confirmaEliminar(pisoBorrar); ' *ngIf="botonPop === 'borrar'">CONTINUAR</button>
                </div>

            </div>
        </div>
    </div>
    <!------>


          <!-- ROW  -------------------------------------------------------------->
          <!-- ROW -------------------------------------------------------------->
          <div class="row">
            <div class="col-md-12 rowForm">



              <div class="row row-cols-1 row-cols-md-1 g-4 mt-0">
                <div class="col" *ngFor="let piso of misPisos; let i = index">



                  <div class="card p-2"  role="button">

                    <div class=" p-1">
                        <div class="row">
                          <div class="col-md-8 p-4 pb-0 pb-xl-4 ipadFix">
                            <div class="titCard">
                              <h5 class="card-title">
                                {{ piso.tipo }} en {{ piso.localidad }} {{ piso.titulo }}
                              </h5>
                            </div>
                            <div class="titCard">
                              <h5 class="card-title">
                                {{ piso.precio}}€
                              </h5>

                            </div>


                            <p *ngIf="piso.tipo === 'Piso'" class="card-text"><span>{{ piso.habitaciones }} hab</span> <span class="ps-2">{{ piso.metros }}m2</span></p>


                            <p class="card-text">{{ piso.desc | slice:0:100 }}</p>
                            <button class="btn btn-main" [routerLink]="['/alquiler/editarPiso', piso._id]">Editar</button>
                            <button class="btn btn-main ms-3" [routerLink]="['/alquiler/piso', piso._id]">Ver anuncio</button>
                            <button *ngIf="this.misPisos[i].creador._id == this.user" (click)='borrarPiso(piso);' class="btn  btn-block ms-3 btn-editar  btn-borrar"></button>
                            <br><br>
                            <input type="checkbox" class="btn-check mt-4" name="pausado" id="pausado" autocomplete="off"
                            [(ngModel)]="piso.pausado" [value]="true" (change)="updatePisoPublicado(piso)" />
                            <label class="btn btn-main" for="pausado">{{ piso.pausado ? 'Activar anuncio' : 'Pausar anuncio' }}</label>
                          <div *ngIf="!piso.pausado" class="alert alert-success mt-4 ">
                            <p class="mb-0">Tu anuncio está activo</p>
                          </div>
                          <div *ngIf="piso.pausado" class="alert alert-danger mt-4 ">
                            <p class="mb-0">Tu anuncio está pausado. No aparecerá en las búsquedas hasta que lo actives</p>
                          </div>
                          </div>

                          <div class="col-md-4 contPisoImg mispisos mb-4 p-4 pb-0 pb-lg-4 mb-xl-0 mt-4 mt-xl-0 d-flex align-items-center width29 overflow-hidden">
                            <img class="" [src]= piso.imgs[0] />
                          </div>

                    </div>

                  </div>
                  </div>
                </div>
              </div>
              </div>
             </div>
            </div>
            <div class="col-md-2" style=" background: url('./assets/images/img_rutas_right_2.jpg') no-repeat -360px -36px;">


            </div>
            </div>
            </div>
        </section>

