import { Subscription } from 'rxjs';
import { ModalService } from './../../services/modal.service';
import { SendMailService } from './../../services/send-mail.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, Renderer2, ElementRef, ViewChild, AfterViewInit, TemplateRef, Pipe, PipeTransform, ViewContainerRef, OnDestroy } from '@angular/core';
import { RutasService } from 'src/app/services/rutas.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-editar-ruta',
  templateUrl: './editar-ruta.component.html',
  styleUrls: ['./editar-ruta.component.css'],
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Hi there!</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <p>Hello, world!</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
    </div>
  `})


export class EditarRutaComponent implements OnInit {



  ruta: any = {};
  rutaId: string;
  origen;
  destino;
  horaSalida;
  paradas;
  usuarios = [];
  plazas;
  showMessage = false;
  cerrada;
  ocultaAlerta;
  modalMensaje;
  paradasParaBorrar = [];
  usuariosParaBorrar = [];
  disableUsuario = true;
  mensajePop;
  botonPop;
  options: any = [];
  fechaInicio;
  fechaFin;
  fechaInicioPicker;
  fechaFinPicker;



  constructor(
    private rutasService: RutasService,
    private router: Router,
    private activateRuta: ActivatedRoute,
    private sendMailService: SendMailService,
    private location: Location,
    private el: ElementRef,
    private renderer: Renderer2,
    public userService: UserService,


  ) { }


  @ViewChild('modal', { static: false }) divModal: ElementRef;
  @ViewChild('modalconfirma', { static: false }) divModal2: ElementRef;
  @ViewChild('modalfecha', { static: false }) divModalFecha: ElementRef;


  setCheckbox(event: any, index: number) {

    this.disableUsuario = !this.disableUsuario;

    if (!this.options.includes(event)) {
      this.options = [...this.options, event];
    } else {
      this.options = this.options.filter((item) => item !== event);
     // console.log(this.options);
    }

  }



  getRuta() {

    this.rutasService.getRutaOk(this.rutaId).subscribe((res: any) => {
      this.ruta = res.ruta;

      this.paradas = this.ruta.paradas; // ARRAY PARADAS
      this.usuarios = this.ruta.usuarios; // ARRAY USERS

      this.fechaInicio = this.ruta.fechaInicio;
      this.fechaFin = this.ruta.fechaFin;


    });


  }

  getParadasByUser(email?: string) {
    if (email !== undefined) {

      return this.ruta.paradas.filter(
        x =>
          x.creador.email === email
      );
    }
  }


  ngOnInit(): void {


    this.rutaId = this.activateRuta.snapshot.paramMap.get('id');
    this.getRuta();



    // this.functionNr1();
    // this.open();

  }

  onSubmit(form: NgForm) {

    if (form.invalid) { return; }
    this.ocultaAlerta = false;

    // if (this.paradasParaBorrar.length > 0) {

    //   this.paradasParaBorrar.forEach((index) => {
    //     this.removeItem(index);
    //     this.removeUsuario(index.creador);
    //     console.log(this.paradasParaBorrar);
    //   });

    // }

    this.ruta = this.ruta;
    this.origen = this.ruta.origenLocalidad;
    this.destino = this.ruta.destino;
    this.horaSalida = this.ruta.horaSalida;
    this.paradas = this.ruta.paradas;
    this.plazas = this.ruta.plazas;


    if (this.fechaInicioPicker) {

      let dateInicio: Date = new Date(this.fechaInicioPicker.year, (this.fechaInicioPicker.month) - 1, (this.fechaInicioPicker.day) + 1);
      let dateInicioISO = dateInicio.toISOString().slice(0, -14);
      this.ruta.fechaInicio = dateInicioISO;

    }
    if (this.fechaFinPicker) {

      let dateFin : Date = new Date(this.fechaFinPicker.year, (this.fechaFinPicker.month) - 1, (this.fechaFinPicker.day) + 1);
      let dateFinISO = dateFin.toISOString().slice(0, -14);
      this.ruta.fechaFin = dateFinISO;

    }

    let dateStart = new Date(this.ruta.fechaFin.slice(0, 4), this.ruta.fechaFin.slice(5, 7) - 1, this.ruta.fechaFin.slice(8, 10));
    let dateEnd = new Date(this.ruta.fechaInicio.slice(0, 4), this.ruta.fechaInicio.slice(5, 7) - 1, this.ruta.fechaInicio.slice(8, 10));

    if(Date.parse(dateStart.toString()) < Date.parse(dateEnd.toString())){

     this.openFecha();


    }else{

   this.showMessage = true;
      this.mensajePop = 'Tu ruta se ha actualizado correctamente';
      this.openConfirma();


  }





    //console.log(this.usuariosParaBorrar);

    //console.log(this.paradas + 'paradas al final')

    //  this.modalMensaje = 'Tu ruta se ha modificado correctamente',  this.open();

    // this.router.navigate(['/ruta',this.ruta._id]);


  }


  removeItem(value) {
    const index: number = this.paradas.indexOf(value);
    this.paradas.splice(index, 1);
  }
  removeUsuario(value) {
    const index: number = this.usuarios.indexOf(value);
    this.usuarios.splice(index, 1);

  }
  change() {
    if (this.ruta.cerrada === false) {

      this.ruta.cerrada = true;

    } else {
      this.ruta.cerrada = false;


    }

  }
  functionNr1() {


    setTimeout(() => {
      this.ocultaAlerta = true;

    }, 6000);

  }

  open() {
    this.renderer.removeClass(this.divModal.nativeElement, 'none');
    this.renderer.addClass(this.divModal.nativeElement, 'modal');
  }

  close() {
    this.renderer.removeClass(this.divModal.nativeElement, 'modal');
    this.renderer.addClass(this.divModal.nativeElement, 'none');
  }

  openFecha() {
    this.renderer.removeClass(this.divModalFecha.nativeElement, 'none');
    this.renderer.addClass(this.divModalFecha.nativeElement, 'modal');
  }

  closeFecha() {
    this.renderer.removeClass(this.divModalFecha.nativeElement, 'modal');
    this.renderer.addClass(this.divModalFecha.nativeElement, 'none');
  }

  openConfirma() {
    this.renderer.removeClass(this.divModal2.nativeElement, 'none');
    this.renderer.addClass(this.divModal2.nativeElement, 'modal');
  }

  closeConfirma() {
    this.renderer.removeClass(this.divModal2.nativeElement, 'modal');
    this.renderer.addClass(this.divModal2.nativeElement, 'none');
  }

  confirmaEdit() {


    if (this.options.length > 0) {

      this.options.forEach((index) => {

        //console.log(index);


        this.ruta.paradas = this.ruta.paradas.filter(item => item.creador.email !== index.email);



        // console.log('paradas');
        // console.log(this.ruta.paradas);



        this.removeItem(index);
        this.removeUsuario(index);
        this.ruta.plazas++;


        this.sendMailService.sendMail(index.email).subscribe((res: any) => {

        },
          (error) => {

          }
        );
       // console.log('usuarios despues de borrar');
       // console.log(this.usuarios);


        // this.sendMailService.sendMail(index.email).subscribe((res: any) => {


        // },
        //   (error) => {

        //   }
        // );

      });

    }

    this.rutasService.editarRuta(this.ruta).subscribe((res: any) => {


    }, (error) => {

    },
    () => {

    }

    );
    this.closeConfirma();
    this.open();
    this.ruta.usuarios.forEach((index) => {


      this.sendMailService.sendMailBorradoDeTuRuta(index.email).subscribe(
        (res: any) => { },
        (error) => {
          console.log('error');
        }
      );
    });
  }


  borrarParada(parada) {

    const index: number = this.paradas.indexOf(parada);

    this.paradasParaBorrar.push(parada);

    this.options.push(parada.creador);


    //this.paradas.splice(index, 1);

  }
  borrarUsuario(usuario) {

    this.disableUsuario = !this.disableUsuario;
    const index: number = this.usuarios.indexOf(usuario);
    if (!this.disableUsuario) {
      this.usuariosParaBorrar.push(usuario);
      this.ruta.plazas++;
    } else {
      this.usuariosParaBorrar.splice(index, 1);
      this.ruta.plazas--;
    }



    //this.paradas.splice(index, 1);

  }

  back(): void {
    this.location.back();
  }

}
