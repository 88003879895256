
export class UsuarioModel {

  email: string;
  tfno: string;
  password: string;
  username: string;
  contacto: [];
  alertas: [];
  token: string;
  newsletter: string;
  newsResp: string;

}
