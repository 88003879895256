
export class RutaDataModel {

  origenLocalidad: string;
  destinoLocalidad: string;
  tienesCoche: string;
  sinCoche: string;
  aceptaParadas: string;
  finDeSemana: string;
  destinoCentro: string;

}
