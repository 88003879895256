import { ParadasolicitadaService } from './services/paradasolicitada.service';
import { UserService } from './services/user.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe, HashLocationStrategy, LocationStrategy, PathLocationStrategy  } from '@angular/common';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgImageSliderModule } from 'ng-image-slider';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
// import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting

import { ModalModule, BsModalService  } from 'ngx-bootstrap/modal';


import { AppComponent } from './app.component';

import { NavbarComponent } from './components/shared/navbar/navbar.component';

import { HomeComponent } from './components/home/home.component';
import { RutasComponent } from './components/rutas/rutas.component';
import { RutaComponent } from './components/ruta/ruta.component';


//rutas
import {APP_ROUTING} from './app.routes';



import { RutasService } from './services/rutas.service';
import { BuscadorComponent } from './components/buscador/buscador.component';
import { RutaTarjetaComponent } from './components/ruta-tarjeta/ruta-tarjeta.component';
import { RegistroComponent } from './components/registro/registro.component';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './components/login/login.component';
import { CrearRutaComponent } from './components/crear-ruta/crear-ruta.component';
import { MisrutasComponent } from './components/misrutas/misrutas.component';
import { EditarRutaComponent } from './components/editar-ruta/editar-ruta.component';
import { ParadaSolicitadaComponent } from './components/parada-solicitada/parada-solicitada.component';
import { ModalComponent } from './components/modal/modal.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RecuperarPasswordComponent } from './components/recuperar-password/recuperar-password.component';
import { ResetPassComponent } from './components/reset-pass/reset-pass.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { UniquePipe } from './unique.pipe';
import { ContactoComponent } from './components/contacto/contacto.component';
import { AvisolegalComponent } from './components/avisolegal/avisolegal.component';
import { PoliticaprivacidadComponent } from './components/politicaprivacidad/politicaprivacidad.component';
import { RouterModule } from '@angular/router';
import { CookiesComponent } from './components/cookies/cookies.component';
import { MiPerfilComponent } from './components/mi-perfil/mi-perfil.component';
import { MicuentaComponent } from './components/micuenta/micuenta.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MantenimientoComponent } from './components/mantenimiento/mantenimiento.component';




import { FileUploadModule } from 'ng2-file-upload';
import { TestrutaComponent } from './components/testruta/testruta.component';
import { SortbyNamePipe } from './sortby-name.pipe';
import { SubirpisoComponent } from './components/subirpiso/subirpiso.component';
import { DndDirective } from './dnd.directive';
import { PisoComponent } from './components/piso/piso.component';
import { PisosComponent } from './components/pisos/pisos.component';
import { EditPisoComponent } from './components/edit-piso/edit-piso.component';
import { MispisosComponent } from './components/mispisos/mispisos.component';
import { FaqsComponent } from './components/faqs/faqs.component';





@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    RutasComponent,
    RutaComponent,
    BuscadorComponent,
    RutaTarjetaComponent,
    RegistroComponent,
    LoginComponent,
    CrearRutaComponent,
    MisrutasComponent,
    EditarRutaComponent,
    ParadaSolicitadaComponent,
    ModalComponent,
    ResetPasswordComponent,
    RecuperarPasswordComponent,
    ResetPassComponent,
    FooterComponent,
    UniquePipe,
    ContactoComponent,
    AvisolegalComponent,
    PoliticaprivacidadComponent,
    CookiesComponent,
    MiPerfilComponent,
    MicuentaComponent,
    MantenimientoComponent,


    TestrutaComponent,
    SortbyNamePipe,
    SubirpisoComponent,
    DndDirective,
    PisoComponent,
    PisosComponent,
    EditPisoComponent,
    MispisosComponent,
    FaqsComponent,





  ],

  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgIdleKeepaliveModule.forRoot(),
    // MomentModule,
    HttpClientModule,
    ModalModule.forRoot(),
    APP_ROUTING,
    NgbModule,
    NgxDropzoneModule,
    NgImageSliderModule






  ],
  providers: [
    RutasService,
    UserService,
    ParadasolicitadaService,
    {provide : LocationStrategy , useClass: HashLocationStrategy},
    DatePipe,
    // {provide : LocationStrategy , useClass: PathLocationStrategy}
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
