

import { Router } from '@angular/router';
import { Component, OnInit, Renderer2,  ElementRef,  ViewChild } from '@angular/core';
import { UserService } from './../../services/user.service';
import { NgForm } from '@angular/forms';
import { ViewportScroller } from '@angular/common';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  email;
  showMessage = false;
  showErrorMessage = false;
  mensajePop;

  public screenWidth: any;
  public screenHeight: any;

  constructor(
    public userService: UserService,
    private router: Router,
    private scroller: ViewportScroller,
    private el: ElementRef,
    private renderer: Renderer2) {

      const screenHeight = window.screen.height;
      const screenWidth = window.screen.width;

      // Actual space available in navigator
      const actualHeight = window.innerHeight;
      const actualWidth = window.innerWidth;
    }

    @ViewChild('exampleModal', { static: false }) divModal: ElementRef;

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

  }

  checkMessage(){
    if (this.showMessage === false){
      this.showMessage = true;
    }

  }

  goAlert(element, margin = 0) {
    const dims =  element.getBoundingClientRect();
    window.scrollTo(window.scrollX, dims.top - margin);
  }
  goSuccess(element, margin = 0) {
    const dims =  element.getBoundingClientRect();
    window.scrollTo(window.scrollX, dims.top - margin);
  }


  onSubmit(form: NgForm) {
    if (form.invalid) {
      if ( this.screenWidth < 400){
      const element = document.getElementById('error');
      this.goAlert(element);
      }
      return;
    }


    this.email = this.email.toLowerCase();
    this.userService.resetPasswordEmail(this.email).subscribe(
      (res: any) => {

      },
      (error) => {
        this.showErrorMessage = true;
        if ( this.screenWidth < 400){
          const element = document.getElementById('error');
          this.goAlert(element);
          }
        return;
      },
      () => {
       this.showMessage = true;
       this.checkMessage();
       this.open();

       if ( this.screenWidth < 400){
          const element = document.getElementById('success');
          this.goAlert(element);
         }else{return; }


      }
    );
  }
  open() {
    this.renderer.removeClass(this.divModal.nativeElement, 'none');
    this.renderer.addClass(this.divModal.nativeElement, 'modal');
  }

  close() {
    this.renderer.removeClass(this.divModal.nativeElement, 'modal');
    this.renderer.addClass(this.divModal.nativeElement, 'none');
  }
}
