
import { Router } from '@angular/router';
import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CentrosService {

  constructor(

    private httpClient: HttpClient,
    private router: Router){

  }

// getCentros(){

//     const url = 'https://docentesenruta.eu-4.evennode.com/centros/getCentros';
//     return this.httpClient.get(url);

//   }

  getCentros(centroData){

    // const params = JSON.stringify(id);
    // console.log(centroData)
    const url = 'https://docentesenruta.eu-4.evennode.com/centros/getCentros/';
    return this.httpClient.post(url, centroData);

  }
  // getCentros(id: string){

  //   const params = JSON.stringify(id);
  //   const url = 'https://docentesenruta.eu-4.evennode.com/centros/getCentros/' + id;
  //   return this.httpClient.get(url);

  // }




}


export interface Centro{
  nombre: string;
  localidad: string;
  provincia: string;
  comunidad: string;

}
