<section class="section" id="registro">
  <div class="container d-flex justify-content-center">
    <div class="row" style="width: 950px; max-width: 950px">
      <!-- <div class="container-fluid"> -->
      <div class="row" style="padding-top: 20px">
        <br />
        <div
          class="col-md-12"
          style="
            padding: 0px 40px;
            display: flex;

            flex-flow: column wrap;
          "
        >
          <h1>Registro</h1>
        </div>
      </div>
      <!-- </div> -->
      <div id="registroContainer" class="rounded" #container>
        <div class="row">
          <div class="col-lg-7">
        <div class="g-4 maxWidth1100" style="margin: 0 auto; text-align: left">
          <div class="container-fluid" style="padding-top: 20px">
            <div class="alert alert-success" *ngIf="registroOk === true">
              Bienvenido a Docentes en ruta. Ya puedes crear tu propio ruta o
              unirte a una existente
            </div>

            <div
              class="wrap-login100 p-t-50 p-b-90"
              *ngIf="registroOk !== true"
            >
              <form
                class="login100-form validate-form flex-sb flex-w pt-4"
                (ngSubmit)="onSubmit(registroForm)"
                #registroForm="ngForm"
              >
                <div
                  *ngIf="
                    registroForm.submitted &&
                    registroForm.controls['username'].errors
                  "
                  class="alert alert-danger animated fadeIn p-2 mt-2 ps-4"
                >
                  El nombre tiene que tener mínimo 2 letras
                </div>

                <div
                  *ngIf="existeUsuario === true"
                  class="alert alert-danger animated fadeIn p-2 mt-2 ps-4"
                >
                  El nombre de usuario ya existe
                </div>

                <div class="wrap-input100 mb-3">
                  <input
                    class="input100 form-control shadow-none"
                    type="text"
                    name="username"
                    placeholder="Nombre usuario"
                    [(ngModel)]="user.username"
                    required
                    minlength="2"
                    (keyup)="onKey($event)"
                  />

                  <div class="focus-input100"></div>
                </div>

                <div
                  *ngIf="
                    registroForm.submitted &&
                    registroForm.controls['email'].errors
                  "
                  class="alert alert-danger animated fadeIn p-2 mt-2 ps-4"
                >
                  Introduce un email válido
                </div>

                <div
                  *ngIf="existeEmail === true"
                  class="alert alert-danger animated fadeIn p-2 mt-2 ps-4"
                >
                  Este email ya está registrado
                </div>

                <div class="wrap-input100 mb-3">
                  <input
                    class="input100 form-control shadow-none"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$"
                    type="text"
                    name="email"
                    placeholder="Email"
                    [(ngModel)]="user.email"
                    required
                    email="true"
                    minlength="9"
                    (keyup)="onKeyMail($event)"
                  />
                  <span class="focus-input100"></span>
                </div>

                <div
                  *ngIf="
                    existeTfno === false &&
                    registroForm.submitted &&
                    registroForm.controls['tfno'].errors
                  "
                  class="alert alert-danger animated fadeIn p-2 mt-2 ps-4"
                >
                  Introduce un teléfono correcto sin espacios
                </div>

                <div
                  *ngIf="mensaje !== ' '"
                  class="alert alert-danger animated fadeIn p-2 mt-2 ps-4"
                >
                  Ese número de teléfono ya está registrado
                </div>

                <div class="wrap-input100 mb-3">
                  <input
                    class="input100 form-control shadow-none"
                    pattern="[0-9]{9}$"
                    type="text"
                    name="tfno"
                    #tfno
                    placeholder="Teléfono (opcional) 666666666*"
                    [(ngModel)]="user.tfno"
                  />
                  <span class="focus-input100"></span>
                </div>
                <p style="font-size: 13px">
                  * Tu email y tfno solo se mostrarán al creador de la ruta a la
                  que te unas
                </p>

                <div
                  *ngIf="
                    registroForm.submitted &&
                    registroForm.controls['password'].errors
                  "
                  class="alert alert-danger animated fadeIn p-2 mt-2 ps-4"
                >
                  El pass tiene que tener mínimo 4 letras
                </div>
                <div class="wrap-input100 mb-3">
                  <input
                    class="input100 form-control shadow-none"
                    type="password"
                    name="password"
                    placeholder="Contraseña"
                    [(ngModel)]="user.password"
                    required
                    minlength="4"
                  />

                  <span class="focus-input100"></span>
                </div>

                <div
                  *ngIf="passwordOk === false"
                  class="alert alert-danger animated fadeIn p-2 mt-2 ps-4"
                >
                  Las contraseñas no coinciden
                </div>
                <div class="wrap-input100">
                  <input
                    class="input100 form-control shadow-none"
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirmar contraseña"
                    [(ngModel)]="confirmPassword"
                    required
                    minlength="4"
                  />

                  <span class="focus-input100"></span>
                </div>
                <br />

                <div
                *ngIf="existeUsuario === true"
                class="alert alert-danger animated fadeIn p-2 mt-2 ps-4"
              >
                El nombre de usuario ya existe
              </div>

              <div class="wrap-input100 mb-3">
                <p style="font-size: 14px">¿Quieres subscribirte a nuestra newsletter para recibir actualizaciones y novedades sobre Docentes en Ruta? No te preocupes, no te vamos a llenar el mail de spam ;)</p>
                <div *ngIf="
                registroForm.submitted &&
                registroForm.controls['btnradio'].errors
              " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                    Selecciona sí o no
                </div>
                <div class="col-md-3">

                  <div class="btn-group wrap-input100 mb-3" role="group" aria-label="Basic radio toggle button group">
                      <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked [(ngModel)]="user.newsletter" value="si" required />
                      <label class="btn btn-outline-primary" for="btnradio1">Sí</label>

                      <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" [(ngModel)]="user.newsletter" value="no" />
                      <label class="btn btn-outline-primary" for="btnradio2">No</label>

                  </div>
                  <br />

              </div>
              </div>

                <button
                  class="btn btn-primary btn-main mt-2 shadow-none"
                  (click)="clickme(tfno.value)"
                >
                  Registrarme
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 d-flex align-items-lg-end mb-5"> <img class="img-fluid mt-5 mt-lg-0" src="./assets/images/registro.jpg" alt=""></div>
    </div>
      </div><!-- registro container-->
    </div>
  </div>
</section>
