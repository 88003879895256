<section class="section" id="rutas">
  <div class="container">
    <div class="row mt-3 pt-2">
      <div
        class="col-md-2"
        style="
          background: url('./assets/images/img_rutas_right.jpg') no-repeat 0 -36px;
        "
      ></div>
      <!--col lat izq-->

      <div class="col-md-8 px-lg-4 px-md-4 px-3">
        <h1>Busca una ruta y únete a tus compañer@s</h1>

        <form
          class="login100-form validate-form flex-sb flex-w"
          (ngSubmit)="onSubmit(rutaFiltroForm)"
          #rutaFiltroForm="ngForm"
        >
          <div class="container-fluid px-0">
            <div class="row">
              <div
                class="titulo mb-2 my-4 d-flex align-items-center"
                style="margin-top: 40px"
              >
                Selecciona Origen<img
                  class="ms-2"
                  style="width: 30px"
                  src="../assets/images/point-01.svg"
                />
              </div>
              <div class="col-md-6 rowForm">
                <div class="wrap-input100 mb-3">
                  <!-- <p><span class="formTitle">Selecciona Provincia Origen *</span></p> -->
                  <select
                    name="origenProvincia"
                    class="w-100 paddingOption select-css form-select form-select-lg mb-3"
                    [(ngModel)]="selectOrigen"
                    (change)="selectChangeOrigen()"


                  >
                    <option
                      class="dropdown-item"
                      disabled
                      selected
                      value="undefined"
                    >
                      Provincia de Origen
                    </option>

                    <option
                      style="
                        font-weight: normal;
                        display: block;
                        white-space: nowrap;
                        min-height: 1.2em;
                        padding: 10px 2px 1px;
                      "
                      *ngFor="let item of provincias"
                      [ngValue]="item.id"
                    >
                      {{ item.nm }}
                    </option>
                  </select>

                  <div
                    *ngIf="
                      rutaFiltroForm.submitted &&
                      rutaFiltroForm.controls['origenProvincia'].errors
                    "
                    class="alert alert-danger animated fadeIn p-2 mt-2 ps-4"
                  >
                    Selecciona una provincia de origen
                  </div>

                  <!-- <div *ngIf="registroForm.submitted && registroForm.controls['destinoProvincia'].errors" class="text-danger animated fadeIn p-1"> Introduce el lugar de inicio de la ruta
                    </div> -->
                </div>
              </div>
              <div class="col-md-6 rowForm">
                <div class="wrap-input100 mb-3">
                  <!-- <p><span class="formTitle">Selecciona Localidad Origen *</span></p> -->
                  <select
                    [disabled]="!selectOrigen"
                    name="origenLocalidad"
                    class="w-100 select-css form-select form-select-lg mb-3"
                    [(ngModel)]="this.origenLocalidad"

                    data-live-search="true"
                    (change)="checkColor()"
                  >
                    <option
                      class="dropdown-item"
                      disabled
                      selected
                      value="undefined"
                    >
                      Localidad de Origen
                    </option>
                    <option
                      [value]="item"
                      *ngFor="let item of localidadesOrigen"
                      [ngValue]="item.nm"
                    >
                      {{ item.nm }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      (rutaFiltroForm.submitted &&
                        rutaFiltroForm.controls['origenLocalidad'].errors) ||
                      (rutaFiltroForm.submitted && origenLocalidad === ' ')
                    "
                    class="alert alert-danger animated fadeIn p-2 mt-2 ps-4"
                  >
                    Selecciona una localidad
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid px-0">
            <div class="row mt-4 mt-lg-0">
              <div class="titulo d-flex align-items-center my-0 mb-2">
                Selecciona Destino
                <img
                  class="ms-2"
                  style="width: 30px"
                  width="30"
                  src="../assets/images/point-01.svg"
                />
              </div>
              <div class="col-md-6 rowForm">
                <div class="wrap-input100 mb-3">
                  <select
                    name="destinoProvincia"
                    class="w-100 select-css form-select form-select-lg mb-3"
                    [(ngModel)]="selectDestino"
                    (change)="selectChange()"
                    required
                  >
                    <option
                      class="dropdown-item"
                      disabled
                      selected
                      value="undefined"
                    >
                      Provincia de destino
                    </option>
                    <option
                      [value]="item.id"
                      *ngFor="let item of provincias"
                      [ngValue]="item.id"
                    >
                      {{ item.nm }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      (rutaFiltroForm.submitted &&
                        rutaFiltroForm.controls['destinoProvincia'].errors) ||
                      (rutaFiltroForm.submitted && selectDestino === ' ')
                    "
                    class="alert alert-danger animated fadeIn p-2 mt-2 ps-4"
                  >
                    Selecciona una Provincia
                  </div>
                </div>
              </div>
              <div class="col-md-6 rowForm">
                <div class="wrap-input100 mb-3">
                  <select
                    [disabled]="!selectDestino"
                    name="destinoLocalidad"
                    class="w-100 select-css form-select form-select-lg mb-3"
                    [(ngModel)]="this.destinoLocalidad"
                    (change)="selectChangeCentro()"
                    required
                  >
                    <option
                      class="dropdown-item"
                      disabled
                      selected
                      value="undefined"
                    >
                      Localidad de destino
                    </option>
                    <option
                      [value]="item"
                      *ngFor="let item of localidadesDestino"
                      [ngValue]="item.nm"
                    >
                      {{ item.nm }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      (rutaFiltroForm.submitted &&
                        rutaFiltroForm.controls['destinoLocalidad'].errors) ||
                      (rutaFiltroForm.submitted && destinoLocalidad === ' ')
                    "
                    class="alert alert-danger animated fadeIn p-2 mt-2 ps-4"
                  >
                    Selecciona una localidad
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid px-0">
            <div class="row">
              <div class="col-md-6 rowForm" style="margin-bottom: 0px">
                <!-- <span class="formTitle">Selecciona un centro</span> -->
                <div class="wrap-input100 mb-3">
                  <div style="text-align: center; display: block">
                    <div class="searchbar">
                      <div class="w-100 d-flex justify-content-start">
                        <input
                          [disabled]="!this.destinoLocalidad"
                          #inputSearch
                          class="w-100 p-2 centro"
                          type="text"
                          placeholder="Introduce el nombre del centro"
                          (keyup)="fetchSeries($event)"
                          [(ngModel)]="destinoCentro"
                          [ngModelOptions]="{ standalone: true }"
                        />
                        <button
                          id="borrafiltro"
                          class="btn btn-close_"
                          type="button"
                          (click)="borraCentro()"
                        >
                          <img
                            class="imgButton"
                            src="../assets/images/close.png"
                          />
                        </button>
                      </div>
                      <div class="w-100">
                        <ul
                          class="list-group"
                          [style.width.px]="inputSearch.offsetWidth"
                        >
                          <li
                            role="button"
                            class="list-group-item"
                            *ngFor="let show of searchResult"
                            (click)="clica(show.nombre)"
                          >
                            <p class="centros">{{ show.nombre }}</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      class="mt-2"
                      style="
                        text-align: left;
                        line-height: 16px;
                        font-size: 13px;
                        color: grey;
                      "
                    >
                      Si tu centro no aparece,
                      <a
                        routerLinkActive="active"
                        [routerLink]="['../contacto']"
                        >contacta con nosotros</a
                      >
                      para que lo podamos añadir al listado.
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 rowForm" style="margin-bottom: 0px">
                <div class="row">
                  <div
                    class="col-md-7 rowForm"
                    style="
                      margin-bottom: 0px;
                      display: flex;
                      margin-bottom: 0px;
                      align-items: center;
                    "
                  >
                    <p>¿Tienes vehículo propio? *</p>
                  </div>
                  <div class="col-md-5 rowForm" style="margin-bottom: 0px">
                    <div
                      class="btn-group wrap-input100 mb-3"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <input
                        type="radio"
                        class="btn-check"
                        name="btncochePropio"
                        id="btncoche1"
                        autocomplete="off"
                        checked
                        value="si"
                        [(ngModel)]="tienesCoche"
                        required
                      />
                      <label class="btn btn-outline-primary" for="btncoche1"
                        >Sí</label
                      >

                      <input
                        type="radio"
                        class="btn-check"
                        name="btncochePropio"
                        id="btncoche2"
                        autocomplete="off"
                        [(ngModel)]="tienesCoche"
                        value="no"
                      />
                      <label class="btn btn-outline-primary" for="btncoche2"
                        >No</label
                      >
                    </div>
                  </div>

                  <div
                    *ngIf="
                      rutaFiltroForm.submitted &&
                      rutaFiltroForm.controls['btncochePropio'].errors
                    "
                    class="alert alert-danger animated fadeIn p-2 mt-2 ps-4"
                  >
                    Selecciona sí o no
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid ps-0">
            <div class="row">
              <!-- <div class="col-md-3 mt-4" >
                    <p>¿Tienes vehículo propio? *</p>
                    <div
                      class="btn-group wrap-input100 mb-3"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <input
                        type="radio"
                        class="btn-check"
                        name="btncochePropio"
                        id="btncoche1"
                        autocomplete="off"
                        checked
                        value="si"
                        [(ngModel)]="tienesCoche"
                        required
                      />
                      <label class="btn btn-outline-primary" for="btncoche1">Si</label>

                                            <input type="radio" class="btn-check" name="btncochePropio" id="btncoche2" autocomplete="off" [(ngModel)]="tienesCoche" value="no" />
                                            <label class="btn btn-outline-primary" for="btncoche2">No</label>

                                        </div>
                                        <div *ngIf="
                      rutaFiltroForm.submitted &&
                      rutaFiltroForm.controls['btncochePropio'].errors
                    " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                                            Selecciona si o no
                                        </div>

                                    </div> -->
            </div>
          </div>

          <div class="container-fluid ps-0">
            <div class="row">
              <div class="col-md-12">
                <div class="">
                  <a
                    class="btn btn-primary mt-2 mb-4 pt-2 pb-2 ps-3 pe-3 btBackGreen"
                    (click)="filtros = !filtros"
                    ><span>{{
                      filtros ? "Mostrar más filtros" : "Ocultar filtros"
                    }}</span
                    ><img
                      class="ms-2 imgButton"
                      src="../assets/images/filter.png"
                  /></a>
                </div>

                <div *ngIf="!filtros">
                  <br />
                  <div *ngIf="tienesCoche != 'no'">
                    <div
                      class="btn-group wrap-input100 mb-3"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <input
                        [disabled]="tienesCoche == false"
                        type="checkbox"
                        class="form-check-input checkBig"
                        name="btncochePropio2"
                        id="btncoche3"
                        autocomplete="off"
                        [(ngModel)]="sinCoche"
                      />
                      <label for="btncoche3">
                        No mostrar rutas que acepten a gente sin coche</label
                      >
                    </div>
                  </div>
                  <div *ngIf="tienesCoche == 'no'">
                    <div
                      class="btn-group wrap-input100 mb-3"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <input
                        disabled="true"
                        type="checkbox"
                        class="form-check-input checkBig"
                        name="btncochePropio2"
                        id="btncoche3"
                        autocomplete="off"
                        [(ngModel)]="sinCoche"
                        checked
                      />
                      <label class="textDisabled" for="btncoche3"
                        >No mostrar rutas que acepten a gente sin coche</label
                      >
                    </div>
                  </div>

                  <p></p>

                  <div
                    class="btn-group wrap-input100 mb-3"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    <input
                      type="checkbox"
                      class="form-check-input checkBig"
                      name="btnaceptaparada"
                      id="btnaceptaparada1"
                      autocomplete="off"
                      checked
                      [(ngModel)]="mostrarRutaConParada"
                    />
                    <label for="btnaceptaparada1"
                      >No mostrar rutas que acepten a gente de otros
                      centros</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="container-fluid ps-0 pe-0">
            <br />

            <br />
            <div class="row">
              <div class="col-md-4">
                <button
                  type="submit"
                  class="btn btn-main buscarRutas animated fadeInDown"
                  (click)="goResultados()"
                >
                  Buscar Rutas
                </button>
              </div>

              <div class="col-md-4"></div>
              <div class="col-md-4"></div>
            </div>
          </div>
        </form>
        <br />

        <!-------------------------------------------- RESULTADOs 1----------------->

        <div *ngIf="showSinOrigen" class="alert alert-warning">
          Todavía no hay rutas con el origen que has seleccionado. Te mostramos las que van a tu destino con un origen diferente, por si alguna te pilla cerca :)
        </div>
        <div *ngIf="showSinOrigenNiCole" class="alert alert-warning">
          Todavía no hay rutas que vayan a ese centro. Te mostramos las que van a esa localidad, por si alguna te pilla cerca :)
        </div>

        <div class="row row-cols-1 row-cols-md-2 g-4 mt-3">

          <div class="col" *ngFor="let ruta of rutas; let i = index">
            <div
              class="card"
              *ngIf="
                this.userService.loggedIn == true &&
                this.ruta.creador._id == this.user._id
              "
            >
              <div class="card-body pt-4">
                <div class="titCard">
                  <div class="alert alert-primary" style="font-size: 0.9rem">
                    Esta ruta es tuya, ¿quieres editarla?
                  </div>
                  <h5 class="card-title">
                    <span class="regular">Origen</span> :
                    {{ ruta.origenLocalidad }}
                  </h5>
                  <h5 class="card-title" *ngIf="ruta.destinoCentro">
                    <span class="regular">Destino</span> :
                    {{ ruta.destinoCentro }}
                  </h5>
                  <h5
                    class="card-title"
                    *ngIf="ruta.destinoLocalidad && !ruta.destinoCentro"
                  >
                    <span class="regular">Destino</span> :
                    {{ ruta.destinoLocalidad }}
                  </h5>
                </div>
                <p class="card-text">Plazas disponibles : {{ ruta.plazas }}</p>
                <p
                  class="card-text"
                  *ngIf="ruta.aceptaParadas == 'si' && ruta.paradas.length > 0"
                >
                  Paradas :
                </p>
                <div
                  class="card-text mx-2"
                  *ngFor="let user of ruta.paradas | unique; let i = index"
                >
                  <b>{{ user.parada }} </b>
                </div>

                <!-- <p class="card-text">Creada por : {{ruta.creador}}</p> -->
                <!-- <p class="card-text">id: {{ruta._id}}</p> -->

                <!-- <button  (rutaSeleccionada)="verRuta($event)" (click)="verRuta(ruta._id);" [id]="ruta._id" [ruta]="ruta"  type="button" class="btn-outline-primary">Ver mas cosas
            </button> -->
                <p *ngIf="!esCreador" class="mt-3">
                  <a
                    [routerLink]="['/editarRuta', ruta._id]"
                    class="btn btn-primary"
                    >Editar Ruta</a
                  >
                  <!-- <img class="ms-2 imgButton" src="../assets/images/map.png" /> -->
                </p>
              </div>
            </div>

            <div
              class="card"
              *ngIf="
                this.userService.loggedIn == true &&
                this.ruta.creador._id !== this.user._id
              "
            >
              <div class="card-body pt-4">
                <div class="titCard">
                  <h5 class="card-title">
                    <span class="regular">
                      <!-- <img
                class=" imgButton"
                width="20"
                src="../assets/images/point-01.svg"
              /> -->
                      Origen
                    </span>
                    : {{ ruta.origenLocalidad }}
                  </h5>
                  <h5 class="card-title" *ngIf="ruta.destinoCentro">
                    <span class="regular"> Destino</span> :
                    {{ ruta.destinoCentro }}
                  </h5>
                  <h5
                    class="card-title"
                    *ngIf="ruta.destinoLocalidad && !ruta.destinoCentro"
                  >
                    <span class="regular">Destino</span> :
                    {{ ruta.destinoLocalidad }}
                  </h5>
                </div>
                <p class="card-text mb-2" class="card-title"><span class="regular">Fecha de inicio</span> : <span class="bold">{{ruta.fechaInicio }}</span></p>
                <p class="card-text mb-2" class="card-title"><span class="regular">Fecha de fin</span> : <span class="bold">{{ruta.fechaFin }}</span></p>
                <hr>
                <p class="card-text">Plazas disponibles : {{ ruta.plazas }}</p>
                <p
                  class="card-text"
                  *ngIf="ruta.aceptaParadas == 'si' && ruta.paradas.length > 0"
                >
                  Paradas :
                </p>
                <div
                  class="card-text mx-2"
                  *ngFor="let user of ruta.paradas; let i = index"
                >
                  <b>{{ ruta.paradas[i].parada }} </b>
                </div>

                <!-- <p class="card-text">Creada por : {{ruta.creador}}</p> -->
                <!-- <p class="card-text">id: {{ruta._id}}</p> -->

                <!-- <button  (rutaSeleccionada)="verRuta($event)" (click)="verRuta(ruta._id);" [id]="ruta._id" [ruta]="ruta"  type="button" class="btn-outline-primary">Ver mas cosas
            </button> -->

               <div *ngIf ="ruta.plazas < 1" class="mb-2">
                <div class="alert alert-danger">Ruta completa</div>
               </div>
                <p *ngIf="!esCreador" class="mt-3">
                  <a [routerLink]="['/ruta', ruta._id]" class="btn btn-primary"
                    >Ver detalle
                    <img class="ms-2 imgButton" src="../assets/images/map.png"
                  /></a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-------------------------------------------FIN RESULTADOS 1!-------------------------------->
        <!------------------------ALERTA NO HY RUTAS-------------------------->

        <div
          class="row row-cols-1 row-cols-md-3 g-4 mt-0"
          *ngIf="
            this.userService.loggedIn == true &&
            this.filtro == true &&
            this.rutas.length == 0
          "
          [class.fadein]="isFavorite"
        >
          <div class="alert alert-info text-center py-4 w-100" role="alert">
            Hay <strong>{{ this.rutas.length }}</strong> rutas que van a tu
            destino. Puedes crear una.<br /><br />
            <button class="btn btn-primary" [routerLink]="['../crearRuta']">
              Ir a crear ruta
            </button>
          </div>
        </div>
        <!------------------------FIN ALERTA NO HY RUTAS-------------------------->

        <!------------------------ALERTA NO HY RUTAS sin registro-------------------------->
        <div
          class="row row-cols-1 row-cols-md-3"
          style="max-width: 1200px; margin: 0 auto"
          *ngIf="
            this.userService.loggedIn === false &&
            this.filtro == true &&
            this.rutas.length == 0
          "
        >
          <div
            class="alert alert-info text-center py-4 w-100"
            role="alert"
            [class.fadein]="isFavorite"
          >
            No hay ninguna ruta con los parámetros seleccionados. Puedes
            registrarte y crearla tú.<br /><br />
            <button class="btn btn-primary" [routerLink]="['../registro']">
              Ir a Registro
            </button>
          </div>
        </div>
        <!------------------------fin ALERTA NO HY RUTAS sin registro-------------------------->

        <div
          class="row row-cols-1 row-cols-md-3"
          *ngIf="
            this.userService.loggedIn == false &&
            this.filtro == true &&
            this.rutas.length > 0
          "
        >
          <div
            class="alert alert-info text-center w-100 py-4"
            role="alert"
            [class.fadein]="isFavorite"
          >
            Hay <strong>{{ this.rutas.length }}</strong> rutas que van a tu
            destino. Puedes registrarte para consultar los detalles<br /><br />
            <button class="btn btn-primary" [routerLink]="['../registro']">
              Ir a Registro
            </button>
          </div>
        </div>
      </div>
      <!--col izquierda-->

      <div
        class="col-md-2"
        style="
          background: url('./assets/images/img_rutas_right.jpg') no-repeat -210px -36px;
        "
      ></div>
      <!--col derecha-->

      <div class="row">
        <div class="col-md-12">
          <div id="resultados"></div>
        </div>
      </div>
    </div>
    <!--row1 1-->
  </div>
  <!--container 1-->
</section>

<br /><br />
