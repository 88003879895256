import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class SubirImgsService {

  private baseUrl = 'https://docentesenruta.eu-4.evennode.com/user';
  httpClient: any;

  params = {
    provincia: '',
    precio:  '',
    localidad:    '',
    habitaciones:  '',
    metros:  '',
    ascensor: '',
    mascota: '',
    tipo: '',
    pausado: '',
  };


  constructor(
    private http: HttpClient,
    private router: Router
  ) { }


  agregarPiso(valoresPiso: any) {



    const params = JSON.stringify(valoresPiso);
    // console.log(params)
    const url = 'https://docentesenruta.eu-4.evennode.com/user/agregar';
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(url, params, { headers: headers });

  }
  editarPiso(valoresPiso: any) {



    const params = JSON.stringify(valoresPiso);
    console.log(params)
    // console.log(params)
    // console.log('EDIT PISO')
    const url = 'https://docentesenruta.eu-4.evennode.com/user/editar';
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(url, params, { headers: headers });

  }
  deleteImgS3(name: string) {


    const url = 'https://docentesenruta.eu-4.evennode.com/user/deleteImg/' + name ;
    return this.http.delete(url);

  }

  eliminarPiso(piso: any){

    const params = piso;
    // console.log(params)
    const url = 'https://docentesenruta.eu-4.evennode.com/user/borrar/' + piso;
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');
    // return this.httpClient.delete(url, params, { headers: headers });
    return this.http.delete(url);

    }


  sendMailPiso(mensajePiso: any) {

    const params = JSON.stringify(mensajePiso);
    // console.log(params)

    const url = 'https://docentesenruta.eu-4.evennode.com/pisos/sendMailPiso';
     const headers = new HttpHeaders().set('Content-Type', 'application/json');
     return this.http.post(url, params, { headers: headers });

  }

  // UPLOAD IMAGES

  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();



    formData.append('file', file);
    // console.log(formData)
    // formData.append('pisoTit', piso.titulo);
    // formData.append('pisoLocalidad', piso.localidad);
    // formData.append('pisoPrecio', piso.precio);
    // formData.append('pisoTipo', piso.tipo);
    // formData.append('pisoHabitaciones', piso.habitaciones);
    // formData.append('pisoMetros', piso.metros);
    // formData.append('pisoPlanta', piso.planta);
    // formData.append('pisoAscensor', piso.ascensor);
    // formData.append('pisoMascotas', piso.mascotas);
    // formData.append('pisoCreador', piso.creador);


    new Response(formData).text().then();

    const req = new HttpRequest('POST', `${this.baseUrl}/single`, formData, {

      reportProgress: true,
      responseType: 'json'

    });

    return this.http.request(req);

  }

  getFiles(): Observable<any> {
    return this.http.get(`${this.baseUrl}/upload`);
  }

  getPiso(id: string){
    // console.log('get piso service')

    const url = 'https://docentesenruta.eu-4.evennode.com/pisos/' + id ;
    return this.http.get(url);

  }


  getPisos(){

    const url = 'https://docentesenruta.eu-4.evennode.com/pisos/getPisos/';
    return this.http.get(url);

  }

  getMisPisos(user: string ){

    const url = 'https://docentesenruta.eu-4.evennode.com/pisos/getMisPisos/' + user ;
    return this.http.get(url);

  }


  getPisosFiltrados(
    precio?: string,
    localidad?: string,
    habitaciones?: string,
    metros?: string,
    ascensor?: boolean,
    mascota?: boolean,
    tipo?: string,


     ){
      const localidadCodificada = encodeURIComponent(localidad);

    // const url = 'https://docentesenruta.eu-4.evennode.com/pisos/getPisosFiltrados/undefined/1/11/undefined/';
    const url = 'https://docentesenruta.eu-4.evennode.com/pisos/getPisosFiltrados/' + precio + '/' + localidadCodificada + '/'  + habitaciones + '/' + metros + '/' + ascensor  + '/' + mascota + '/' + tipo;

    // console.log(url)


    return this.http.get(url);

  }

  getParams(provincia, precio, localidad, habitaciones, metros, ascensor, mascota, tipo){

    this.params.provincia = provincia;
    this.params.precio = precio;
    this.params.localidad = localidad;
    this.params.habitaciones = habitaciones;
    this.params.metros = metros;
    this.params.ascensor = ascensor;
    this.params.mascota = mascota;
    this.params.tipo = tipo;
    return;

  }

  updatePisoPublicado(pisoId: string, publicado: boolean): Observable<Piso> {

    return this.http.put<Piso>('https://docentesenruta.eu-4.evennode.com/pisos/piso/' + pisoId + '/publicado',  { pausado: publicado });
  }

}

export interface Piso {

  titulo: string;
  localidad: string;
  precio: string;
  habitaciones: string;
  metros: string;
  creador: object;
  ascensor: boolean;
  mascotas: boolean;
  planta: string;
  tipo: string;
  imgs: [];
  desc: string;
  pausado: boolean;


}
