<!-- hero area -->
<!-- Mashead header-->



<header class="masthead bg-white" id="cabecera">

    <div class="container px-5 pt-4 pt-lg-0" id="cabeceraContent" style="margin-top: 100px">
        <div class="row gx-5 align-items-center mt-5">
            <div class="col-lg-6">
                <!-- Mashead text and app badges-->
                <div class="mb-5 mb-lg-0 text-center text-lg-start">
                    <h1 class=" mt-5 mb-5">Estamos realizando labores de mantenimiento. En un rato volvemos :)</h1>
                     </div>
            </div>
            <div class="col-lg-6">
                <!-- Masthead device mockup feature-->
                <div class="masthead-device-mockup">
                    <img class="img-fluid" src="./assets/images/4_c.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</header>




<br>
<br>
<br>

