import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'unique',
  pure: false
})
export class UniquePipe implements PipeTransform {

  transform(items: any[]): any {
    return items.filter((item, index, self) => self.findIndex(t => t.parada === item.parada) === index)
}
}
