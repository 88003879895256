import { SubirImgsService } from './../../services/subir-imgs.service';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  Inject,
  HostBinding,
  Renderer2,
} from '@angular/core';

import {
  UploaderComponent,
  SelectedEventArgs,
  FileInfo,
  RemovingEventArgs,
} from '@syncfusion/ej2-angular-inputs';
import {
  createSpinner,
  showSpinner,
  hideSpinner,
} from '@syncfusion/ej2-popups';
import {
  EmitType,
  detach,
  Browser,
  createElement,
  isNullOrUndefined,
  EventHandler,
} from '@syncfusion/ej2-base';

import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ProvinciasService,
  Provincia,
} from './../../services/provincias.service';
import { ProvinciaModel } from './../../models/provincia.model';
import { LocalidadModel } from 'src/app/models/localidades.model';
import {
  LocalidadesService,
  Localidad,
} from './../../services/localidades.service';

import { PisoModel } from './../../models/piso.model';
import { NgForm, FormControl, Validators, FormGroup } from '@angular/forms';

import { NgbTransitionOptions } from '@ng-bootstrap/ng-bootstrap/util/transition/ngbTransition';
import { UserService } from 'src/app/services/user.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-edit-piso',
  templateUrl: './edit-piso.component.html',
  styleUrls: ['./edit-piso.component.css'],
})
export class EditPisoComponent implements OnInit {
  @HostBinding('className') componentClass: string;
  @ViewChild('fileDropRef') fileDropRef: ElementRef;

  filesToShow = [];
  pisoId: string;
  files: any[] = [];

  public imagePath;
  imgURL: any;

  files2: File[] = [];

  form: FormGroup;

  onSelect(event) {
    //console.log(event);
    this.files2.push(...event.addedFiles);
  }

  onRemove(event) {
    //console.log(event);
    this.files2.splice(this.files.indexOf(event), 1);
  }

  back(): void {
    this.location.back();
  }

  // @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  // files: any[] = [];

  piso: PisoModel;
  provincia: ProvinciaModel;
  localidad: LocalidadModel;

  public localidadesFiltradas: Localidad[] = [];
  public provincias: Provincia[] = [];

  // selectedFiles?: File[];
  selectedFiles?: FileList;
  currentFile?: File;
  progressInfos: any[] = [];
  progress = 0;
  message: string[] = [];

  previews: string[] = [];
  imageInfos?: Observable<any>;

  selectOrigen;
  creador;
  lista = [];
  // fileInfos?: Observable<any>;
  urls = [];
  tieneascensor;
  dt = new DataTransfer(); // Permet de manipuler les fichiers de l'input file
  itemsToRemove = [];
  finish = false;
  publicado = false;
  submitForm = false;
  initialValues;
  filesToRemove = [];

  constructor(
    private uploadService: SubirImgsService,
    private localidadesService: LocalidadesService,
    private provinciasService: ProvinciasService,
    private userService: UserService,
    private el: ElementRef,
    private activateRuta: ActivatedRoute,
    private renderer: Renderer2,
    private location: Location
  ) {}

  @ViewChild('modal', { static: false }) divModal: ElementRef;

  ngOnInit(): void {
    this.pisoId = this.activateRuta.snapshot.paramMap.get('id');

    this.getPiso();


    // this.form = new FormGroup({
    //   // pisotitle: new FormControl( [Validators.required, Validators.minLength(3)]),
    //   // pisoprecio: new FormControl( [Validators.required, Validators.pattern("^[0-9]*$")]),
    //   // provincia: new FormControl( Validators.required),
    //   // localidad: new FormControl( Validators.required),
    //   // desc: new FormControl( Validators.required),
    //   // habitaciones: new FormControl( [Validators.required, Validators.pattern("^[0-9]*$")]),
    //   // metros: new FormControl( [Validators.required, Validators.minLength(2), Validators.pattern("^[0-9]*$")], ),
    //   // planta: new FormControl( [Validators.required, Validators.pattern("^[0-9]*$")]),
    //   // ascensor: new FormControl( Validators.required),
    //   // mascotas: new FormControl( Validators.required),
    //   // tipo: new FormControl( Validators.required),

    //  });

    //  this.initialValues = this.form.value;

    this.piso = new PisoModel();

    this.imageInfos = this.uploadService.getFiles();

    this.provincia = new ProvinciaModel();
    this.getProvincias();

    this.creador = this.userService.usuarioLogged;
    this.piso.creador = this.creador;




  }

  changeAscensor() {
    if ((this.tieneascensor === true)) {
     // this.tieneascensor = false;
      console.log(this.tieneascensor)

    }
    if ((!this.tieneascensor )) {
     // this.tieneascensor = true;
      console.log(this.tieneascensor )

    }
  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }
  resetFileUploader() {
    this.fileDropRef.nativeElement.value = '';
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
    //console.log(this.files[0].name)
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.previews.splice(index, 1);
    //console.log(this.files)
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);

      var reader = new FileReader();
      this.imagePath = files;

      reader.onload = (_event) => {
        this.imgURL = reader.result;
        this.urls.push(this.imgURL);
        // this.piso.imgs.push(this.imgURL)
        // console.log(this.urls)
      };
      reader.readAsDataURL(item);
    }
    //this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  selectToPreview(event: any): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFiles = event.target.files;

    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;

      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.previews.push(e.target.result);

          // console.log(e.target.result.toString())
          // console.log(this.piso.imgs)

          // this.piso.imgs.push(e.target.result);
          // this.lista.push(e.target.result);
        };

        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }
  }

  selectFiles(event: any): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFiles = event.target.files;

    //this.previews = [];
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;

      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
          this.lista.push(e.target.result);
        };

        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }
  }
  public delete() {
    this.lista[0] = null;
  }

  editar() {
    if (this.tieneascensor === true) {
      this.piso.ascensor = true;
    } else {
      this.piso.ascensor = false;
    }

    if (this.files.length > 0) {
      this.uploadFiles();
    } else {
      if (this.filesToRemove) {
        for (let i = 0; i < this.filesToRemove.length; i++) {
          if (this.piso.imgs.includes(this.filesToRemove[i])) {
            this.piso.imgs.splice(
              this.piso.imgs.indexOf(this.filesToRemove[i]),
              1
            );

            let imagen = this.filesToRemove[i].split('/').pop();
            this.uploadService.deleteImgS3(imagen).subscribe((response) => {
              //this.form.reset(this.initialValues);
            });
          }
        }

        this.uploadService.editarPiso(this.piso).subscribe((response) => {
          this.resetFileUploader();
          this.files = [];
          this.finish = true;
          this.publicado = true;
          this.open();
        });
      }
    }
  }

  onSubmit(form: NgForm) {
    // if(this.form.valid){
    //   console.log(this.form.value);
    // }
    // this.uploadFiles();
    //  this.uploadService.agregarPiso(this.piso).subscribe((res: any) => {
    //  });
  }

  // submit(){
  //   console.log('alert');

  //   if(this.form.valid){
  //     console.log(this.form.value);
  //   }

  // }
  uploadFiles(): void {
    // console.log(this.selectedFiles + '0000000000000000000000000000')
    this.message = [];
    // PROBAR CON THIS.FILES
    if (this.files) {
      for (let i = 0; i < this.files.length; i++) {
        // check si la imagen se sube o se ha eliminado por el usuario
        if (this.itemsToRemove.indexOf(this.files[i].name) > -1) {
          // if( this.files[i].name === 'pexels-cottonbro-studio-5658531.jpg'){
        //  alert('no se sube');
        } else {
          this.upload(i, this.files[i]);
          // this.upload(i, this.files[i]);
        }
      }
    }
  }

  upload(idx: number, file: File): void {
    this.progressInfos[idx] = { value: 0, fileName: file.name };

    if (file) {
      this.uploadService.upload(file).subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].value = Math.round(
              (100 * event.loaded) / event.total
            );
          } else if (event instanceof HttpResponse) {
            const msg = 'Imagen subida correctamente: ' + file.name;
            this.message.push(msg);
            this.imageInfos = this.uploadService.getFiles();

            // this.componentClass = 'd-none';

            // hacer llamada como en rutas------------------------------
            // hacer llamada como en rutas------------------------------

            // console.log(this.piso)

            if (this.files.length === idx + 1) {
              setTimeout(() => {
                // if(this.tieneascensor === true){
                //   this.piso.ascensor = 'si';

                // }
                // if(this.tieneascensor === undefined || this.tieneascensor === 'undefined'){
                //   this.piso.ascensor = 'no';

                // }

                this.uploadService
                  .editarPiso(this.piso)
                  .subscribe((response) => {
                    this.resetFileUploader();
                    this.files = [];
                    this.finish = true;
                    this.publicado = true;
                    this.open();
                    //this.form.reset(this.initialValues);
                  });
              }, 500);
            }

            //console.log(this.imageInfos);
          }
        },
        error: (err: any) => {
          this.progressInfos[idx].value = 0;
          const msg = 'No se ha podido subir la siguiente imagen: ' + file.name;
         // alert(msg);
          this.message.push(msg);
        },
      });
    }
  }

  // REMOVE SELECTED IMAGES ONCLICK

  removeItem(index) {
    this.itemsToRemove.push(this.selectedFiles[index].name);
    this.previews.splice(index, 1);
  }

  // get provincias
  getProvincias() {
    this.provinciasService.getProvincias().subscribe((res: any) => {
      this.provincias = res.provincias;
    });
  }

  selectChangeOrigen() {
    this.getLocalidades();
    // this.toggleClass = !this.toggleClass;
  }

  //get localidades
  getLocalidades() {
    let id = this.selectOrigen;
    this.localidadesService.getLocalidades(id).subscribe((res: any) => {
      this.localidadesFiltradas = res.localidades;
    });
  }

  getPiso() {
    this.uploadService.getPiso(this.pisoId).subscribe((res: any) => {

      this.piso = res.piso;

      this.filesToShow = this.piso.imgs;
      if(this.piso.ascensor === true){
        this.tieneascensor = true;


      }else{
        this.tieneascensor = false;

      }

//alert(this.piso.mascotas)

    });
  }
  status: boolean = false;
  removeFirst(index) {
    if (this.filesToRemove.includes(index)) {
      this.filesToRemove.splice(this.filesToRemove.indexOf(index), 1);
    } else {
      this.filesToRemove.push(index);
    }
    this.status = !this.status;

    // if(this.status){
    //   this.filesToRemove.push(this.piso.imgs[index]);
    //  // console.log(this.filesToRemove)

    // }else{
    //   this.filesToRemove.splice(this.piso.imgs[index]);
    //  // console.log(this.filesToRemove)
    // }
  }
  // someMethod(id){

  //   return this.filesToRemove.some(id);
  // }
  open() {
    this.renderer.removeClass(this.divModal.nativeElement, 'none');
    this.renderer.addClass(this.divModal.nativeElement, 'modal');
  }

  close() {
    this.getPiso();
    this.renderer.removeClass(this.divModal.nativeElement, 'modal');
    this.renderer.addClass(this.divModal.nativeElement, 'none');
  }
}
