import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { Component, OnInit,  Renderer2,  ElementRef,  ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ViewportScroller } from '@angular/common';


@Component({
  selector: 'app-recuperar-password',
  templateUrl: './recuperar-password.component.html',
  styleUrls: ['./recuperar-password.component.css']
})
export class RecuperarPasswordComponent implements OnInit {
  usuario = {};
  url = this.router.url;
  verified = false;
  password;
  confirmPassword;
  passwordOk;
  cambioOk;
  mensajePop;

  constructor(
    public userService: UserService,
    private router: Router,
    private scroller: ViewportScroller,
    private el: ElementRef,
    private renderer: Renderer2) { }

    @ViewChild('exampleModal', { static: false }) divModal: ElementRef;

  ngOnInit(): void {
    this.checkUser();
  }

  checkPassword() {
    if (this.password === this.confirmPassword) {
      this.passwordOk = true;
    } else {
      this.passwordOk = false;
    }
  }
  goAlert(element, margin = 0) {
    const dims =  element.getBoundingClientRect();
    window.scrollTo(window.scrollX, dims.top - margin);
  }
  goAlert2(element, margin = 0) {
    const dims =  element.getBoundingClientRect();
    window.scrollTo(window.scrollX, dims.top - margin);
  }
  goAlert3(element, margin = 0) {
    const dims =  element.getBoundingClientRect();
    window.scrollTo(window.scrollX, dims.top - margin);
  }
  checkUser() {

    this.url = this.url.replace('/recuperar-password/', ''); /*token*/

    this.userService.getUserToken(this.url).subscribe((res: any) => {

      if (res.usuario.length === 0) {

      } else {
        this.verified = true;
        this.usuario = res.usuario;
      }
    }, (error) => {}

    );

  }
  onSubmit(form: NgForm) {


    if (form.invalid) {

      if ( this.passwordOk === false){
        const element = document.getElementById('error2');
        this.goAlert2(element);
      }else{
        const element = document.getElementById('error');
        this.goAlert(element);
      }

    }

    // this.usuario.password = this.password;

    this.userService.editUser(this.usuario).subscribe(
      (res: any) => {

      // this.cambioOk = true;
      // this.open();
      // alert();


    } , ( error ) => {

    },
    () => {
    //  this.showMessage = true;
    //  this.checkMessage();
    this.mensajePop = 'Tu contraseña se ha cambiado correctamente';
    this.cambioOk = true;
    this.open();
    const element = document.getElementById('alertSuccess');
    this.goAlert3(element);


    }

    );



  }


  open() {
    this.renderer.removeClass(this.divModal.nativeElement, 'none');
    this.renderer.addClass(this.divModal.nativeElement, 'modal');
  }

  close() {
    this.renderer.removeClass(this.divModal.nativeElement, 'modal');
    this.renderer.addClass(this.divModal.nativeElement, 'none');
  }
}
