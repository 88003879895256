<section class="section" id="registro">
    <div class="container d-flex justify-content-center">
        <div class="row" style="width: 950px; max-width: 950px">
            <!-- <div class="container-fluid"> -->
            <div class="row" style="padding-top: 20px">
                <br />
                <div class="col-md-12" style="
            padding: 0px 40px;
            display: flex;

            flex-flow: column wrap;
          ">
                    <h1>Contacto</h1>

                </div>
            </div>
            <!-- </div> -->
            <div id="registroContainer" class="rounded" #container>

                <div class="row">
                    <div class="col-lg-7">

                        <div class="g-4 maxWidth1100" style="margin: 0 auto; text-align: left">
                          <div id="alertSuccess"></div>

                            <div class="container-fluid" style="padding-top: 20px">
                                <div id="alertSuccess"></div>
                                <div class="alert alert-success" *ngIf="enviado === true" id="enviado">
                                    Tu mensaje se ha enviado correctamente. Nos pondremos en contacto contigo lo antes posible.
                                </div>

                                <div class="wrap-login100 p-t-50 p-b-90">
                                    <form class="login100-form validate-form flex-sb flex-w pt-4" (ngSubmit)="onSubmit(contactoForm)" #contactoForm="ngForm">


                                      <div id="errorName"></div>

                                        <div class="wrap-input100 mb-3">
                                            <input class="input100 form-control shadow-none" type="text" name="username" placeholder="Nombre" [(ngModel)]="emailContacto.nombre" required minlength="2" #nombreInput />
                                            <div *ngIf="
                                            contactoForm.submitted &&
                                            contactoForm.controls['username'].errors
                                          " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                                                                    El nombre tiene que tener mínimo 2 letras
                                                                </div>
                                            <div class="focus-input100"></div>
                                        </div>



                                        <div id="errorMail"></div>
                                        <div class="wrap-input100 mb-3">
                                            <input class="input100 form-control shadow-none" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$" type="text" name="email" placeholder="Email" [(ngModel)]="emailContacto.email" required email="true" minlength="9" #emailInput />
                                            <span class="focus-input100"></span>
                                        </div>
                                        <div *ngIf="
                                        contactoForm.submitted &&
                                        contactoForm.controls['email'].errors
                                      " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                                                                Introduce un email válido
                                                            </div>


                                        <div class="wrap-input100 mb-3">
                                            <textarea class="input100 form-control shadow-none" type="text" name="mensaje" placeholder="Mensaje" [(ngModel)]="emailContacto.mensaje" required minlength="9" #mensajeInput>

                </textarea>
                                            <span class="focus-input100"></span>
                                        </div>
                                        <div id="errorMensaje"></div>
                                        <div *ngIf="
                  contactoForm.submitted &&
                  contactoForm.controls['mensaje'].errors
                " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                                            El mensaje no puede estar vacío ni tener menos de 9 caracteres
                                        </div>



                                        <br />

                                        <button class="btn btn-primary btn-main mt-2 shadow-none">
                  Enviar
                </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 d-flex align-items-lg-end mb-5 justify-content-center"> <img class="img-fluid mt-5 mt-lg-0" src="./assets/images/registro.jpg" alt=""></div>
                </div>
            </div>
            <!-- registro container-->
        </div>
    </div>
</section>
