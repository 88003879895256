<section class="section" id="login">
    <div class="container d-flex justify-content-center">
        <div class="row" style="width: 950px; max-width: 950px">
                         <!-- modal -->
                         <div class="fade none" #newsModal tabindex="-1" aria-labelledby="newsModalLabel" aria-hidden="true">
                          <div class="modal-dialog">
                              <div class="modal-content p-3">
                                  <div class="modal-header pb-0">
                                      <h5 class="modal-title" id="newsModalLabel"></h5>

                                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
                                  </div>
                                  <div class="modal-body">
                                    <div class="d-flex justify-content-center">
                                      <!-- <img class="img-fluid" src="./assets/images/alert_1.jpg" alt="" /> -->
                                  </div>
                                      <p *ngIf="!respuesta" class="text-center mt-4 alert-info alert">¿Quieres subscribirte a nuestra newsletter para recibir novedades sobre la aplicación y alguna promoción interesante para los usuarios? No te preocupes, no te llenaremos el e-mail de spam. ;)</p>
                                      <p *ngIf="respuesta" class="text-center mt-4 alert-success alert">¡Gracias!</p>
                                      <br>

                                      <div *ngIf="!respuesta" class="d-flex flex-column">
                                       <button class="btn btn-primary" (click)="siquiere();">Sí, enviadme cositas :)</button>

                                       <button class="btn btn-primary mt-3" (click)="noquiere();">No, no quiero emails :(</button>
                                      </div>
                                      <div *ngIf="respuesta" class="d-flex flex-column">
                                        <button class="btn btn-primary" (click)="close();" [routerLink]="['/rutas']">Cerrar</button>

                                       </div>

                                  </div>
                                  <div class="modal-footer pt-0 justify-content-center">

                                  </div>
                              </div>
                          </div>
                      </div>
                      <!------>
            <div class="row" style="padding-top: 20px">
                <div class="col-md-12" style="
            padding: 0px 40px;
            display: flex;

            flex-flow: column wrap;
          ">
             <h1>Login</h1>
                </div>

            </div>
            <div id="misrutasContainer" class="rounded" #container>
                <div class="row">
                    <div class="col-lg-7">
                        <div class="g-4 maxWidth1100" style="margin: 0 auto; text-align: left">
                            <div class="container-fluid" style="padding-top: 20px">
                                <div class="alert alert-success" *ngIf="userService.registroOk === true">
                                    Bienvenido a Docentes en ruta. Ya puedes crear tu propio ruta o unirte a una existente
                                </div>
                                <div class="wrap-login100 p-t-50 p-b-90">
                                    <form class="login100-form validate-form flex-sb flex-w pt-4" (ngSubmit)="onSubmit(registroForm)" #registroForm="ngForm">
                                        <!-- <p>Introduce tu email y tu contraseña para acceder</p><br> -->



                                        <div class="wrap-input100 mb-3">
                                            <input class="input100 form-control" type="text" name="email" placeholder="Email" [(ngModel)]="user.email" required email />

                                            <span class="focus-input100"></span>
                                        </div>
                                        <div *ngIf="
                                        registroForm.submitted &&
                                        registroForm.controls['email'].errors
                                      " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                                                            Introduce tu email
                                                        </div>


                                        <div class="wrap-input100">
                                            <input class="input100 form-control" [type]="show ? 'text' : 'password'" name="password" placeholder="password" [(ngModel)]="user.password" required minlength="3" autocomplete="off" />

                                            <span class="focus-input100"></span>
                                        </div>
                                        <div *ngIf="
                                        registroForm.submitted &&
                                        registroForm.controls['password'].errors
                                      " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                                                            Introduce tu contraseña
                                                        </div>
                                        <br>
                                        <div class="d-flex" style="font-size:0.8em"><input type="checkbox" class="checkShowPass me-2" (click)="password()">{{show ? 'Ocultar contraseña' : 'Mostrar contraseña'}} </div>
                                        <br>
                                        <button class="btn btn-primary btn-block my-2 mb-4">
                      Entrar
                    </button>
                                        <br />
                                        <div *ngIf="showErrorMessage == true" class="alert alert-danger mt-3">
                                            El email o la contraseña no son correctos
                                        </div>

                                        <div>
                                            <a [routerLink]="['../reset-password']" class="link-dark">¿Has olvidado tu contraseña?</a
                      >
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 d-flex align-items-lg-end mb-5">
            <img width="200"
              class="img-fluid mt-5 mt-lg-0 align-self-start"
              src="./assets/images/login.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- login container-->
    </div>
  </div>
</section>
