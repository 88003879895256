import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit {
  faqs = [
    {
      question: '¿Cómo registrarse?',
      answer: 'Ve a la sección Registro y rellena el formulario. Te llegará un email con un enlace para verificar tu correo electrónico (revisa que no haya llegado a tu carpeta de Spam). Pincha sobre el enlace y ¡listo! Ya sólo tendrás que iniciar sesión introduciendo el email y la contraseña con la que te registraste.',
      open: false
    },
    {
      question: '¿Cómo iniciar sesión?',
      answer: 'Una vez que te hayas registrado, ve a la sección Login. Introduce el email y la contraseña con la que te registraste. ¡Cuidado con tener las mayúsculas activadas!',
      open: false
    },
    {
      question: '¿Cómo buscar una ruta?',
      answer: 'Ve a la sección Compartir coche y luego Rutas. Después rellena el formulario que aparece en ella. Cuántos más datos rellenes más posibilidades de encontrar una ruta que se amolde a tus necesidades. Para poder ver los detalles de la ruta y unirte a ella tendrás que haber iniciado sesión.',
      open: false
    },
    {
      question: '¿Cómo unirse a una ruta?',
      answer: 'Una vez que hayas buscado una ruta rellenando el formulario, dale a Ver detalle en la ruta a la que te quieras unir. Aparecerán los detalles de la ruta y la opción de unirte.',
      open: false
    },
    {
      question: 'Voy a la misma localidad pero no al mismo centro, ¿cómo solicito una parada?',
      answer: 'Antes de unirte a la ruta, elige tu centro educativo del desplegable que aparece y dale a enviar.',
      open: false
    },
    {
      question: '¿Cómo sé si me han aceptado en la ruta a la que me he unido?',
      answer: 'Cuando te hayas unido a la ruta, al creador le llegará un email avisándole de que te has unido.',
      open: false
    },
    {
      question: '¿Cómo creo una ruta?',
      answer: 'Primero busca una ruta por si ya existe con ese origen y destino. Si no, ve a la sección Compartir coche y luego Crear ruta.',
      open: false
    },
    {
      question: '¿Cómo sé si alguien se ha unido a la ruta que he creado?',
      answer: 'Cuando alguien se una a tu ruta te enviaremos un email para avisarte.',
      open: false
    },
    {
      question: '¿Cómo contactar con los usuarios que se unen a mi ruta?',
      answer: 'Entrando en el apartado Mis rutas de la sección Compartir coche.',
      open: false
    },
    {
      question: '¿Cómo eliminar o modificar una ruta que he creado?',
      answer: 'Para eliminar una ruta ve a la sección Mis rutas. Ahí podrás modificar o eliminar la ruta.',
      open: false
    },
    {
      question: '¿Cómo borrarme de una ruta a la que me uní?',
      answer: 'Ve a la sección Mis rutas y ahí podrás borrarte.',
      open: false
    },
    {
      question: '¿Cómo eliminar a un usuario que se ha unido a mi ruta?',
      answer: 'En la sección Compartir coche ve a Mis rutas. Luego dale a editar.',
      open: false
    },
    {
      question: '¿Cómo sé que me han borrado de la ruta a la que me uní?',
      answer: 'Si el usuario que creó la ruta te borra o elimina la ruta, te llegará un email avisándote.',
      open: false
    },
    {
      question: '¿Cómo buscar un piso/habitación para alquilar?',
      answer: 'Ve a la sección Alquiler para docentes y luego Buscar piso.',
      open: false
    },
    {
      question: '¿Cómo contactar con el anunciante de un piso/habitación?',
      answer: 'Una vez que entras en el anuncio aparece un formulario para contactar con el anunciante.',
      open: false
    },
    {
      question: '¿Cómo anunciar un piso o una habitación?',
      answer: 'Puedes crear un anuncio yendo a la sección Alquiler para docentes y luego Publicar piso.',
      open: false
    },
    {
      question: '¿Tiene algún coste publicar un anuncio de alquiler?',
      answer: 'No, es completamente gratuito.',
      open: false
    },
    {
      question: '¿Cómo eliminar un anuncio de forma temporal y que vuelva a ser visible?',
      answer: 'Si has alquilado el piso temporalmente, puedes pausar el anuncio.',
      open: false
    },
    {
      question: '¿Cómo pausar un anuncio?',
      answer: 'Ve a la sección Alquiler para docentes y entra en el apartado Mis pisos.',
      open: false
    },
    {
      question: '¿Cómo activar un anuncio pausado?',
      answer: 'Ve a la sección Alquiler para docentes y entra en el apartado Mis pisos.',
      open: false
    },
    {
      question: '¿Cómo eliminar o modificar un anuncio de alquiler?',
      answer: 'Ve a la sección Alquiler para docentes y luego Mis pisos.',
      open: false
    }
  ];

  // tslint:disable-next-line:typedef
  toggleFaq(selectedFaq: any) {
    this.faqs.forEach(faq => {
      faq.open = faq === selectedFaq ? !faq.open : false;
    });
  }

  constructor() { }

  ngOnInit(): void {
  }

}
