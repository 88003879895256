import { Subscription } from 'rxjs';
import { ModalService } from './../../../services/modal.service';
import { RutasService } from 'src/app/services/rutas.service';
import { UserService } from 'src/app/services/user.service';
import { SubirImgsService } from 'src/app/services/subir-imgs.service';
import { Component, OnInit, ViewChild, ElementRef, Renderer2, ViewContainerRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

declare var $: any;


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {




  constructor(
    private router: Router,
    public auth: UserService,
    public userService: UserService,
    public rutasService: RutasService,
    public pisosService: SubirImgsService,
    private ElByClassName: ElementRef,
    private renderer: Renderer2,
    private modalService: ModalService
  ) { }

 // myTag = this.elementRef.nativeElement.getelementsByClassName('navbarClass');
  @ViewChild('modal', { read: ViewContainerRef })
  entry!: ViewContainerRef;
  sub!: Subscription;
  toggle = false;
  btnElement;
  userPisos=[];
  userRutas=[];
  @ViewChild('navbar') myDiv: ElementRef;

  openModal() {

    this.sub = this.modalService
      .openModal(this.entry, 'Vas a cerrar tu sesión', '¿Quieres continuar?')
      .subscribe((v) => {

       if (this.btnElement.classList.contains('show')) {
        this.btnElement.classList.remove('show');
       }else{
        this.btnElement.classList.add('show');
       }
        // if (this.myDiv.nativeElement.classList.contains('show')) {
        //   this.myDiv.nativeElement.classList.remove('show');
        // } else {
        //   this.myDiv.nativeElement.classList.add('show');
        // }
        // if(this.myTag.classList.contains('show'))
        // {
        //     this.myTag.classList.remove('show');
        // }else{
        //   this.myTag.classList.add('show');
        // }
        this.auth.logout();
        this.router.navigate(['/home']);

      }
      );


  }
  ngOnDestroy(): void {

    if (this.sub) {
      this.sub.unsubscribe();
     }
  }


  clickEvent(event) {

    if (this.myDiv.nativeElement.classList.contains('show')) {
      this.myDiv.nativeElement.classList.remove('show');
    } else {
      this.myDiv.nativeElement.classList.add('show');
    }


    // this.toggle = !this.toggle;
  }
  clickEvent2() {

    if (this.btnElement.classList.contains('show')) {
      this.btnElement.classList.remove('show');
     }else{
      this.btnElement.classList.add('show');
     }

  }

  ngOnInit(): void {

    if(this.userService.usuarioLogged){
      this.rutasService.getMisRutas(this.userService.usuarioLogged._id).subscribe((res:any) => {
        this.userRutas = res.misRutas;


      });
      this.pisosService.getMisPisos(this.userService.usuarioLogged._id).subscribe((res:any) => {
            this.userPisos = res.misPisos

      });


    }




  }
  ngAfterViewInit() {
    this.btnElement = (<HTMLElement>this.ElByClassName.nativeElement).querySelector(
    '.navbarClass'
    );

  }
  // tslint:disable-next-line:typedef
  buscarRuta(termino: string) {
    // console.log(termino);
    this.router.navigate(['/buscador', termino]);

  }
  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  logout() {
    // this.auth.logout();
    localStorage.clear();
    this.rutasService.params.origenProvincia = '';
    this.rutasService.params.origenLocalidad = '';
    this.rutasService.params.destinoProvincia = '';
    this.rutasService.params.destinoLocalidad = '';
    this.rutasService.params.destinoCentro = '';
    this.rutasService.params.tienesCoche = '';
    this.rutasService.params.sinCoche = '';

  }


  clearSearch() {

    this.rutasService.params.origenProvincia = '';
    this.rutasService.params.origenLocalidad = '';
    this.rutasService.params.destinoProvincia = '';
    this.rutasService.params.destinoLocalidad = '';
    this.rutasService.params.destinoCentro = '';
    this.rutasService.params.tienesCoche = '';
    this.rutasService.params.sinCoche = '';

  }



}
