import { LocalidadesService, Localidad } from './../../services/localidades.service';
import { Component, OnInit } from '@angular/core';
import { SubirImgsService } from 'src/app/services/subir-imgs.service';
import { UserService } from 'src/app/services/user.service';
import { NgForm, FormControl, Validators, FormGroup } from '@angular/forms';
import { LocalidadModel } from 'src/app/models/localidades.model';
import { ProvinciasService, Provincia } from './../../services/provincias.service';
import { ProvinciaModel } from './../../models/provincia.model';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-pisos',
  templateUrl: './pisos.component.html',
  styleUrls: ['./pisos.component.css']
})
export class PisosComponent implements OnInit {

  pisos = [];
  user;
  habitaciones;
  metros;
  ascensor;
  precio;
  selectOrigen;
  origenLocalidad;
  mascota;
  toggleClass = false;
  tipo;
  ProdData: any[] = [];
  filtroHabitaciones = [];
  public localidadesOrigen: Localidad[] = [];
  public provincias: Provincia[] = [];

  provincia: ProvinciaModel;
  localidad;
  submitted = false;
  resultados = false;
  form: FormGroup;
  isFavorite = false;

  constructor(
    private pisosService: SubirImgsService,
    public userService: UserService,
    private localidadesService: LocalidadesService,
    private provinciasService: ProvinciasService,
    private scroller: ViewportScroller,
  ) { }

  ngOnInit(): void {


    this.form = new FormGroup({
      provincia: new FormControl('', Validators.required),

    });


    this.ProdData = [

    ];
    // Default sort order on page load
    this.ProdData = this.ProdData.sort((low, high) => low.precio - high.precio);
    this.provincia = new ProvinciaModel();
    this.getProvincias();

    if (this.pisosService.params.localidad !== '') {
      this.getParams();
    }
    // this.getPisos();


    // this.pisosService
    //   .getPisosFiltrados(
    //     this.localidad,
    //     this.habitaciones,
    //     this.metros,
    //     this.ascensor,
    //   )
    //   .subscribe((res: any) => {
    //     this.pisos = res.pisosFiltrados;
    //     console.log(this.pisos)
    //     });

    if (this.ProdData.length === 0) {
      this.resultados = false
    } else {
      this.resultados = true;
      this.goResultados();
    }
  }

  getPisos() {

    this.pisosService.getPisos().subscribe((res: any) => {
      this.pisos = res.pisos;


    });
  }
  sort(event: any) {
    switch (event.target.value) {
      case "Low":
        {
          this.ProdData = this.ProdData.sort((low, high) => low.precio - high.precio);
          break;
        }

      case "High":
        {
          this.ProdData = this.ProdData.sort((low, high) => high.precio - low.precio);
          break;
        }

      // case "Name":
      //   {
      //     this.ProdData = this.ProdData.sort(function (low, high) {
      //       if (low.Name < high.Name) {
      //         return -1;
      //       }
      //       else if (low.Name > high.Name) {
      //         return 1;
      //       }
      //       else {
      //         return 0;
      //       }
      //     })
      //     break;
      //   }

      default: {
        this.ProdData = this.ProdData.sort((low, high) => low.Price - high.Price);
        break;
      }

    }
    return this.ProdData;

  }
  selectChangeOrigen() {
    this.getLocalidadesOrigen();
    this.toggleClass = !this.toggleClass;
    // this.filled = true;
  }
  changeReset() {
    this.submitted = false;
    // this.filled = true;
  }

  getLocalidadesOrigen() {
    let id = this.selectOrigen;
    this.localidadesService.getLocalidades(id).subscribe((res: any) => {
      this.localidadesOrigen = res.localidades;

    });
  }

  getProvincias() {
    this.provinciasService.getProvincias().subscribe((res: any) => {
      this.provincias = res.provincias;

    });
  }

  checkColor() {

    if (this.origenLocalidad !== ' ') {
    }
    else {
    }
  }

  addFilterHab(value) {




    this.pisosService
      .getPisosFiltrados(
        this.precio,
        this.localidad,
        this.habitaciones,
        this.metros,
        this.ascensor,
        this.mascota,
        this.tipo,
      )
      .subscribe((res: any) => {
        // this.pisos = res.pisosFiltrados;
       // console.log(res);
        this.pisos = res.pisosFiltrados;
      });


  }

  onSubmit(form: NgForm) {


    if (form.invalid) {
      return;
    }


    // if (this.ascensor === false) {
    //   this.ascensor = 'undefined'
    // }



    // if(this.mascota === true){
    //   this.mascota = 'si'
    // }else if(this.mascota === false){
    //   this.mascota = 'undefined'
    // }
    this.user = this.userService.getUser();


    /*envia datos a getParams*/

    this.pisosService.getParams(
      this.selectOrigen,
      this.precio,
      this.localidad,
      this.habitaciones,
      this.metros,
      this.ascensor,
      this.mascota,
      this.tipo,
    );

    /*-----------------------*/

    this.pisosService
      .getPisosFiltrados(
        this.precio,
        this.localidad,
        this.habitaciones,
        this.metros,
        this.ascensor,
        this.mascota,
        this.tipo
      )
      .subscribe((res: any) => {
        // this.pisos = res.pisosFiltrados;
        // console.log(res);
        this.pisos = res.pisosFiltrados;
        this.ProdData = res.pisosFiltrados;
        this.submitted = true;
        if (this.ProdData.length === 0) {
          this.resultados = false
        } else {
          this.resultados = true;
          this.goResultados();



        }




      });
  }


  getParams() {

    this.selectOrigen = this.pisosService.params.provincia;
    this.precio = this.pisosService.params.precio;
    this.localidad = this.pisosService.params.localidad;
    this.habitaciones = this.pisosService.params.habitaciones;
    this.metros = this.pisosService.params.metros;

    this.ascensor = this.pisosService.params.ascensor;
    this.mascota = this.pisosService.params.mascota;

    this.selectChangeOrigen();
    this.checkColor();


    this.pisosService
      .getPisosFiltrados(
        this.precio,
        this.localidad,
        this.habitaciones,
        this.metros,
        this.ascensor,
        this.mascota,
      )
      .subscribe((res: any) => {
        // this.pisos = res.pisosFiltrados;
        // console.log(res);
        this.pisos = res.pisosFiltrados;
      });
    // }
  }


  goResultados() {
    this.scroller.scrollToAnchor('resultados');
  }


}
