<div class="faq-container">
  <h1>Preguntas frecuentes</h1>
  <div class="faq-item" *ngFor="let faq of faqs" [ngClass]="{ 'open': faq.open }">
    <button class="faq-question" (click)="toggleFaq(faq)">
      {{ faq.question }}
    </button>
    <div class="faq-answer" [ngClass]="{ 'open': faq.open }">
      <p [innerHTML]="faq.answer"></p>
    </div>
  </div>
</div>
