import * as $ from 'jquery';
import { UsuarioModel } from './../../models/user.model';
import { Router } from '@angular/router';
import { UserService } from './../../services/user.service';
import { RutasService } from './../../services/rutas.service';
import { SubirImgsService } from './../../services/subir-imgs.service';
import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { EmailValidator, NgForm } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  user: UsuarioModel;
  showErrorMessage;
  token;
  show: boolean = false;
  mensajePop;
  usuario;
  respuesta = false;

  private timerHandle: number = null;

  constructor(
    public userService: UserService,
    public rutasService: RutasService,
    public pisosService: SubirImgsService,
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  @ViewChild('newsModal', { static: false }) divModal: ElementRef;
  open() {
    this.renderer.removeClass(this.divModal.nativeElement, 'none');
    this.renderer.addClass(this.divModal.nativeElement, 'modal');
  }

  close() {
    this.renderer.removeClass(this.divModal.nativeElement, 'modal');
    this.renderer.addClass(this.divModal.nativeElement, 'none');
  }

  siquiere() {
    this.respuesta = true;
    this.usuario.newsResp = 'si';
    this.usuario.newsletter = 'si';
    this.userService.editUser(this.usuario).subscribe(
      (res: any) => {


      },
      (error) => {
        this.showErrorMessage = true;
      },
      () => {

      }
    );
  }
  noquiere() {
    this.respuesta = true;
    this.usuario.newsResp = 'si';
    this.usuario.newsletter = 'no';
    this.userService.editUser(this.usuario).subscribe(
      (res: any) => {


      },
      (error) => {
        this.showErrorMessage = true;
      },
      () => {

      }
    );
  }

  enviaNews(){

  }
  ngOnInit(): void {
    this.user = new UsuarioModel();
    // setTimeout(() => {
    //   this.open();
    // }, 1000);
  }
  password() {
    this.show = !this.show;
  }
  onSubmit(form: NgForm) {
    if (form.invalid) {
      this.showErrorMessage = true;
      // return;
    }

    this.user.email = this.user.email.toLowerCase();

    this.userService.login(this.user).subscribe(
      (res: any) => {

        this.usuario = res.usuario;

        let token = JSON.stringify(res.token);
        this.token = token;
        res.usuario.token = res.token;

        const user = JSON.stringify(res.usuario);

        this.userService.editUser(res.usuario).subscribe((res) => {
          //ya se ha guardado el token

          localStorage.setItem('usuario', user);
          localStorage.setItem('token', token);
          this.userService.usuarioLogged = JSON.parse(
            localStorage.getItem('usuario')
          );

         // this.router.navigateByUrl('rutas');
          this.userService.usuarioLogged.token = token;
          this.userService.registroOk = false;
        
        });
      },
      (error) => {
        this.showErrorMessage = true;
      },
      () => {
        // this.userService.editUser( this.userService.usuarioLogged).subscribe((res: any) => {

        // });







        this.userService.loggedIn = true;

        if ( this.userService.loggedIn === true ){

              if (this.usuario.newsResp === 'no'){

                this.open();
              } else{
                 this.router.navigateByUrl('home');
              }


        }
      }
    );
  }
}
