<!-- hero area -->
<!-- Mashead header-->



<header class="masthead bg-white" id="cabecera">
  <div class="d-flex justify-content-center py-3" id="masrutas">
    <img class="me-2" src="./assets/images/point_white.png" width="23">
     <p class="lead fw-normal text-muted mb-0">+ de 500 rutas creadas</p>

  </div>
    <div class="container px-5 pt-2 pt-lg-0" id="cabeceraContent">
        <div class="row gx-5 align-items-center mt-5">
            <div class="col-lg-7">

                <!-- Mashead text and app badges-->
                <div class="mb-5 mb-lg-0 text-center text-lg-start">
                    <h1 class="display-1 lh-1 mb-3 mt-3 mt-lg-5" ><span style="font-weight:500">Docentes</span> en <span style="font-weight:500">Ruta</span></h1>
                    <p class="lead fw-normal  mb-5 mt-4">Tu web para encontrar compañer@s de coche o<br> piso de alquiler para docentes.</p>

                  <a class="mt-0 me-md-4 me-2 mb-4 mb-lg-0 btn btn-main btnMainHome shadow-none py-3" routerLinkActive="active" [routerLink]="['../registro']">Regístrate</a>
                    <a class="mb-4 me-2 me-md-0 me-lg-0 mb-lg-0 btn btn-main btnMainHome btnMainHome2 shadow-none py-3" routerLinkActive="active" [routerLink]="['../rutas']">Buscar rutas</a>


                    <!-- <div class="d-flex flex-column flex-lg-row align-items-center">
                        <a class="me-lg-3 mb-4 mb-lg-0 btn btn-main btnMainHome shadow-none py-2" routerLinkActive="active" [routerLink]="['../registro']">Regístrate</a>
                        <a class="me-lg-3 mb-4 mb-lg-0 btn btn-main btnMainHome btnMainHome2 shadow-none py-2" routerLinkActive="active" [routerLink]="['../rutas']">Busca rutas</a>
                        <a class="btn btn-main btnMainHome btnMainHome2 shadow-none py-2" routerLinkActive="active" [routerLink]="['../alquiler/pisos']">Busca pisos</a>

                    </div> -->
                </div>
            </div>
            <div class="col-lg-5">

                <!-- Masthead device mockup feature-->
                <div class="masthead-device-mockup">
                    <img class="img-fluid" src="./assets/images/4_c.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="row gx-5 align-items-center mt-5  ">
          <div class="col-lg-5 order-2 order-lg-1 order-md-1 mb-5 mb-lg-0">

            <!-- Masthead device mockup feature-->
            <div class="masthead-device-mockup">
                <img class="img-fluid" src="./assets/images/pisosHome.jpg" alt="">
            </div>
        </div>
          <div class="col-lg-7 order-1 order-lg-2 order-md-2">

              <!-- Mashead text and app badges-->
              <div class="mb-5 mb-lg-0 text-center text-lg-start">

                 <div class="alert alert-info p-5 mb-5">
                   <p class="lead fw-normal  mb-0  ">Este curso estrenamos la sección de alquiler de pisos para docentes. Regístrate y publica tu anuncio. </p>

                </div>
                <a class="mt-0 me-md-4 me-2 mb-4 mb-lg-0 btn btn-main btnMainHome shadow-none py-3" routerLinkActive="active" [routerLink]="['../alquiler/pisos']">Buscar piso</a>

                  <a class="mb-4 me-2 me-md-0 me-lg-0 mb-lg-0 btn btn-main btnMainHome btnMainHome2 shadow-none py-3" routerLinkActive="active" [routerLink]="['../alquiler/subirpiso']">Publicar piso</a>


                  <!-- <div class="d-flex flex-column flex-lg-row align-items-center">
                      <a class="me-lg-3 mb-4 mb-lg-0 btn btn-main btnMainHome shadow-none py-2" routerLinkActive="active" [routerLink]="['../registro']">Regístrate</a>
                      <a class="me-lg-3 mb-4 mb-lg-0 btn btn-main btnMainHome btnMainHome2 shadow-none py-2" routerLinkActive="active" [routerLink]="['../rutas']">Busca rutas</a>
                      <a class="btn btn-main btnMainHome btnMainHome2 shadow-none py-2" routerLinkActive="active" [routerLink]="['../alquiler/pisos']">Busca pisos</a>

                  </div> -->
              </div>
          </div>

      </div>
    </div>

</header>

<!-- Quote/testimonial aside-->
<aside class="text-center bg-gradient-primary-to-secondary bgInfo mt-5 mb-5">
    <div class="container px-3 px-lg-5">
        <div class="row  justify-content-center">
            <div class="col-xl-9">

                <div class="text-white mb-4 pt-4 px-xl-3 px-3">"En docentes en ruta sabemos que muchos docentes tenéis que recorrer largos trayectos hasta vuestro centro educativo, lo que supone grandes gastos y cansancio durante el curso escolar. Por eso hemos creado esta herramienta para que podáis
                    encontrar otros compañer@s con los que compartir esos gastos y hacer más amenas las horas en el coche"</div>
            </div>
        </div>
    </div>
</aside>

<div class="container px-1 px-lg-5" id="porque">
    <div class="row gx-5 align-items-center mx-0">
        <h2 id="h2porque" class="fs-1 text-center mb-5" style="color:#3f3f3f">¿Por qué usar Docentes en Ruta?</h2>
        <div class="col-lg-5">



            <div class="col-md-12 px-4 px-lg-4" style="align-self: center; padding-top: 20px;" id="iconosLista">

                <div class="row w-100">
                    <div class="col-md-2 icon" style="align-self: baseline; text-align: center; padding: 0px;">
                        <div style="width:100%;  border-radius: 50%; "><img src="./assets/images/icon1.png" alt="" class="img-fluid center-block"></div>
                    </div>
                    <div class="col-md-10">
                        <div class="mb-30 text-left text-md-left">

                            <h5 class="font-weight-bold  w-600 mb-2">Rápida</h5>
                            <p class="pInfo">Estás a un par de clics de encontrar a tus nuevos compañeros de ruta para este curso escolar o tu nueva casa para los próximos meses.</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- <div class="col-md-2"><img src="./assets/images/reloj.svg" width="60px" alt="" class=""></div> -->
                    <div class="col-md-2 icon" style="align-self: baseline; padding: 0px; text-align: center;">
                        <div style="width:100%;  border-radius: 50%; "> <img src="./assets/images/icon2.png" alt="" class="img-fluid"></div>
                    </div>
                    <div class="col-md-10">
                        <div class="mb-30 text-left text-md-left">

                            <h5 class="font-weight-bold  w-600 mb-2">Sencilla</h5>
                            <p class="pInfo">Sólo tienes que seleccionar la provincia, localidad y, si quieres, tu centro de destino.</p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <!-- <div class="col-md-2"><img src="./assets/images/reloj.svg" width="60px" alt="" class=""></div> -->
                    <div class="col-md-2 icon" style="align-self: baseline; text-align: center; padding: 0px;">
                        <div style="width:100%;  border-radius: 50%; "><img src="./assets/images/icon1.png" alt="" class="img-fluid center-block"></div>
                    </div>
                    <div class="col-md-10">
                        <div class="mb-30 text-left text-md-left">

                            <h5 class="font-weight-bold  w-600 mb-2">Sin registro</h5>
                            <p class="pInfo">No necesitas registrarte para comprobar las rutas disponibles.</p>
                        </div>
                    </div>
                </div>




            </div>
        </div>
        <div class="col-lg-7">

            <div class="masthead-device-mockup" id="imgHome2">
                <img class="img-fluid" src="./assets/images/6.jpg" alt="">
            </div>
        </div>
    </div>
</div>

<div class="container px-5 " id="infoHome">
    <div class="row gx-5 align-items-center py-3 py-sm-4 py-xxl-0">
        <div class="col-lg-4">
            <!-- Mashead text and app badges-->
            <div class="mb-3 mb-lg-0 text-center text-lg-end ">
                <img id="imgRegistra" class="img-fluid" src="./assets/images/click1.png" alt="">
            </div>
        </div>
        <div class="col-lg-8">
            <!-- Masthead device mockup feature-->
            <div class="masthead-device-mockup text-lg-start">

                <p class="titulo">Regístrate para crear tu propia ruta y que otros compañer@s se unan a ella.</p>
                <a class="btn btn-main bg-white text-dark shadow-none py-2" role="button" style="margin-top: 20px;" id="btnMainHome" routerLinkActive="active" [routerLink]="['../registro']">Ir a registro</a>
            </div>
        </div>
    </div>
</div>
<br>
<br>
<br>
<script src="https://cmp.osano.com/Azz7sdT3XAca668D/b50bdd9f-cf53-4a32-b845-a50ca6dff168/osano.js"></script>
<!-- <script id="CookieDeclaration" src="https://consent.cookiebot.com/f0dd574f-a084-489d-a579-f80d133cabb7/cd.js" type="text/javascript" async></script> -->
<!--

<div class="row">
  <div id="infoHome" class="row col-md-12">


    <div class="col-md-3">
        <img id="imgRegistra" class="img-fluid" src="./assets/images/click1.png" alt="">

  </div>

  <div class="col-md-9 " style="align-self: center; padding: 0 30px;">

  <p class="titulo">Regístrate para crear tu propia ruta y que otros compañer@s se unan a ella.</p>
<a class="btn btn-main bg-white text-dark" href="#about" role="button" style="margin-top: 20px;" id="btnMainHome">Ir a registro</a>
</div>
  </div>
  </div> -->
