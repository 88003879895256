
import { ContactoModel } from './../../models/contacto.model';
import { NgForm } from '@angular/forms';
import { SendMailService } from './../../services/send-mail.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  enviado = false;

  emailContacto: ContactoModel;
  @ViewChild('nombreInput') nombreInput: ElementRef;
  @ViewChild('emailInput') emailInput: ElementRef;
  @ViewChild('mensajeInput') mensajeInput: ElementRef;

  constructor(
    private router: Router,
    private  sendMailService: SendMailService,
    private scroller: ViewportScroller
  ) { }

  ngOnInit(): void {
    this.emailContacto = new ContactoModel();
  }
  // scrollToJustAbove(element, margin = 0) {
  //   const dims =  document.getElementById('errorName').getBoundingClientRect();
  //   window.scrollTo(window.scrollX, dims.top - margin);
  // }
  goErrorEmail(element, margin = 0) {

    const dims =  element.getBoundingClientRect();
    window.scrollTo(window.scrollX, dims.top - margin);
  }
  goErrorName(element, margin = 0) {
    const dims = element.getBoundingClientRect();
    window.scrollTo(window.scrollX, dims.top - margin);
  }

  goErrorMensaje(element, margin = 0) {
    const dims =  element.getBoundingClientRect();
    window.scrollTo(window.scrollX, dims.top - margin);
  }
  goSuccess(element, margin = 0) {
    const dims =  element.getBoundingClientRect();
    window.scrollTo(window.scrollX, dims.top - margin);
  }

  onSubmit(form: NgForm) {

    if (form.invalid) {
      this.enviado = false;
      if(form.submitted &&
        form.controls['username'].errors){
          let element = document.getElementById('errorName');
          this.goErrorName(element);
        }
      if(form.submitted &&
        form.controls['email'].errors){
          let element = document.getElementById('errorMail');
          this.goErrorEmail(element);
        }
      if(form.submitted &&
        form.controls['mensaje'].errors){
          let element = document.getElementById('errorMensaje');
          this.goErrorEmail(element);
        }
      return;
    }
    this.enviado = true;
    //this.goEnviado();
    let element = document.getElementById('alertSuccess');
    this.goSuccess(element);
    this.clearSearchInput();


    this.sendMailService.sendMailContacto(this.emailContacto).subscribe((res: any) => {



      // if (res.success === true) {
      //   this.enviado = true;
      // } else {
      //  // this.mensaje = res.mensaje;
      // }
    }
    );
  }

  goEnviado() {
    this.scroller.scrollToPosition([0, 0]);

  }
  clearSearchInput(){
    this.nombreInput.nativeElement.value = '';
    this.emailInput.nativeElement.value = '';
    this.mensajeInput.nativeElement.value = '';
 }
}
