import { ModalService } from './../../services/modal.service';
import { UserService } from 'src/app/services/user.service';
import { Component, OnInit, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

  //showAlerts;
  //alertaTxt;


  constructor(
    private userService: UserService,
    private modalService: ModalService
  ) { }


  @ViewChild('modal', { read: ViewContainerRef })
  entry!: ViewContainerRef;
  sub!: Subscription;

  createModal() {
   this.modalService.openModal(this.entry, 'Are you sure ?', 'click confirm or close')
      .subscribe((v) => {
        //your logic
      });
  }
  ngOnDestroy(): void {
    if (this.sub) { this.sub.unsubscribe(); }
  }
  // checkAlerts() {

  //   if (this.userService.usuarioLogged.alertas) {
  //     this.showAlerts = true;
  //     this.alertas = this.userService.usuarioLogged.alertas;


  //   }

  // }

  // deleteAlert(alerta){

  //   if (this.userService.usuarioLogged.alertas.lenght < 1){
  //     this.showAlerts = false;
  //   }

  //   this.alertas = this.userService.usuarioLogged.alertas;
  //   let index = this.alertas.indexOf(alerta);
  //   this.alertas.splice(index, 1);
  //   this.userService.usuarioLogged.alertas = this.alertas;
  //   this.userService.editUser(this.userService.usuarioLogged).subscribe((res: any) => {

  //       const user = JSON.stringify(res.usuario);
  //       localStorage.setItem('usuario', user);


  //   });

  // }

ngOnInit(): void {
  // if ( this.userService.usuarioLogged){
  // this.checkAlerts();
 // }

}

}
