import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UserService } from './../../services/user.service';
import { SubirImgsService, Piso } from 'src/app/services/subir-imgs.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';



@Component({
  selector: 'app-mispisos',
  templateUrl: './mispisos.component.html',
  styleUrls: ['./mispisos.component.css']
})
export class MispisosComponent implements OnInit {


  pisos: any[] = [];
  public misPisos: Piso[] = [];

  termino: string;
  piso: any = {};
  user = this.userService.usuarioLogged._id;
  userObj = this.userService.usuarioLogged;
  pisoBorrar;
  showMessage = false;
  mensajeConfirma;
  mensajePop;
  botonPop;


  constructor(
    private pisosService: SubirImgsService,
    private activatedRoute: ActivatedRoute,
    public userService: UserService,
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer2,

  ) { }

  @ViewChild('exampleModal', { static: false }) divModal: ElementRef;

  ngOnInit(): void {

    this.getMisPisos(this.user);
  }

  getMisPisos(user: string) {

    // this.user = this.userService.usuarioLogged._id;
     this.pisosService.getMisPisos(this.user).subscribe((res: any) => {
     this.misPisos = res.misPisos;
    });
  }

  open() {
    this.renderer.removeClass(this.divModal.nativeElement, 'none');
    this.renderer.addClass(this.divModal.nativeElement, 'modal');
  }

  close() {
    this.renderer.removeClass(this.divModal.nativeElement, 'modal');
    this.renderer.addClass(this.divModal.nativeElement, 'none');
  }
  borrarPiso(piso) {
    this.pisoBorrar = piso;
    this.open();
    this.mensajePop = 'Vas a eliminar el anuncio';
    this.botonPop = 'borrar';
  }

  updatePisoPublicado(piso) {


    this.pisosService.updatePisoPublicado(piso._id, piso.pausado).subscribe(
      (updatedPiso) => {

        this.piso.pausado = updatedPiso.pausado;
        // console.log('Piso actualizado:', updatedPiso);
      },
      (error) => {
        console.error('Error actualizando el piso:', error);
      }
    );
  }

  confirmaEliminar(piso) {
    this.pisoBorrar = piso;
    // console.log(piso._id)

    this.pisosService.eliminarPiso(piso._id).subscribe((res: any) => {
      this.showMessage = true;
      this.mensajeConfirma = 'Has eliminado el anuncio';
      this.getMisPisos(this.user);
      this.close();
    });
  }

}
