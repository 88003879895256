<section class="section" id="pisoInfo">
  <div class="container">
    <div class="row mt-3 pt-2">

      <div class="col-md-2">
        <div>
          <!--
          <form #filter>


            <div class="row">
              <p>Habitaciones</p>
              <p>
                <label for="filterCheckHab1">1
                </label>
                <input type="checkbox" class="form-check-input ms-2" name="addfilterCheckHab1" id="filterCheckHab1"
                  autocomplete="off" value="1" (click)="addFilterHab($event.target.value)"/>

              </p>
              <p>
                <label for="filterCheckHab2">2
                </label>
                <input type="checkbox" class="form-check-input ms-2" name="addfilterCheckHab2" id="filterCheckHab2"
                  autocomplete="off" value="2" (click)="addFilterHab($event.target.value)"/>

              </p>
              <p>
                <label for="filterCheckHab3">3
                </label>
                <input type="checkbox" class="form-check-input ms-2" name="addfilterCheckHab3" id="filterCheckHab3"
                  autocomplete="off" value="3" (click)="addFilterHab($event.target.value)"/>

              </p>
              <p>
                <label for="filterCheckHabMas">Más de 3
                </label>
                <input type="checkbox" class="form-check-input ms-2" name="addfilterCheckHabMas" id="filterCheckHabMas"
                  autocomplete="off" value="10" (click)="addFilterHab($event.target.value)" />

              </p>
            </div>


            <div class="row">
              <p>Precio</p>
              <p>
                <label for="filterCheckHab1">Límite de precio
                </label>
                <input type="text" class="form-check-input w-100" name="filterCheckHab1" id="filterCheckHab1"
                  autocomplete="off" [(ngModel)]="this.precio" />
              </p>
            </div>


              <div class="row">
              <p>Mascotas</p>
              <p>
                <label for="filterCheckHab1">Si
                </label>
                <input type="checkbox" class="form-check-input ms-2" name="filterCheckHab1" id="filterCheckHab1"
                  autocomplete="off" [(ngModel)]="this.mascota" />

              </p>
              <p>
                <label for="filterCheckHab2">No
                </label>
                <input type="checkbox" class="form-check-input ms-2" name="filterCheckHab2" id="filterCheckHab2"
                  autocomplete="off" [(ngModel)]="this.mascota" />

              </p>
              <p>
                <label for="filterCheckHab3">Gatos
                </label>
                <input type="checkbox" class="form-check-input ms-2" name="filterCheckHab3" id="filterCheckHab3"
                  autocomplete="off" [(ngModel)]="this.mascota" />

              </p>
              <p>
                <label for="filterCheckHab3">Perros
                </label>
                <input type="checkbox" class="form-check-input ms-2" name="filterCheckHab3" id="filterCheckHab3"
                  autocomplete="off" [(ngModel)]="this.mascota" />

              </p>
            </div>


              <div class="row">
                <p>Ascensor</p>
                <p>
                  <label for="filterCheckHab1">Si
                  </label>
                  <input type="checkbox" class="form-check-input ms-2" name="filterCheckHab1" id="filterCheckHab1"
                    autocomplete="off" [(ngModel)]="this.habitaciones" />

                </p>
                <p>
                  <label for="filterCheckHab1">No
                  </label>
                  <input type="checkbox" class="form-check-input ms-2" name="filterCheckHab1" id="filterCheckHab1"
                    autocomplete="off" [(ngModel)]="this.habitaciones" />

                </p>

              </div>



          </form> -->

        </div>
      </div>

      <div class="col-md-12 col-lg-8 px-lg-4 px-md-4 px-3">
        <h1>Encuentra piso de alquiler</h1>
        <br />


        <form class="login100-form validate-form flex-sb flex-w" (ngSubmit)="onSubmit(pisoFiltroForm)"
          #pisoFiltroForm="ngForm">



          <!-- LOCALIDAD -------------------------------------------------------------->
          <!-- LOCALIDAD -------------------------------------------------------------->

          <div class="row mb-3 mt-3">
            <div class="wrap-input100 mb-3">
            <div class="col-md-9 d-flex ">
              <div class="btn-group wrap-input100 mb-3" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="form-check-input checkMiddle ms-2 me-2 py-0 my-0" name="tipo" id="tipoEntera"
                  autocomplete="off" [(ngModel)]="this.tipo" value="Piso" required />
                <label class="" for="tipoEntera">Vivienda entera</label>

                <input type="radio" class="form-check-input checkMiddle ms-5  me-2 py-0 my-0" name="tipo"
                  id="tipoHabitacion" autocomplete="off" [(ngModel)]="this.tipo" value="Habitacion" required />
                <label class="" for="tipoHabitacion">Habitación</label>

              </div>



            </div>
            <div *ngIf="
               pisoFiltroForm.submitted &&
               pisoFiltroForm.controls['tipo'].errors
             " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4 col-md-6">
              Selecciona tipo de alojamiento
            </div>
          </div>
        </div>

          <div class="row">
            <div class="col-md-6 rowForm">

              <div class="wrap-input100 mb-3">
                <select id="provincia" name="origenProvincia"
                  class="w-100 paddingOption select-css form-select form-select-lg mb-3" [(ngModel)]="selectOrigen"
                  (change)="selectChangeOrigen(); changeReset()" required>
                  <option class="dropdown-item" disabled selected value="undefined">
                    Provincia
                  </option>

                  <option style="
                      font-weight: normal;
                      display: block;
                      white-space: nowrap;
                      min-height: 1.2em;
                      padding: 10px 2px 1px;
                    " *ngFor="let item of provincias | sortbyName:'nm'" [ngValue]="item.id">
                    {{ item.nm }}
                  </option>
                </select>

                          <!-- <div *ngIf="
                          pisoFiltroForm.submitted &&
                          form.controls['provincia'].invalid
                        " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                    Selecciona una provincia
                  </div> -->
                <div *ngIf="
                  pisoFiltroForm.submitted &&
                  pisoFiltroForm.controls['origenProvincia'].errors
                " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                  Selecciona una provincia
                </div>
              </div>


            </div>
            <div class="col-md-6 rowForm">

              <div class="wrap-input100 mb-3">

                <select [disabled]="!selectOrigen" name="origenLocalidad" id="localidad"
                  class="w-100 select-css form-select form-select-lg mb-3" [(ngModel)]="this.localidad"
                  data-live-search="true" (change)="checkColor(); changeReset()" required>
                  <option class="dropdown-item" disabled selected value="undefined">
                    Localidad
                  </option>
                  <option [value]="item" *ngFor="let item of localidadesOrigen | sortbyName:'nm'" [ngValue]="item.nm">
                    {{ item.nm }}
                  </option>
                </select>
                <!-- <div *ngIf="
                pisoFiltroForm.submitted &&
                form.controls['localidad'].invalid
              " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
          Selecciona una localidad
        </div> -->

                <div *ngIf="
                pisoFiltroForm.submitted &&
                pisoFiltroForm.controls['origenLocalidad'].errors
              " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                  Selecciona una localidad
                </div>
              </div>


            </div>
          </div>

          <!-- ROW  -------------------------------------------------------------->
          <!-- ROW -------------------------------------------------------------->

          <div class="row">
            <div class="col-md-12 col-xl-6">
              <div class="col-md-7" style="
                margin-bottom: 0px;
                display: flex;
                margin-bottom: 0px;
                align-items: center;
              ">
                <p>Selecciona tu rango de precio</p>
              </div>
              <div class="wrap-input100 mb-3">
                <div class="btn-group wrap-input100 mb-3" role="group" aria-label="Basic radio toggle button group"
                  (change)="changeReset()">
                  <input type="radio" class="btn-check" name="btnPrecio" id="filterPrice400" autocomplete="off" checked
                    value="400" [(ngModel)]="this.precio" />
                  <label class="btn btn-outline-primary" for="filterPrice400">Hasta 400€</label>

                  <input type="radio" class="btn-check" name="btnPrecio" id="filterPrice600" autocomplete="off"
                    value="600" [(ngModel)]="this.precio" />
                  <label class="btn btn-outline-primary" for="filterPrice600">Hasta 600€</label>
                  <input type="radio" class="btn-check" name="btnPrecio" id="filterPriceMore600" autocomplete="off"
                    value="mas600" [(ngModel)]="this.precio" />
                  <label class="btn btn-outline-primary" for="filterPriceMore600">Todos</label>
                </div>

              </div>


            </div>

            <!-- COL RIGHT--------------------------->
            <!-- COL RIGHT--------------------------->


            <div class="col-md-12 col-xl-6 " *ngIf="this.tipo === 'Piso' || this.tipo === undefined ">

              <div class="row">
                <div class="col-md-12 ">


                  <div class="col-md-8 " style="
                    margin-bottom: 0px;
                    display: flex;
                    margin-bottom: 0px;
                    align-items: center;
                  ">
                    <p>Habitaciones</p>
                  </div>
                  <div class="wrap-input100 mb-0">
                    <div class="btn-group wrap-input100 mb-3" role="group" aria-label="Basic radio toggle button group">
                      <input type="radio" class="btn-check" name="btnHabita" id="filterHab0" autocomplete="off"
                        value="0" [(ngModel)]="this.habitaciones" />
                      <label class="btn btn-outline-primary" for="filterHab0">Me da igual</label>
                      <input type="radio" class="btn-check" name="btnHabita" id="filterHab1" autocomplete="off"
                        value="1" [(ngModel)]="this.habitaciones" />
                      <label class="btn btn-outline-primary" for="filterHab1">1</label>

                      <input type="radio" class="btn-check" name="btnHabita" id="filterHab2" autocomplete="off"
                        value="2" [(ngModel)]="this.habitaciones" />
                      <label class="btn btn-outline-primary" for="filterHab2">2</label>
                      <input type="radio" class="btn-check" name="btnHabita" id="filterHabitaMas2" autocomplete="off"
                        value="mas2" [(ngModel)]="this.habitaciones" />
                      <label class="btn btn-outline-primary" for="filterHabitaMas2"> 3 o +</label>
                    </div>

                  </div>


                </div>
                <!-- <div class="col-md-4 ">


                  <div class="col-md-4 " style="
                margin-bottom: 0px;
                display: flex;
                margin-bottom: 0px;
                align-items: center;
              ">
                    <p>Ascensor</p>
                  </div>
                  <div class="wrap-input100 mb-3">
                    <div class="btn-group wrap-input100 mb-3" role="group" aria-label="Basic radio toggle button group">
                      <input type="checkbox" class="form-check-input checkBig" name="btnAscensor" id="btnAscensor"
                        autocomplete="off" [(ngModel)]="this.ascensor" />
                      <label for="btnAscensor">
                      </label>
                    </div>

                  </div>


                </div> -->

              </div>
            </div>
            <!-- FIN COL RIGHT--------------------------------->
            <!-- FIN COL RIGHT--------------------------------->

          </div>


          <!-- ROW  -------------------------------------------------------------->
          <!-- ROW -------------------------------------------------------------->

          <div class="row">
            <div class="col-md-6 ">


              <div class="col-md-12 " style="
                margin-bottom: 0px;
                display: flex;
                margin-bottom: 0px;
                align-items: center;
              ">
                <p>Necesito que admita mascotas</p>
              </div>
              <div class="wrap-input100 mb-3">
                <div class="btn-group wrap-input100 mb-3" role="group" aria-label="Basic radio toggle button group">
                  <input type="checkbox" class="form-check-input checkBig" name="btnMascota" id="btnMascota"
                    autocomplete="off" [(ngModel)]="this.mascota" />
                  <label for="btnMascota">
                  </label>
                </div>

              </div>


            </div>
            <div class="col-md-6 ">


              <div class="col-md-12 " style="
                margin-bottom: 0px;
                display: flex;
                margin-bottom: 0px;
                align-items: center;
              ">
               <p>Ascensor</p>
              </div>
              <div class="wrap-input100 mb-3">
                <div class="btn-group wrap-input100 mb-3" role="group" aria-label="Basic radio toggle button group">
                  <input type="checkbox" class="form-check-input checkBig" name="btnAscensor" id="btnAscensor"
                    autocomplete="off" [(ngModel)]="this.ascensor" />
                  <label for="btnAscensor">
                  </label>
                </div>

              </div>


            </div>
          </div>

          <div class="row">
            <div class="wrap-input100 mb-3">
              <button type="submit" class="btn btn-main buscarRutas animated fadeInDown">
                Buscar Pisos
              </button>
            </div>
          </div>

          <!-- ROW  -------------------------------------------------------------->
          <!-- ROW -------------------------------------------------------------->
          <div class="row">
            <div class="col-md-12 rowForm">

              <select id="sort-by" (change)="sort($event)"
                class="paddingOption select-css form-select w-auto mt-3 form-select-lg mb-1 ng-untouched ng-pristine ng-valid"
                *ngIf="resultados && userService.loggedIn">
                <option value="" selected disabled hidden>Ordenar por</option>
                <!-- <option value="Name">Name</option> -->
                <option value="Low">Más baratos primero</option>
                <option value="High">Más caro primero</option>
              </select>
              <div id="resultados"></div>
              <div class="alert alert-info mt-3" *ngIf="resultados === false && submitted && tipo === 'Piso'">
                Lo sentimos, no hay ningún piso disponible en la localidad que has seleccionado
              </div>
              <div class="alert alert-info mt-3" *ngIf="resultados === false && submitted && tipo === 'Habitacion'">
                Lo sentimos, no hay ninguna habitación disponible en la localidad que has seleccionado
              </div>


              <div class="alert alert-info text-center w-100 py-4 mt-5" role="alert"
                *ngIf="this.pisos.length > 0 && !userService.loggedIn">

                Hemos encontrado <strong>{{ this.pisos.length }}</strong> pisos que pueden interesarte. Regístrate para
                ver todos los detalles.<br /><br />
                <button class="btn btn-primary" [routerLink]="['../registro']">
                  Ir a Registro
                </button>
              </div>







              <div class="row row-cols-1 row-cols-md-1 g-4 mt-0" *ngIf="this.userService.loggedIn">
                <div class="col" *ngFor="let piso of pisos; let i = index">



                  <div class="card" [routerLink]="['/alquiler/piso', piso._id]" role="button">

                    <div class=" p-1">
                      <div class="row">
                        <div class="col-md-8 p-4 pb-0 pb-xl-0 ">
                          <div class="titCard">
                            <h5 class="card-title">
                              {{ piso.tipo }} en {{ piso.localidad }} {{ piso.titulo }}
                            </h5>
                          </div>
                          <div class="titCard">
                            <h5 class="card-title">
                              {{ piso.precio}}€
                            </h5>

                          </div>


                          <p class="card-text" *ngIf="piso.tipo === 'Piso'">
                            <span >{{ piso.habitaciones }} hab |</span>
                            <span class="ps-2">{{ piso.metros }}m2 |</span>
                            <span *ngIf="piso.ascensor" class="ps-2">Ascensor</span>
                          </p>


                          <p class="card-text" *ngIf="piso.tipo === 'Habitacion'">
                            <span class="ps-0">{{ piso.metros }}m2 |</span>
                            <span *ngIf="piso.ascensor" class="ps-2">Ascensor</span>
                          </p>



                          <p class="card-text">{{ piso.desc | slice:0:130 }}</p>
                        </div>
                        <div class="col-md-4 contPisoImg mb-4 mb-xl-0 mt-3 mt-xl-0">
                          <img class="w-100" [src]=piso.imgs[0] />
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>







        </form>




      </div>
    </div>
  </div>
  <!--container 1-->
</section>

<br /><br />
