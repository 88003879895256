

<nav class="navbar navbar-expand-lg navbar-light fixed-top bg-white" id="mainNav" style="background: #2a93eb;">
    <div class="container px-0 " style="padding-left: 0px!important;padding-right: 0px!important; ">
        <a class="navbar-brand fw-bold ms-3 me-3" href="#page-top" id="brandImage" style="align-items: flex-end;
      display: flex;"> <img class="img-fluid" src="./assets/images/logo_gris-01.svg" width="80" alt="" />
            <p style="text-decoration: none; display:inline; font-weight: 400; border: none; font-size: 22px; color: #585858; padding-bottom: 0px; margin: 0; line-height: 24px;">Docentes en Ruta</p>
        </a>
        <button class="navbar-toggler me-3 shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
        <div class="collapse navbar-collapse navbarClass" id="navbarResponsive" #navbar>
            <ul class="navbar-nav ms-auto  my-lg-0 position-relative bg-white">

                <li class="nav-item menu" routerLinkActive="active">
                    <a class="nav-link" aria-current="page" [routerLink]="['home']" (click)="clearSearch();" (click)="clickEvent($event)">Inicio</a>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown">Alquiler para docentes</a>
                   <ul class="dropdown-menu">
                    <li class="nav-item menu" routerLinkActive="active">
                      <a class="nav-link" aria-current="page" [routerLink]="['alquiler/pisos']" (click)="clearSearch();" (click)="clickEvent($event)">Buscar piso</a>
                  </li>
                   <li class="nav-item menu" routerLinkActive="active" >
                    <a class="nav-link" aria-current="page" [routerLink]="['alquiler/subirpiso']" (click)="clearSearch();" (click)="clickEvent($event)">Publicar piso</a>
                </li>
                   <li class="nav-item menu" routerLinkActive="active" *ngIf="userService.usuarioLogged && userPisos.length !== 0">
                    <a class="nav-link" aria-current="page" [routerLink]="['alquiler/mispisos']" (click)="clearSearch();" (click)="clickEvent($event)">Mis pisos</a>
                </li>
                   </ul>
               </li>
               <li class="nav-item dropdown">
                <a class="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown">Compartir coche</a>
                 <ul class="dropdown-menu">
                  <li class="nav-item menu" routerLinkActive="active">
                    <a class="nav-link" aria-current="page" [routerLink]="['rutas']" (click)="clearSearch();" (click)="clickEvent($event)">Rutas</a>
                </li>
                <li class="nav-item menu" routerLinkActive="active" *ngIf="userService.usuarioLogged">
                    <a class="nav-link" aria-current="page" [routerLink]="['crearRuta']" (click)="clearSearch();" (click)="clickEvent($event)">Crear ruta</a>
                </li>

                <li class="nav-item menu" routerLinkActive="active" *ngIf="userService.usuarioLogged && userRutas.length !== 0">
                    <a class="nav-link" aria-current="page" [routerLink]="['misRutas']" (click)="clearSearch();" (click)="clickEvent($event)">Mis rutas</a>
                </li>

                 </ul>
             </li>





               <li class="nav-item menu" routerLinkActive="active">
                <a class="nav-link" aria-current="page" [routerLink]="['contacto']" (click)="clearSearch();" (click)="clickEvent($event)">Contacto</a>
            </li>
            <li class="nav-item menu" routerLinkActive="active" *ngIf="!userService.usuarioLogged">
              <a class="nav-link" aria-current="page" [routerLink]="['registro']" (click)="clearSearch();" (click)="clickEvent($event)">Registro</a>
          </li>

            <li class="nav-item menu" routerLinkActive="active" *ngIf="userService.usuarioLogged">
              <a class="nav-link" aria-current="page" [routerLink]="['miCuenta']" (click)="clearSearch();" (click)="clickEvent($event)">Mi Cuenta</a>
           </li>
                <li class="nav-item menu switch  " #switch routerLinkActive="active" *ngIf="!userService.usuarioLogged">
                    <a class="nav-link loginborder" aria-current="page" [routerLink]="['login']" (click)="clearSearch();" (click)="clickEvent($event)">

                       LOGIN</a>
                </li>

                <li class="nav-item " routerLinkActive="active" *ngIf="userService.usuarioLogged">
                    <a class="nav-link loginborder " aria-current="page"  (click)="openModal()"

                    style="cursor: pointer;"

                    > LOGOUT</a>
                </li>

            </ul>
            <div class="modal-container">

              <div #modal></div>
            </div>
        </div>
    </div>
</nav>
