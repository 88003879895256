<section class="section" id="pisoInfo">
  <div class="container " >
    <div class="row mt-3 pt-2">
      <div class="col-md-2" >
      </div>
      <!--col lat izq-->
      <!-- <ng-image-slider [images]="imgCollection" #nav></ng-image-slider> -->


      <div class="col-xl-7 col-md-12 col-12  px-lg-5 px-md-5 px-3">

        <h1 class="px-3 px-md-0 px-lg-0">{{piso.tipo}} en {{piso.localidad}} {{piso.titulo}}</h1>

        <div (click)="back()" class="mb-3">
          <div class="arrow"></div>

        </div>
        <div class="container-fluid mt-0 mt-lg-3">

          <div class="containerSlider " #container [ngStyle]="{width:100+'%'}" >
            <ng-image-slider #nav [images]="imgCollection" [infinite]="false" [manageImageRatio]=true [autoSlide]="1"
            [animationSpeed]="1"  [imageSize]="{width: '100%', height: '130%'}" slideImage="1"></ng-image-slider>
      </div>
      <!-- <div style="width: 420px; height: 500px; text-align:center;margin:0; padding:0;">
        <ng-image-slider #nav
          [images]="imgCollection"
          [imagePopup]="false"
          [infinite]="false"
          [autoSlide]="1"
          [imageSize]="{width: '100%', height: '400'}"
          slideImage="1"
        ></ng-image-slider>
      </div> -->

          <!-- <ngb-carousel>
            <ng-template ngbSlide *ngFor="let image of piso.imgs" >
              <div class="wrapper">
                <img [src]="image" alt="Random first slide" style="height:400px">
              </div>

            </ng-template>
          </ngb-carousel> -->

          </div>

          <div class="row mt-2 gx-5 px-lg-4 ps-lg-4 ps-md-4">
            <div class=" col-md-12 col-lg-12 col-sm-12">
              <div class=" px-lg-4 pt-4 pt-sm-0 pt-xl-2" >

                <div class="d-flex flex-row flex-wrap" >
                  <div class=" d-flex align-items-center pisoPrecio" >
                    <span
                    > {{piso.precio}}€
                   </span>
                   </div>
                  <div class=" d-flex align-items-center pisoInfo ms-3" >
                  <span
                  > {{piso.metros}}m2
                 </span>
                 </div>

                  <div class=" d-flex align-items-center pisoInfo ms-3" *ngIf="piso.ascensor">
                    <span
                      class="">  Ascensor
                    </span>
                  </div>

                  <div class=" d-flex align-items-center pisoInfo ms-3" >
                    <span
                      class=""> {{piso.habitaciones}} habitaciones
                    </span>
                  </div>
                  <div class=" d-flex align-items-center pisoInfo ms-3" *ngIf="piso.mascotas === 'si'">
                    <span
                      class=""> Admite mascotas
                    </span>
                  </div>
                  <div class=" d-flex align-items-center pisoInfo ms-3" *ngIf="piso.mascotas === 'no'">
                    <span
                      class=""> No admite mascotas
                    </span>
                  </div>
                  <div class=" d-flex align-items-center pisoInfo ms-3" *ngIf="piso.mascotas === 'gatos'">
                    <span
                      class=""> Admite gatos
                    </span>
                  </div>
                  <div class=" d-flex align-items-center pisoInfo ms-3" *ngIf="piso.mascotas === 'perros'">
                    <span
                      class=""> Admite perros
                    </span>
                  </div>




                </div>
                <hr class="mt-4">
                <!-- <p class="card-text mb-2" class="card-title"><span class="regular">Fecha de inicio</span> : <span
                    class="bold">{{ruta.fechaInicio }}</span></p>
                <p class="card-text mb-2" class="card-title"><span class="regular">Fecha de fin</span> : <span
                    class="bold">{{ruta.fechaFin}}</span></p> -->


                <div class="col-md-2" style=" background: url('./assets/images/img_rutas_right.jpg') no-repeat 0 -36px;">
                </div>
                <!--col lat izq-->

              </div>
            </div>
          </div>
        <div class="row mt-2 gx-5  px-4 px-xl-5">
          <div class=" col-md-12 col-lg-12 mb-xl-4 mb-0 col-sm-12 px-0 px-xl-4">
            <div class=" px-4 px-md-0 py-2" >

              <div>

                <div class=" d-flex align-items-center pisoDesc pb-3"> <span
                    class=""> {{piso.desc}}</span></div>

              </div>
              <hr>
              <!-- <p class="card-text mb-2" class="card-title"><span class="regular">Fecha de inicio</span> : <span
                  class="bold">{{ruta.fechaInicio }}</span></p>
              <p class="card-text mb-2" class="card-title"><span class="regular">Fecha de fin</span> : <span
                  class="bold">{{ruta.fechaFin}}</span></p> -->




            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-12 px-5 px-md-0 px-lg-2">

        <div class="row pt-xl-2 pt-0 px-lg-2">
          <div class="px-3 px-xl-0">
          <div class="col-12 mt-xl-5 mt-3 alert alert-info p-4 p-xl-3" *ngIf="enviado === false">


            <form #formMensaje>
              <p> Contacta con el propietario</p>


              <div class="wrap-input100 mb-3">
                <input class="input100 form-control shadow-none" type="text" name="nombre"
                  placeholder="Nombre" minlength="2" required [(ngModel)]='mensajePiso.nombre'  />
                <div class="focus-input100"></div>
              </div>
              <div class="wrap-input100 mb-3">
                <input class="input100 form-control shadow-none" type="text" name="email"
                  placeholder="Email" minlength="2" required [(ngModel)]='mensajePiso.email'  />
                <div class="focus-input100"></div>
              </div>
              <div class="wrap-input100 mb-3">
                <textarea class="w-100 p-2 form-control" name="mensaje" placeholder="Estoy interesad@ en el piso..." [(ngModel)]='mensajePiso.mensaje' ></textarea>
              </div>
              <div>
                <button class="btn btn-main" type="submit" (click)="enviarMensaje()">Enviar</button>
              </div>


            </form>
          </div>
          </div>
          <div class="col-12 mt-5 py-4 alert alert-success" *ngIf="enviado">

            <p class="pb-0 mb-0">
              Tu mensaje se ha enviado correctamente
            </p>

          </div>


        </div>
      </div>
      <!--col lat izq-->
    </div>
  </div>
</section>
