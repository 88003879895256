import { RutasService } from 'src/app/services/rutas.service';
import { ParadasService } from './../../services/paradas.service';

import { UsuarioModel } from './../../models/user.model';

import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  EmailValidator,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';



@Component({
  selector: 'app-mi-perfil',
  templateUrl: './mi-perfil.component.html',
  styleUrls: ['./mi-perfil.component.css']
})
export class MiPerfilComponent implements OnInit {
  user: UsuarioModel;
  editar = false;
  usuarios;
  existeEmail;
  existeTfno;
  emailTemporal;
  tfnoTemporal;
  emailOriginal;
  editEmail = false;
  editTfno = false;
  tfnoValidate;
  tfnoEditado = false;

  constructor(
   // private userService: UserService,
    private paradasService: ParadasService,
    private rutasService: RutasService
  ) { }

  // editarFun(){
  //   if(this.editar === true){
  //     this.editar = false;
  //   }else{
  //     this.editar = true;
  //   }

  // }
  // cancelEmail(){this.editEmail = false}
  // editEmailFun(){

  //   this.editEmail = true;
  //   // this.tfnoEditado = false;

  //   // if(this.editEmail === true){
  //   //   this.editEmail = false;
  //   // }else{
  //   //   this.editEmail = true;
  //   // }
  //   // if(this.editTfno === true){
  //   //   this.editTfno = false;

  //   // }

  //   //alert(this.editEmail + 'dsdfdf');
  //  // alert(this.tfnoEditado+ 'tfno edit')
  // }
  // editTfnoFun(){

  //   if(this.editTfno === true){
  //     this.editTfno = false;

  //   }else{
  //     this.editTfno = true;

  //   }
  //   if(this.editEmail === true){
  //     this.editEmail = false;
  //   }
  // }
  // clickme(value) {
  //   if (value !== '') {
  //     if (/\d{9}/.test(value)) {
  //       this.tfnoValidate = true;
  //     } else {
  //       this.tfnoValidate = false;
  //       return false;
  //     }
  //   } else {
  //   }
  // }
  getUsers() {
    // this.userService.getUsers().subscribe((res: any) => {
    //   this.usuarios = res.users;

    // });
  }

  // checkMailLive(userMail) {
  //   for (let i = 0; i < this.usuarios.length; i++) {
  //     let email = this.usuarios[i].email;
  //     if (email !== userMail) {
  //       this.existeEmail = false;
  //     }
  //   }
  // }
  // onKeyMail(event: any) {
  //   // without type info

  //   this.checkMailLive(event.target.value);
  // }


  ngOnInit(): void {
    // this.user = new UsuarioModel();
    // this.user = this.userService.usuarioLogged;
    // this.emailOriginal = this.user.email.toLowerCase();
    // //console.log(this.user);

    // this.getUsers();
  }

  // tfnoEditadoFun(){
  //   this.tfnoEditado = true;

  // }
  // onSubmit(form: NgForm) {



  //   this.checkValues();
  //   if (form.invalid ||   this.existeEmail === true ||   this.existeTfno === true) {

  //     return;
  //   }else{

  //     if ( this.editEmail === true){
  //         this.user.email = this.emailTemporal.toLowerCase();
  //     }

  //     if ( this.editTfno === true){
  //       this.user.tfno = this.tfnoTemporal;
  //     }

  //   }

  //   this.userService.editUser(this.user).subscribe((res: any) => {
  //     //console.log(this.user);
  //     if (res.success === true) {


  //       this.editEmail = false;
  //       this.editTfno = false;

  //       if (this.tfnoEditado === false) {
  //        // alert(this.tfnoEditado);
  //         this.rutasService.updateMails(this.emailOriginal, this.emailTemporal).subscribe((res: any) => {

  //           if (res.success === true) {
  //             this.emailTemporal = '';
  //           }
  //         });

  //         this.paradasService.updateMails(this.emailOriginal, this.emailTemporal).subscribe((res: any) => {
  //           if (res.success === true) {
  //           }
  //         });

  //       } else {

  //        // alert('tfno editado true, no edita mails')

  //       }

  //     } else {



  //     }
  //   });


  // }


}
