import { SubirpisoComponent } from './components/subirpiso/subirpiso.component';
import { TestrutaComponent } from './components/testruta/testruta.component';

import { MantenimientoComponent } from './components/mantenimiento/mantenimiento.component';
import { MicuentaComponent } from './components/micuenta/micuenta.component';

import { MiPerfilComponent } from './components/mi-perfil/mi-perfil.component';
import { MisrutasComponent } from './components/misrutas/misrutas.component';

import { AuthguardService } from './services/authguard.service';
import { LoginComponent } from './components/login/login.component';

import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {RutasComponent} from './components/rutas/rutas.component';
import {RutaComponent} from './components/ruta/ruta.component';
import {BuscadorComponent} from './components/buscador/buscador.component';
import {RegistroComponent} from './components/registro/registro.component';
import { CrearRutaComponent } from './components/crear-ruta/crear-ruta.component';
import { EditarRutaComponent } from './components/editar-ruta/editar-ruta.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ResetPassComponent } from './components/reset-pass/reset-pass.component';
import { RecuperarPasswordComponent } from './components/recuperar-password/recuperar-password.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { AvisolegalComponent } from './components/avisolegal/avisolegal.component';
import { PoliticaprivacidadComponent } from './components/politicaprivacidad/politicaprivacidad.component';
import { CookiesComponent } from './components/cookies/cookies.component';
import { PisoComponent } from './components/piso/piso.component';
import { PisosComponent } from './components/pisos/pisos.component';
import { MispisosComponent } from './components/mispisos/mispisos.component';
import { EditPisoComponent } from './components/edit-piso/edit-piso.component';
















const APP_ROUTES: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'rutas', component: RutasComponent, /*canActivate : [AuthguardService]*/ },
  { path: 'registro', component: RegistroComponent },
  { path: 'login', component: LoginComponent },
  { path: 'crearRuta', component: CrearRutaComponent, canActivate : [AuthguardService] },
  { path: 'misRutas', component: MisrutasComponent, canActivate : [AuthguardService] },
  { path: 'miCuenta', component: MicuentaComponent, canActivate : [AuthguardService] },
  { path: 'ruta/:id', component: RutaComponent, canActivate : [AuthguardService] },
  { path: 'editarRuta/:id', component: EditarRutaComponent, canActivate : [AuthguardService] },
  { path: 'buscador/:termino', component: BuscadorComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'reset-pass', component: ResetPassComponent },
  { path: 'contacto', component: ContactoComponent },
  { path: 'avisolegal', component: AvisolegalComponent },
  { path: 'privacidad', component: PoliticaprivacidadComponent },
  { path: 'cookies', component: CookiesComponent },
  { path: 'testruta', component: TestrutaComponent },
  { path: 'alquiler/subirpiso', component: SubirpisoComponent, canActivate : [AuthguardService]  },
  // { path: 'alquiler/piso/:id', component: PisoComponent },
  { path: 'alquiler/pisos', component: PisosComponent, },
  { path: 'alquiler/mispisos', component: MispisosComponent, canActivate : [AuthguardService] },
  { path: 'alquiler/piso/:id', component: PisoComponent, canActivate : [AuthguardService] },
  { path: 'alquiler/editarPiso/:id', component: EditPisoComponent, canActivate : [AuthguardService] },

 { path: 'recuperar-password/:id', component: RecuperarPasswordComponent },
 { path: 'rutas/getmisrutas',  redirectTo: '/rutas', pathMatch: 'full' },
 { path: 'rutas/getrutas',  redirectTo: '/rutas', pathMatch: 'full' },
 { path: 'rutas/delete',  redirectTo: '/rutas', pathMatch: 'full' },
 { path: 'rutas/guardar',  redirectTo: '/rutas', pathMatch: 'full' },


   { path: '**', component: HomeComponent },
  // { path: '**', component: MantenimientoComponent },

];

// export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES);
 export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { useHash: true });




