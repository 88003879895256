<section class="section" id="creaRuta">
    <div class="container">
        <div class="row mt-3 pt-2">
            <div class="col-md-2" style=" background: url('./assets/images/img_rutas_right.jpg') no-repeat 0 -36px;">
            </div>
            <!--col lat izq-->


            <div class="col-md-8 px-lg-4 px-md-4 px-3">
                <h1>Crea tu ruta</h1>

                <form class="login100-form validate-form flex-sb flex-w" (ngSubmit)="onSubmit(registroForm)" #registroForm="ngForm">
                    <div class="container-fluid px-0" *ngIf="this.showFormOk == false">
                      <div class="alert alert-warning mt-4">Antes de crear tu ruta, comprueba que no exista ninguna a la que puedas unirte.</div>

                      <div class="row">
                        <div class="me-4 mt-2 p-0">
                        <div class="alert alert-info mx-3 p-4">* Si no sabes la fecha de finalización, añade una aproximada. Te avisaremos antes de que finalice por si quieres ampliarla.</div>
                      </div>
                        <div class="col-12 col-md-6">
                      <div class="titulo mb-2 my-4 d-flex align-items-center" style="margin-top: 40px">
                        Fecha de inicio
                    </div>

                      <div class="input-group">
                        <input class="form-control" placeholder="Selecciona fecha" required
                               name="dpinicio" [(ngModel)]="fechaInicioPicker" ngbDatepicker #d="ngbDatepicker" (ngModelChange)="selectInicio($event)">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                      </div>
                      <div *ngIf="
                      registroForm.submitted &&
                      !fechaIniSelected
                    " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                                      Selecciona fecha de inicio
                                  </div>
                    </div>
                        <div class="col-12 col-md-6">

                      <div class="titulo mb-2 my-4 d-flex align-items-center" style="margin-top: 40px">
                      Selecciona Fecha de fin *
                    </div>

                      <div class="input-group">
                        <input class="form-control" placeholder="Selecciona fecha" required
                               name="dpfin" [(ngModel)]="fechaFinPicker" ngbDatepicker #f="ngbDatepicker" (ngModelChange)="selectFin($event)">
                        <button class="btn btn-outline-secondary calendar" (click)="f.toggle()" type="button"></button>
                      </div>
                      <div *ngIf="
                      registroForm.submitted &&
                      !fechaFinSelected
                    " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                                      Selecciona fecha de fin
                                  </div>
                    </div>

                    <div class="mt-4 d-flex align-items-center row ms-2  "><img class="p-0 me-2" style="width: 32px" src="../assets/images/alert_1.jpg" />Activa esta casilla si la ruta es solo para fines de semana
                      <label class="switch ms-0 ms-lg-2 mt-2 mt-lg-0">
                        <input type="checkbox" [checked]="ruta.finDeSemana"
                            (change)="change()" />
                        <span class="slider round ms-0"></span>
                    </label>
                  </div>
                    </div>
                    <!-- fin row -->


                        <div class="row">
                            <div class="titulo mb-2 my-4 d-flex align-items-center" style="margin-top: 40px">
                                Selecciona Origen<img class="ms-2" style="width: 30px" src="../assets/images/point-01.svg" />
                            </div>
                            <div class="col-md-6 rowForm">
                                <div class="wrap-input100 mb-3">
                                    <!-- <p><span>Selecciona provincia de origen</span></p> -->
                                    <select name="origenProvincia" class="w-100 paddingOption select-css form-select form-select-lg mb-3" [(ngModel)]="selectOrigen" (change)="selectChangeOrigen()" required>
                    <option class="dropdown-item" disabled selected value="undefined">
                      Provincia de Origen
                    </option>
                    <option [value]="item.id" *ngFor="let item of provincias | sortbyName:'nm'" [ngValue]="item.id">
                      {{ item.nm }}
                    </option>
                  </select>
                                    <div *ngIf="
                      registroForm.submitted &&
                      registroForm.controls['origenProvincia'].errors
                    " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                                        Introduce la provincia de origen
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 rowForm">
                                <!-- <p><span>Selecciona localidad de origen</span></p> -->
                                <select name="origenLocalidad" class="w-100 paddingOption select-css form-select form-select-lg mb-3" [(ngModel)]="origenLocalidad" required [disabled]="!this.selectOrigen">
                  <option class="dropdown-item" disabled selected value="undefined">
                    Localidad de Origen
                  </option>

                  <option [value]="item" *ngFor="let item of localidadesOrigen | sortbyName:'nm'" [ngValue]="item.nm">
                    {{ item.nm }}
                  </option>
                </select>
                                <div *ngIf="
                    registroForm.submitted &&
                    registroForm.controls['origenLocalidad'].errors
                  " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                                    Introduce la localidad de origen
                                </div>
                            </div>
                        </div>

                        <!--------------------------------DESTINO FORM------------------------------->
                        <div class="row mt-4 mt-lg-0">
                            <div class="titulo mb-2 my-0 d-flex align-items-center" style="margin-top: 30px">
                                Selecciona Destino<img class="ms-2" style="width: 30px" src="../assets/images/point-01.svg" />
                            </div>
                            <div class="col-md-6 rowForm">
                                <!-- <p><span>Selecciona provincia de destino</span></p> -->
                                <select name="destinoProvincia" class="w-100 paddingOption select-css form-select form-select-lg mb-3" [(ngModel)]="selectDestino" (change)="selectChange()" required>
                  <option class="dropdown-item" disabled selected value="undefined">
                    Provincia de Destino
                  </option>
                  <option [value]="item.id" *ngFor="let item of provincias | sortbyName:'nm'" [ngValue]="item.id">
                    {{ item.nm }}
                  </option>
                </select>
                                <div *ngIf="
                    registroForm.submitted &&
                    registroForm.controls['destinoProvincia'].errors
                  " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                                    Introduce la provincia de destino
                                </div>
                            </div>

                            <div class="col-md-6 rowForm">
                                <!-- <p><span>Selecciona localidad de destino</span></p> -->
                                <select name="destinoLocalidad" class="w-100 paddingOption select-css form-select form-select-lg mb-3" [(ngModel)]="this.destinoLocalidad" (change)="selectChangeCentro()" required [disabled]="!this.selectDestino">
                  <option class="dropdown-item" disabled selected value="undefined">
                    Localidad de destino
                  </option>
                  <option [value]="item" *ngFor="let item of localidadesDestino | sortbyName:'nm'" [ngValue]="item.nm">
                    {{ item.nm }}
                  </option>
                </select>
                                <div *ngIf="
                    registroForm.submitted &&
                    registroForm.controls['destinoLocalidad'].errors
                  " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                                    Introduce la localidad de destino
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 rowForm">
                                <div class="wrap-input100 mb-3">
                                    <div style="text-align: center; display: block">
                                        <div class="searchbar">
                                            <div class="w-100 d-flex justify-content-start">
                                                <input [disabled]="!this.destinoLocalidad" #inputSearch class="w-100 p-2 centro" type="text" placeholder="Selecciona el nombre del centro *" (keyup)="fetchSeries($event)" [(ngModel)]="destinoCentro" [ngModelOptions]="{ standalone: true }" name="centroname"
                                                     />
                                                <button id="borrafiltro" class="btn btn-close_" type="button" (click)="borraCentro()">
                          <img class="imgButton" src="../assets/images/close.png" />
                        </button>
                                            </div>
                                            <div class="w-100">
                                                <ul class="list-group" [style.width.px]="inputSearch.offsetWidth">
                                                    <li role="button" class="list-group-item" *ngFor="let show of searchResult" (click)="clica(show.nombre)">
                                                        <p class="centros">{{ show.nombre }}</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="mt-2" style="text-align: left; line-height: 16px; font-size: 13px; color:grey">
                                          * Opcional
                                      </div>
                                        <div class="mt-2" style="text-align: left; line-height: 16px; font-size: 13px; color:grey">
                                          Si tu centro no aparece, <a routerLinkActive="active" [routerLink]="['../contacto']">contacta con nosotros</a> para que lo podamos añadir al listado.
                                      </div>

                                        <!-- <div *ngIf="this.centroOk == false && this.submitForm == true" class="alert alert-danger animated fadeIn p-2 mt-3 ps-4">
                                            selecciona un centro
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />
                        <br />

                        <!-----------------------NÚMERO DE PLAZAS------------------>
                        <div class="row">
                            <div class="col-md-3">
                                <p>Número de plazas disponibles</p>
                                <div class="btn-group wrap-input100 mb-3" role="group" aria-label="Basic radio toggle button group">
                                    <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked [(ngModel)]="ruta.plazas" value="1" required />
                                    <label class="btn btn-outline-primary" for="btnradio1">1</label>

                                    <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" [(ngModel)]="ruta.plazas" value="2" />
                                    <label class="btn btn-outline-primary" for="btnradio2">2</label>

                                    <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" [(ngModel)]="ruta.plazas" value="3" />
                                    <label class="btn btn-outline-primary" for="btnradio3">3</label>

                                    <input type="radio" class="btn-check" name="btnradio" id="btnradio4" autocomplete="off" [(ngModel)]="ruta.plazas" value="4" />
                                    <label class="btn btn-outline-primary" for="btnradio4">4</label>
                                </div>
                                <br />
                                <div *ngIf="
                    registroForm.submitted &&
                    registroForm.controls['btnradio'].errors
                  " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                                    selecciona el número de plazas
                                </div>
                            </div>
                            <div class="col-md-4">
                                <p>¿Aceptas a personas sin coche propio?</p>
                                <div class="btn-group wrap-input100 mb-3" role="group" aria-label="Basic radio toggle button group">
                                    <input type="radio" class="btn-check" name="btnradio5" id="btnradio5" autocomplete="off" checked [(ngModel)]="ruta.sinCoche" value="si" required />
                                    <label class="btn btn-outline-primary" for="btnradio5">Sí</label>

                                    <input type="radio" class="btn-check" name="btnradio5" id="btnradio6" autocomplete="off" [(ngModel)]="ruta.sinCoche" value="no" />
                                    <label class="btn btn-outline-primary" for="btnradio6">No</label>
                                </div>
                                <br />
                                <div *ngIf="
                    registroForm.submitted &&
                    registroForm.controls['btnradio5'].errors
                  " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                                    selecciona si admites usuarios sin coche propio
                                </div>
                            </div>

                            <div class="col-md-5">
                                <p *ngIf="!unCentro">
                                    ¿Aceptas solicitudes para añadir paradas a tu ruta?
                                </p>
                                <div *ngIf="!unCentro" class="btn-group wrap-input100 mb-3" role="group" aria-label="Basic radio toggle button group">
                                    <input type="radio" class="btn-check" name="btnradio7" id="btnradio7" autocomplete="off" checked [(ngModel)]="ruta.aceptaParadas" value="si" required />
                                    <label class="btn btn-outline-primary" for="btnradio7">Sí</label>

                                    <input type="radio" class="btn-check" name="btnradio7" id="btnradio8" autocomplete="off" [(ngModel)]="ruta.aceptaParadas" value="no" />
                                    <label class="btn btn-outline-primary" for="btnradio8">No</label>
                                </div>
                                <div *ngIf="
                    registroForm.submitted &&
                    registroForm.controls['btnradio7'].errors
                  " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                                    selecciona si admites solicitudes de parada en tu ruta
                                </div>
                            </div>
                        </div>

                        <br />
                        <hr />
                        <div class="row">
                            <div class="col-md-12 mt-4">
                                <button type="submit" class="btn btn-main buscarRutas">
                  <!-- [attr.data-bs-toggle]="modalToggle" [attr.data-bs-target]="modalTarget" -->
                  Crear ruta
                  <!-- Enviar <img class="ms-2 imgButton" src="../assets/images/ok.png" /> -->
                </button>
                            </div>
                        </div>
                        <div class="row">
                            <!-- <div class="col-md-12 d-flex align-items-end mt-4 ">
        <span class="focus-input100 mx-3"
          >¿Falta tu colegio en el listado? Háznoslo saber a través de
          <a href="#">este formulario</a>
        </span>
      </div> -->
                        </div>
                        <br />

                        <div class="wrap-input100 mb-3" style="
                display: flex;
                align-content: normal;
                align-items: flex-start;
                flex-direction: column;
                justify-content: center;
              ">
                            <!-- <span class="focus-input100 mx-3"
  >¿Falta tu colegio en el listado? Háznoslo saber a través de <a href="#">este formulario</a>
</span> -->
                        </div>
                    </div>
                </form>
                <div *ngIf="this.showFormOk == true">
                    <div class="alert alert-success w-90 mt-4 mb-4" role="alert">
                        Tu ruta se ha creado correctamente. No olvides marcarla como completa cuando se hayan ocupado todas las plazas.<br /><br />

                    </div>
                    Puedes acceder a la sección
                    <a [routerLink]="['../misRutas']">"Mis rutas"</a> para editar o consultar los detalles.
                </div>

            </div>
            <!--col izq-->
            <div class="col-md-2" style="
          background: url('./assets/images/img_rutas_right.jpg') no-repeat -210px -36px;
        "></div>
            <!--col derecha-->
        </div>

                    <!-- Modal -->
                    <div class="fade none" style="background: #ffffffc9" #modalfecha tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div class="modal-dialog">
                          <div class="modal-content">
                              <div class="modal-header border-0 pt-3 pb-1">
                                  <h5 class="modal-title" id="exampleModalLabel"></h5>

                        <button
                       type="button"
                       class="btn-close"
                       data-bs-dismiss="modal"
                       aria-label="Close"
                       (click)="this.closeFecha()"
                     ></button>
                              </div>
                              <div class="modal-body p-4 pb-3">
                                  <div class="d-flex justify-content-center">
                                      <img class="img-fluid" src="./assets/images/alert_1.jpg" alt="" />
                                  </div>
                                  <div class="d-flex justify-content-center mt-4">
                                     <div>La fecha de inicio no puede ser posterior a la de finalización </div>
                                  </div>


                              </div>
                              <div class="modal-footer border-0 d-flex justify-content-center pb-5">
                                  <button type="button" class="btn btn-secondary btn-pop" data-bs-dismiss="modal" (click)=" this.closeFecha()">
                                      Ok
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
                  <!-- fin modal-->
    </div>
</section>
