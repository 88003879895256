import {
  Component,
  OnInit,
  Renderer2,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { RutasService, Ruta } from '../../services/rutas.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from './../../services/user.service';

import { SendMailService } from './../../services/send-mail.service';
import { UniquePipe } from 'src/app/unique.pipe';
import { ViewportScroller, DatePipe } from '@angular/common';

@Component({
  selector: 'app-misrutas',
  templateUrl: './misrutas.component.html',
  styleUrls: ['./misrutas.component.css'],
})
export class MisrutasComponent implements OnInit {
  public rutasSinFiltro: Ruta[] = [];
  public rutasFiltradas = [];
  rutas: any[] = [];
  public misRutas: Ruta[] = [];

  termino: string;
  ruta: any = {};
  user = this.userService.usuarioLogged._id;
  userObj = this.userService.usuarioLogged;
  esElCreador;
  arrayCreadores = [];
  haSolicitadoParada;
  creador;
  paradasSolicitadas;
  rutaBorrar;
  showMessage = false;
  mensajeConfirma;
  mensajePop;
  botonPop;

  misRutasLimpias = [];

  dateInicio: string;

  constructor(
    private rutasService: RutasService,
    private activatedRoute: ActivatedRoute,
    public userService: UserService,
    private router: Router,
    private sendMailService: SendMailService,
    private el: ElementRef,
    private renderer: Renderer2,
    private scroller: ViewportScroller,
    private datePipe: DatePipe
  ) {}

  @ViewChild('exampleModal', { static: false }) divModal: ElementRef;

  getMisRutas() {
    // this.user = this.userService.usuarioLogged._id;
    this.rutasService.getMisRutas(this.user).subscribe((res: any) => {
      this.misRutas = res.misRutas;
      //console.log(this.misRutas);

      for (let i = 0; i < this.misRutas.length; i++) {



        // para usar sin pipe//
        let filteredStops = [
          ...new Set(this.misRutas[i].paradas.map((item) => item)),
        ];

        this.misRutas[i].paradas = filteredStops;
        // console.log('filteredStops');
        // console.log(filteredStops);
        // comentar para usar pipe//

        let creador: any = this.misRutas[i].creador;
        this.creador = this.misRutas[i].creador;

        let paradasSolici: any = this.misRutas[i].paradasSolicitadas;
        // console.log('paradas soli');
        // console.log(this.misRutas[i].paradasSolicitadas);

        for (let e = 0; e < paradasSolici.length; e++) {
          this.paradasSolicitadas = paradasSolici[e];
          //  console.log(paradasSolici[e].creador._id, this.userService.usuarioLogged._id);
          if (
            paradasSolici[e].creador._id === this.userService.usuarioLogged._id
          ) {
            this.haSolicitadoParada = true;
          }
        }

        // this.arrayCreadores.push(creador);
        if (this.user === creador._id) {
          this.esElCreador = true;
        }
      }
    });
  }

  goAlert() {
    this.scroller.scrollToPosition([0, 0]);
  }

  open() {
    this.renderer.removeClass(this.divModal.nativeElement, 'none');
    this.renderer.addClass(this.divModal.nativeElement, 'modal');
  }

  close() {
    this.renderer.removeClass(this.divModal.nativeElement, 'modal');
    this.renderer.addClass(this.divModal.nativeElement, 'none');
  }

  ngOnInit(): void {
    this.getMisRutas();

    //this.getTuRuta();
  }

  // tslint:disable-next-line:typedef
  verRuta(idx: number) {
    this.router.navigate(['/ruta', idx]);
  }

  confirmaBorrar(rutaBorrar) {
    //let index = rutaBorrar.usuarios.indexOf(this.userObj);
    for (let i = 0; i < rutaBorrar.paradas.length; i++) {
      // console.log(rutaBorrar.paradas[i]);
    }

    rutaBorrar.usuarios = rutaBorrar.usuarios.filter(
      (item) => item._id !== this.user
    );
    rutaBorrar.paradas = rutaBorrar.paradas.filter(
      (item) => item.creador._id !== this.user
    );

    /* borrarse cdo has solicitado parada y estas a la espera*/

    if (rutaBorrar.paradasSolicitadas.length > 0) {
      for (let i = 0; i < rutaBorrar.paradasSolicitadas.length; i++) {
        // arrayCreadoresDeParadas.push(rutaBorrar.paradas[i].creador);
        // console.log(
        //   rutaBorrar.paradasSolicitadas[i],
        //   this.userService.usuarioLogged,
        //   rutaBorrar
        // );

        if (
          rutaBorrar.paradasSolicitadas[i].creador.username ===
          this.userService.usuarioLogged.username
        ) {
          rutaBorrar.paradasSolicitadas.splice(i, 1);
        } else {
          rutaBorrar.plazas++;
        }
      }
    } else {
      rutaBorrar.plazas++;
    }
    let tipo = 'borrarme';
    // alert(rutaBorrar.plazas);
    //rutaBorrar.plazas ++;

    this.sendMailService
      .sendMailBorradoDeTuRuta(this.rutaBorrar.creador.email)
      .subscribe(
        (res: any) => {},
        (error) => {
          console.log('error');
        }
      );
    this.rutasService
      .editarRuta(rutaBorrar, tipo, this.rutaBorrar.creador.email)
      .subscribe(
        (res: any) => {
          // rutaBorrar.plazas ++;
          this.showMessage = true;
          this.mensajeConfirma = 'Te has borrado de la ruta';
          this.goAlert();
          this.getMisRutas();
        },
        (error) => {
          console.log('error');
        },
        () => {
          this.rutaBorrar.usuarios.forEach((index) => {
            this.sendMailService.sendMailBorradoDeTuRuta(index.email).subscribe(
              (res: any) => {},
              (error) => {
                console.log('error');
              }
            );
          });
        }
      );

    this.close();

    /* ENVIAR EMAIL AL SOLICITANTE D LA PARADA */
    // this.paradaCreadorEmail = this.paradaCreador.email;
    // this.rutasService
    //   .editarRuta(this.ruta, this.tipo, this.paradaCreadorEmail)
    //   .subscribe((res: any) => {
    //     // console.log(res);
    //   });
  }

  borrarmeDeRuta(ruta) {
    this.rutaBorrar = ruta;
    this.open();
    this.mensajePop = 'Si continúas te borrarás de la ruta';
    this.botonPop = 'borrarme';
  }

  borrarRuta(ruta) {
    this.rutaBorrar = ruta;
    this.open();

    this.mensajePop = 'Vas a eliminar la ruta';
    this.botonPop = 'borrar';
  }

  confirmaEliminar(ruta) {
    this.rutaBorrar = ruta;

    if (this.rutaBorrar.usuarios.length > 0) {
      // envia mail a los usuarios de la ruta //

      this.rutaBorrar.usuarios.forEach((index) => {
        this.sendMailService.sendMailBorraRuta(index.email).subscribe(
          (res: any) => {},
          (error) => {
            console.log('error');
          }
        );
      });
    }
    // fin envia mail a los usuarios de la ruta //
    this.rutasService.eliminarRuta(ruta).subscribe((res: any) => {
      this.showMessage = true;
      this.mensajeConfirma = 'Has eliminado la ruta';
      this.getMisRutas();
      this.close();
    });
  }
}
