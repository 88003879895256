<section class="section" id="misRutas">
    <div class="container ">
        <div class="row mt-3 pt-2">
            <div class="col-md-2" style=" background: url('./assets/images/img_rutas_right.jpg') no-repeat 0 -36px;">
            </div>
            <!--col lat izq-->


            <div class="col-md-8  px-lg-5 px-md-5 px-3">

                <h1>Ruta</h1>

            <!-- modal -->
            <div class="fade none modal-container" #exampleModal tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                  <div class="modal-content p-3">
                      <div class="modal-header pb-0 border-0">
                          <h5 class="modal-title" id="exampleModalLabel"></h5>

                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
                      </div>
                      <div class="modal-body p-4 pb-3">
                        <div class="d-flex justify-content-center">
                            <img class="img-fluid" src="./assets/images/ok_1.jpg" alt="" />
                        </div>
                        <div class="d-flex justify-content-center mt-4">
                            {{ this.mensajePop }}
                        </div>

                    </div>
                    <div class="modal-footer border-0 d-flex justify-content-center pb-5">
                        <button type="button" class="btn btn-secondary btn-pop" data-bs-dismiss="modal" (click)="close()">
                            OK
                        </button>
                    </div>
                  </div>
              </div>
          </div>
          <!------>



                <div class="row mt-5 gx-5 px-4 ps-lg-4 ps-md-4">
                    <div class="card col-md-12 col-lg-7 mb-4 col-sm-12">
                        <div class=" px-4 py-5" style="font-size:110%">

                            <div class="fontSize110">
                                <div class="card-title d-flex align-items-start"><span class="regular">Origen </span> : <span class="bold"> {{ruta.origenLocalidad}}</span></div>
                                <div class="card-title d-flex align-items-center"><span class="regular">Destino </span> : <span class="bold"> {{ruta.destinoLocalidad}}</span></div>
                                <div *ngIf="ruta.destinoCentro" class="card-title bold d-flex align-items-center"> <span>{{ruta.destinoCentro}}</span></div>
                            </div>
                            <hr>
                            <p class="card-text mb-2" class="card-title"><span class="regular">Fecha de inicio</span> : <span class="bold">{{ruta.fechaInicio }}</span></p>
                            <p class="card-text mb-2" class="card-title"><span class="regular">Fecha de fin</span> : <span class="bold">{{ruta.fechaFin}}</span></p>
                            <hr>
                            <div class="card-title" *ngIf="ruta.creador"><span class="regular">Creador</span> :<span class="bold"> {{ruta.creador.username}}</span></div>
                            <p class="card-text mb-2">Plazas disponibles :<span class="bold"> {{ruta.plazas}}</span></p>
                            <p class="card-text mb-2" *ngIf="ruta.usuarios && ruta.usuarios.length > 0">Usuarios</p>

                            <div *ngIf="ruta.creador && ruta.creador._id == userLogged" class="ps-2">
                                <div *ngFor="let user of ruta.usuarios; let i=index">
                                    <div style="font-size:90%"><span class="bold">{{ruta.usuarios[i].username}} - {{user.email}}</span></div>
                                </div>
                            </div>
                            <div *ngIf="ruta.creador && ruta.creador._id != userLogged">
                                <div *ngFor="let user of ruta.usuarios; let i=index">
                                    <div class="bold"><img class="me-2" style="width: 18px" src="../assets/images/user.svg" /><span class="bold">{{ruta.usuarios[i].username}}</span> </div>
                                </div>
                            </div>

                            <p class="card-text mt-2" *ngIf="ruta.aceptaParadas == 'si' && ruta.paradas.length > 0">Centros con los que se comparte trayecto : </p>
                            <div class="card-text mx-2" *ngFor="let user of ruta.paradas; let i=index"> <b>{{ruta.paradas[i].parada}} </b>
                            </div>

                            <!--
                      <p *ngIf="ruta.aceptaParadas == 'si'" class="card-text">Paradas : {{ruta.paradas}}</p> -->


                        </div>
                    </div>
                    <div class=" col-md-12 col-lg-5 col-sm-12 pt-1 ps-0 ps-lg-4 pe-0 ">

                        <!-- <button *ngIf="this.ruta.creador.username != userLogged2.username && ruta.plazas > 0 && !inscritoEnRuta  && haSolicitadoParada != true" type="button" class="btn btn-primary btn-block w-50 h-15 mb-2" (click)="unirmeRuta(); " [class.disabled]="isClickedOnce" (click)="isClickedOnce = true">Unirme a la ruta</button><br> -->


                        <div *ngIf="inscritoEnRuta && !unidoOk" class="alert alert-success mt-0">Ya formas parte de esta ruta</div>
                        <div class="alert alert-success mt-2" role="alert" *ngIf="unidoOk && inscritoEnRuta">
                            ¡Enhorabuena! Te has unido a la ruta
                        </div>
                        <div id="alertSuccess"></div>
                        <div *ngIf="paradaAceptada" class="alert alert-success">Has aceptado la parada</div>
                        <!-- <button *ngIf="paradaAceptada" (click)="back()" class="btn btn-primary btn-block h-15 ">Volver</button> -->
                        <div *ngIf="haSolicitadoParada || showDiv.previous" class="alert alert-success mt-0" id="paradaSolicitada">Parada solicitada. Te llegará un email con la respuesta a tu solicitud</div>

                        <div *ngIf="ruta.creador && ruta.creador._id == userLogged && this.ruta.paradasSolicitadas.length > 0 " role="alert" class="px-0">
                            <div *ngFor="let parada of this.ruta.paradasSolicitadas; let i=index" class="alert alert-warning">
                                <!-- <p>Tienes una solicitud de ruta. ¿Quieres aceptarla?</p> -->
                                <!-- <p>{{ruta.paradasSolicitadas[i].creador.username}} solicita parar en <dt>{{ruta.paradasSolicitadas[i].parada}}</dt></p> -->
                                <p>{{ruta.paradasSolicitadas[i].creador.username}} solicita parar en <dt>{{ruta.paradasSolicitadas[i].parada}}
                  </dt>
                                </p>
                                <!-- <button type="button" (click)="aceptarParada(paradasSolicitadas[i])">Aceptar parada en tu ruta</button> FUNCIONA OK-->
                                <button type="button" (click)="aceptarParada(ruta.paradasSolicitadas[i])" class="btn  btn-block btn-verSolicitud">Aceptar parada</button><br>
                                <button type="button" (click)="rechazarParada(paradasSolicitadas[i])" class="btn  btn-block btn-verSolicitud mt-3">Rechazar</button>
                            </div>
                        </div>
                        <!-- <button *ngIf="haSolicitadoParada || showDiv.previous" (click)="back()" class="btn btn-primary btn-block h-15 segundo ">Volver</button> -->
                        <div class="d-flex flex-column">
                            <button *ngIf="this.ruta.creador && this.ruta.creador.username != userLogged2.username && ruta.plazas > 0 && !inscritoEnRuta  && haSolicitadoParada != true" type="button" class="btn btn-primary btn-block h-15 mb-2" (click)="unirmeRuta(); " [class.disabled]="inscritoEnRuta">Unirme a la ruta</button>
                            <button type="button" (click)="back()" class="btn btn-primary btn-block h-15 mt-2 primero">Volver</button>
                        </div>


                        <!-- <button type="button" class="btn btn-primary btn-block w-50 h-15 mb-2" (click)="addParada(this.values);" [class.disabled]="isClickedOnce" (click)="isClickedOnce = true">Solicitar parada</button><br> -->

                    </div>
                    <br>
                </div>

                <div class="alert alert-danger" *ngIf="submitted === true && changeCentro === false">Selecciona un centro</div>
                <form *ngIf="ruta.creador && ruta.creador._id != userLogged && ruta.plazas > 0 && !inscritoEnRuta && ruta.aceptaParadas == 'si' && haSolicitadoParada != true && !showDiv.previous " class="login100-form validate-form flex-sb flex-w mt-2" (ngSubmit)="onSubmit( registroForm)"
                    #registroForm="ngForm">
                    <div class="alert alert-warning mt-3 mt-lg-0">Si no formas parte del centro de destino, selecciona el tuyo para enviar una solicitud al creador de la ruta. </div>
                    <div class="wrap-input100 mb-3 col-lg-8">
                        <label class="mb-1">Selecciona tu centro</label><br>
                        <select name="centroSeleccionado" id="selectSolicita" [(ngModel)]="paradaSolicitada.parada" class="w-100" (change)="changeCentroFun();">
                          <option
                          class="dropdown-item"
                          disabled
                          selected
                          required
                          value="undefined"

                        >
                          Selecciona centro
                        </option>
            <option [value]="item" *ngFor="let item of centros" [ngValue]="item.nombre">{{item.nombre}}</option>
          </select>
                    </div>

                    <div class="d-flex">
                        <button type="submit" class="btn btn-primary " (click)="  goParadaSolicitada(); submittedFun();">Enviar</button>


                        <!-- <button type="submit" class="btn btn-primary" (click)="open()">Enviar</button> -->
                    </div>

                </form>

            </div>


            <div class="col-md-2" style=" background: url('./assets/images/img_rutas_right.jpg') no-repeat 0 -36px;">
            </div>
            <!--col lat izq-->

        </div>
    </div>
</section>
