<section class="section" id="creaRuta">
  <div class="container">
    <div class="row mt-3 pt-2">
      <div class="col-md-2" style=" background: url('./assets/images/img_rutas_right.jpg') no-repeat 0 -36px;">
      </div>
      <!--col lat izq-->





      <div class="col-md-8 px-lg-4 px-md-4 px-3">
        <h1>Registra tu piso</h1>
        <div class="alert alert-success mt-5" *ngIf="publicado">
          Tu piso se ha subido correctamente.
          <p>Puedes ver los detalles del anuncio en <a href="https://www.docentesenruta.es/#/alquiler/mispisos">"Mis pisos"</a></p>


        </div>
        <form [formGroup]="form" action="/posts" method="POST" enctype="multipart/form-data" #pisoForm="ngForm" *ngIf="publicado === false"   >



          <br><br>

          <div class="row">
            <div class="col-12 alert alert-info">
              <p class="mb-0 mx-1">Por tu privacidad no vamos a mostrar la dirección exacta de la vivienda. Introduce solamente la calle, distrito o zona que quieres que aparezca en el anuncio.</p>
            </div>

          </div>

          <div class="row mb-3 mt-3">

      <div class="col-md-6 d-flex ">
        <div class="btn-group wrap-input100 mb-3" role="group" aria-label="Basic radio toggle button group">
          <input type="radio" class="form-check-input checkBig ms-2" name="tipo" id="tipoEntera" autocomplete="off" checked
            [(ngModel)]="piso.tipo" value="Piso" required (change)="changeType()"/>
          <label class="" for="tipoEntera">Vivienda entera</label>

          <input type="radio" class="form-check-input checkBig ms-5" name="tipo" id="tipoHabitacion" autocomplete="off"
            [(ngModel)]="piso.tipo" value="Habitacion" required (change)="changeType()"/>
          <label class="" for="tipoHabitacion">Habitación</label>

        </div>




         </div>
      <div class="row mx-1">
            <div *ngIf="
            pisoForm.submitted &&
            form.controls['tipo'].invalid
          " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
      Selecciona tipo de alojamiento
    </div>
          </div>
          </div>


          <div class="row">
            <div class="col-md-6 rowForm">

              <select name="provincia" class="w-100 paddingOption select-css form-select form-select-lg mb-3"
                [(ngModel)]="selectOrigen" (change)="selectChangeOrigen()" required formControlName="provincia">
                <option class="dropdown-item" disabled selected value="undefined" [value]="undefined">
                  Provincia
                </option>
                <option [value]="item.id" *ngFor="let item of provincias | sortbyName:'nm'" [ngValue]="item.id">
                  {{ item.nm }}
                </option>
              </select>
              <div *ngIf="
                      pisoForm.submitted &&
                      form.controls['provincia'].invalid
                    " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                Selecciona una provincia
              </div>
            </div>

            <div class="col-md-6 rowForm">

              <select name="localidad" class="w-100 paddingOption select-css form-select form-select-lg mb-3"
                [(ngModel)]="piso.localidad" required formControlName="localidad">
                <option class="dropdown-item" disabled selected value="undefined">
                  Localidad
                </option>

                <option [value]="item" *ngFor="let item of localidadesFiltradas | sortbyName:'nm'" [ngValue]="item.nm">
                  {{ item.nm }}
                </option>
              </select>
              <div *ngIf="
                      pisoForm.submitted &&
                      form.controls['localidad'].invalid
                    " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                Selecciona una localidad
              </div>
            </div>
          </div>
          <!-- NAME -->
          <div class="row">
            <div class="col-md-6 rowForm">

              <div class="wrap-input100 mb-3">

                <input class="input100 form-control shadow-none" type="text" name="pisotitle" placeholder="Calle o zona"
                  [(ngModel)]="piso.titulo" required />
                <div class="focus-input100"></div>
              </div>

              <div *ngIf="
                              pisoForm.submitted &&
                              form.controls['pisotitle'].invalid
                            " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                Introduce la calle o zona
              </div>
            </div>
            <div class="col-md-6 rowForm">

              <div class="wrap-input100 mb-3">
                <span> </span>
                <input class="input100 form-control shadow-none" type="text" name="pisoprecio" placeholder="Precio"
                  [(ngModel)]="piso.precio" required />
                <div class="focus-input100"></div>
              </div>

              <div *ngIf="
                              pisoForm.submitted &&
                              form.controls['pisoprecio'].invalid
                            " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                Introduce el precio
              </div>
            </div>
          </div>


          <div class="row">


            <div class="col-md-6 rowForm" >
              <div class="row">
                <div class="col-md-6" *ngIf="piso.tipo === 'Piso' || piso.tipo === undefined ">

                  <div class="wrap-input100 mb-3">
                    <input class="input100 form-control shadow-none" type="text" name="habitaciones" formControlName="habitaciones"
                      placeholder="Nº de habitaciones" [(ngModel)]="piso.habitaciones" minlength="1" required />

                    <div class="focus-input100"></div>
                  </div>

                  <div *ngIf="
                       pisoForm.submitted &&
                      form.controls['habitaciones'].invalid
                        " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                    Introduce el número de habitaciones
                  </div>
                   <!-- Is Valid {{ form.valid }} -->
                </div>
                <div class="col-md-6">

                  <div class="wrap-input100 mb-3" *ngIf="piso.tipo === 'Piso' || piso.tipo === undefined ">
                    <input class="input100 form-control shadow-none" type="text" name="metros"
                      placeholder="M² de la vivienda" [(ngModel)]="piso.metros" minlength="2" required  />

                    <div class="focus-input100"></div>
                  </div>
                  <div class="wrap-input100 mb-3" *ngIf="piso.tipo === 'Habitacion'">
                    <input class="input100 form-control shadow-none" type="text" name="metros"
                      placeholder="M² de la habitación" [(ngModel)]="piso.metros" minlength="2" required  />

                    <div class="focus-input100"></div>
                  </div>

                  <div *ngIf="
                       pisoForm.submitted && form.controls['metros'].invalid "
                       class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                    Introduce los metros de la vivienda. Mínimo 2 dígitos
                  </div>
                </div>

              </div>

            </div>
            <div class="col-md-6 rowForm">
              <div class="row">
                <div class="col-md-6">


                  <div class="wrap-input100 mb-3">

                    <input class="input100 form-control shadow-none" type="text" name="planta" placeholder="Planta"
                      [(ngModel)]="piso.planta" minlength="1" required />

                    <div class="focus-input100"></div>
                  </div>
                  <div *ngIf="
                                pisoForm.submitted &&
                                form.controls['planta'].invalid
                                " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
                    Introduce la planta
                  </div>
                </div>
                <div class="col-md-6 ">
                  <div class="wrap-input100 mb-3">
                    <label for="ascensor">Ascensor
                    </label
                    >
                    <div
                    class="btn-group wrap-input100"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    <input
                      type="checkbox"
                      class="form-check-input checkBig ms-2"
                      name="ascensor"
                      id="ascensor"
                      autocomplete="off"
                      [(ngModel)]="tieneascensor"
                      (change)="checkascen()"

                    />

                  </div>
                  </div>

                  </div>
              </div>
            </div>

          </div>

          <div class="row">

            <div class="col-md-12 rowForm">

              <textarea class="w-100 p-2 form-control" rows="5" name="desc" placeholder="Cuéntanos algo sobre el piso" [(ngModel)]="piso.desc"></textarea>
              <div *ngIf="
                      pisoForm.submitted &&
                      form.controls['desc'].invalid
                    " class="alert alert-danger animated fadeIn p-2 mt-3 ps-4">
                Introduce una descripción
              </div>
            </div>


          </div>

          <!----------------------- PLANTA------------------>

          <div class="row">


            <div class="col-md-6">
              <p>
                ¿Admites mascotas?
              </p>
              <div class="btn-group wrap-input100 mb-3" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="btn-check" name="mascotas" id="mascotasSi" autocomplete="off" checked
                  [(ngModel)]="piso.mascotas" value=true required />
                <label class="btn btn-outline-primary" for="mascotasSi">Sí</label>

                <input type="radio" class="btn-check" name="mascotas" id="mascotasNo" autocomplete="off"
                  [(ngModel)]="piso.mascotas" value=false required />
                <label class="btn btn-outline-primary" for="mascotasNo">No</label>

                <!-- <input type="radio" class="btn-check" name="mascotas" id="mascotasGato" autocomplete="off"
                  [(ngModel)]="piso.mascotas" value="gatos" required />
                <label class="btn btn-outline-primary" for="mascotasGato">Solo gatos</label>

                <input type="radio" class="btn-check" name="mascotas" id="mascotasPerro" autocomplete="off"
                  [(ngModel)]="piso.mascotas" value="perros" required />
                <label class="btn btn-outline-primary" for="mascotasPerro">Solo perros</label> -->
              </div>
              <div *ngIf="
                            pisoForm.submitted &&
                           form.controls['mascotas'].invalid
                          " class="alert alert-danger animated fadeIn p-2 mt-0 ps-4">
                Selecciona si admites mascotas.
              </div>
            </div>

          </div>


          <!-- <div class="row mt-4">
                      <h5>Sube las fotos de la vivienda</h5>

                      <div *ngFor="let progressInfo of progressInfos" class="mb-2">
                        <span>{{ progressInfo.fileName }}</span>
                        <div class="progress">
                          <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                            attr.aria-valuenow="{{ progressInfo.value }}" aria-valuemin="0" aria-valuemax="100"
                            [ngStyle]="{ width: progressInfo.value + '%' }">
                            {{ progressInfo.value }}%
                          </div>
                        </div>
                      </div>

                      <div class="row mt-3">
                        <div class="col-8">
                          <label class="btn btn-default p-0">
                            <input type="file" accept="image/*" multiple (change)="selectFiles($event)" #fileUploader />
                          </label>
                        </div>


                      </div>

                      <div>
                        <img *ngFor='let preview of previews; let i = index' [src]="preview" class="preview"
                          (click)="removeItem(i)">
                      </div>

                      <div *ngIf="message.length" class="alert alert-secondary my-3" role="alert">
                        <ul *ngFor="let msg of message; let i = index">
                          <li>{{ msg }}</li>
                        </ul>
                      </div>
                    </div> -->

          <div class="row mt-4">
            <h5 class="mb-4">Sube las fotos de la vivienda. - <i>Máximo 10 imágenes </i>-</h5>


            <div *ngFor="let progressInfo of progressInfos;  let i = index" class="mb-2 barraProgreso "
              [ngClass]="{'d-none' : finish}">
              <span>{{ progressInfo.fileName }}</span>
              <div class="progress">
                <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                  attr.aria-valuenow="{{ progressInfo.value }}" aria-valuemin="0" aria-valuemax="100"
                  [ngStyle]="{ width: progressInfo.value + '%' }">
                  {{ progressInfo.value }}%
                </div>
              </div>
            </div>

            <div class="container" appDnd (fileDropped)="onFileDropped($event); selectToPreview($event)">
              <input type="file" #fileDropRef id="fileDropRef" multiple name="fotos"
                (change)="fileBrowseHandler($event.target.files) ; selectToPreview($event)" />
              <!-- <svg xmlns="http://www.w3.org/2000/svg" width="63" height="64" viewBox="0 0 63 64">
                          <g fill="#3B454F" fill-rule="nonzero">
                            <path
                              d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z" />
                            <path
                              d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z" />
                            <path
                              d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z" />
                          </g>
                        </svg> -->

              <!-- <h3>Drag and drop file here</h3> -->
              <!-- <h3>or</h3> -->
              <label class="labelLoadImages" for="fileDropRef">Seleciona las fotos</label>

                <div *ngIf="
                pisoForm.submitted &&
                noFotos
              " class="alert alert-danger animated fadeIn p-2 mt-2 ps-4 mt-3 col-md-6">
          Selecciona fotos
        </div>

            </div>
          </div>


          <div class="row mt-5">


            <div class="files-list">

              <div class="single-file" *ngFor="let file of files; let i = index">
                <div class="file-icon">
                  <img [src]="previews[i]" class="preview">
                  <!-- <img [src]="imgURL" height="200" *ngIf="imgURL"> -->
                  <!-- <img [src]="imgURL" height="200" *ngFor="let img of urls; let i = index"> -->

                                    <!-- <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px" y="0px" viewBox="0 0 58 58" style="enable-background:new 0 0 58 58;" xml:space="preserve">
                          <polygon style="fill:#EDEADA;" points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
                          <g>
                            <path style="fill:#CEC9AE;"
                              d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z" />
                            <path style="fill:#CEC9AE;"
                              d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z" />
                            <path style="fill:#CEC9AE;"
                              d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z" />
                            <path style="fill:#CEC9AE;"
                              d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z" />
                            <path style="fill:#CEC9AE;"
                              d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z" />
                          </g>
                          <polygon style="fill:#CEC9AE;" points="37.5,0 37.5,14 51.5,14 " />
                        </svg> -->
                  <div class="delete" (click)="deleteFile(i)">
                    <svg id="uuid-9b567490-0ca6-4daa-948d-f57780f58661" data-name="Capa 1"
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.19 28.19">
                      <path
                        d="M26.41,28.19c-.45,0-.91-.17-1.25-.52L.52,3.03C-.17,2.33-.17,1.21,.52,.52,1.21-.17,2.34-.17,3.03,.52L27.67,25.16c.69,.69,.69,1.81,0,2.51-.35,.35-.8,.52-1.25,.52Z"
                        style="fill: #ffffff;" />
                      <path
                        d="M1.77,28.19c-.45,0-.91-.17-1.25-.52-.69-.69-.69-1.81,0-2.51L25.16,.52c.69-.69,1.81-.69,2.51,0,.69,.69,.69,1.82,0,2.51L3.03,27.67c-.35,.35-.8,.52-1.25,.52Z"
                        style="fill: #ffffff;" />
                    </svg>
                                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="14" height="28" viewBox="0 0 14 18">
                        <path fill="#dd4747" fill-rule="nonzero"
                          d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z" />
                      </svg> -->
                  </div>
                </div>
                                            <!-- <div class="info">
                                  <h4 class="name">
                                    {{ file?.name }}
                                  </h4>
                                  <p class="size">
                                    {{ formatBytes(file?.size) }}
                                  </p>
                                  <app-progress [progress]="file?.progress"></app-progress>
                                </div> -->


              </div>
            </div>



          </div>
          <div class="row mt-3">
            <div class="col-4">


              <!-- <button type="submit" class="btn btn-primary btn-block my-2 mb-4" [disabled]="!selectedFiles">
  Publicar
</button> -->
              <!-- <button type="submit" class="btn btn-primary btn-block my-2 mb-4"  (click)="uploadFiles()"> -->
              <button type="submit" class="btn btn-primary btn-block my-2 mb-4" (click)="uploadFiles()" >
                <!-- <button type="submit" class="btn btn-primary btn-block my-2 mb-4" [disabled]="!selectedFiles" (click)="uploadFiles()"> -->
                Publicar
              </button>
            </div>

          </div>
          <div class="row mt-0">
            <div class="col-6">
          <div *ngIf="
          pisoForm.submitted && form.invalid
        " class="alert alert-danger animated fadeIn p-2 mt-0 ps-4">
Por favor, revisa los datos introducidos
</div></div></div>
        </form>
        <!-- <div *ngIf="this.showFormOk == true">
                  <div class="alert alert-success w-90 mt-4 mb-4" role="alert">
                      Tu ruta se ha creado correctamente. No olvides marcarla como completa cuando se hayan ocupado todas las plazas.<br /><br />

                  </div>
                  Puedes acceder a la sección
                  <a [routerLink]="['../misRutas']">"Mis rutas"</a> para editar o consultar los detalles.
              </div> -->

      </div>

      <!--col izq-->
      <div class="col-md-2" style="
        background: url('./assets/images/img_rutas_right.jpg') no-repeat -210px -36px;
      "></div>
      <!--col derecha-->
    </div>


  </div>
</section>
