
<div class="col" >
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Origen : {{ruta.origen}}</h5>
      <p class="card-text">Destino : {{ruta.destino}}</p>
      <p class="card-text">Paradas : {{ruta.paradas}}</p>
      <p class="card-text">creador : {{ruta.creador}}</p>
      <p class="card-text">hfgfg : {{ruta._id}}</p>
      <p class="card-text">Plazas disponibles : {{ruta.plazas}}</p>

      <button (click)="verRuta()" type="button" class="btn-outline-primary">Ver mas
      </button>

<!--
     <a [routerLink]="['/ruta',i]" class="btn btn-primary">ver mas</a> -->
    </div>
  </div>
</div>
