<section class="section" id="miPerfil">
  <div class="container">
    <div class="row mt-3 pt-2">
      <div class="col-md-2" style=" background: url('./assets/images/img_rutas_right.jpg') no-repeat 0 -36px;">
      </div>

      <div class="col-md-8 px-lg-4 px-md-4 px-3">

        <h1>Mi cuenta</h1>
        <form class="login100-form validate-form flex-sb flex-w" (ngSubmit)="onSubmit(registroForm)"
          #registroForm="ngForm">


          <div class="form-group">

            <!-- <input type="text" class="form-control" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,3}$" id="email"name="email" ngModel #emailref="ngModel"> -->
            <!-- <div *ngIf="emailref.errors &&(emailref.touched || emailref.dirty)" class ="alert alert-danger">
               <div [hidden]="!emailref.errors?.pattern">
                 Invalid pattern
               </div>
              </div> -->
          </div>
          <div class="container-fluid px-0">
            <h5 class="mt-3 mb-3">Tus datos</h5>
          </div>
          <div>Nombre de usuari@<br>
            <div class="bold mt-1">{{user.username}}</div>
          </div>
          <div class="mt-2">Email<br></div>
          <div class="bold mt-1">{{user.email}}
            <button type="button" *ngIf="!editEmail" class="btnEdit px-3 ms-0 ms-md-3 ms-lg-3" (click)="editEmailFun()">
              Editar
            </button>
          </div>

            <!-- <div *ngIf="prmEmail.errors?.email">
  Primary email address is not valid. </div>
  <input name="primaryEmail" [ngModel]="user.email" [email]="validateEmail" required #prmEmail="ngModel" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,3}$" class="form-control"/> -->
            <!-- <input type="text" class="form-control" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,3}$" id="email" name="email" ngModel #emailref="ngModel">
              <div *ngIf="emailref.errors &&(emailref.touched || emailref.dirty)" class ="alert alert-danger">
               <div [hidden]="!emailref.errors?.pattern">
                 Invalid pattern
               </div>
              </div> -->
            <!-- <div class="wrap-input100 mb-3">
              <input
                class="input100 form-control shadow-none"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                type="text"
                name="email"
                placeholder="{{this.user.email}}"
                [(ngModel)]="emailTemporal"
                required
                email="true"
                minlength="9"
                (keyup)="onKeyMail($event)"
                ngModel
                #emailref="ngModel"

              />

              <span class="focus-input100"></span>
            </div> -->



<!-- EMAIL -->
          <div *ngIf="editEmail === true" class="alert alert-warning mt-3 px-4">
            <div class="mt-2">Introduce el nuevo email</div>
            <div class="alert alert-danger animated fadeIn p-2 mt-2 ps-4"
              *ngIf="emailref.errors &&(emailref.touched || emailref.dirty) && registroForm.submitted">
              <div [hidden]="!emailref.errors?.pattern">
                Introduce un email válido y sin mayúsculas
              </div>
            </div>

            <div *ngIf="existeEmail === true" class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
              Este email ya está registrado
            </div>

            <div class="wrap-input100 mb-3">
              <input class="input100 form-control shadow-none" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$"
                type="text" name="email" placeholder="{{this.user.email}}"  [(ngModel)]="emailTemporal" (ngModelChange)="value = $event.toLowerCase()" required
                email="true" minlength="9" (keyup)="onKeyMail($event)" ngModel #emailref="ngModel" />
              <span class="focus-input100"></span>
            </div>
            <button type="submit" class="btn btn-primary btn-block mt-2 btn-pop btn-edit">
              Confirmar
            </button>
            <a class="btn btn-primary btn-block mt-2 btn-pop btn-edit ms-3 ms-lg-3 ms-md-3" (click)="cancelEmail()">
              Cancelar
            </a>
          </div>
<!-- FIN EMAIL -->


<div class="mt-2">Teléfono<br></div>
          <div class="bold mt-1">{{user.tfno}}
            <button *ngIf="user.tfno !== null && editTfno === false" class="btnEdit px-3 ms-0 ms-md-3 ms-lg-3" (click)="editTfnoFun()">
              Editar
            </button>

            <button *ngIf="user.tfno === null && editTfno === false" class="btnEdit px-3 ms-0" (click)="editTfnoFun()">
              Añadir teléfono
            </button>
  <!-- TFNO -->
          <div *ngIf="editTfno === true" class="alert alert-warning mt-3 px-4">
            <div class="mt-2">Introduce el nuevo teléfono</div>
            <div class="alert alert-danger animated fadeIn p-2 mt-2 ps-4"
              *ngIf="tfnoref.errors &&(tfnoref.touched || tfnoref.dirty) && registroForm.submitted">
              <div [hidden]="!tfnoref.errors?.pattern">
                Introduce un teléfono válido y sin espacios.
              </div>
            </div>

            <div *ngIf="existeTfno === true && !tfnoref.errors" class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
              Este teléfono ya está registrado
            </div>

            <div class="wrap-input100 mb-3">
              <input
              class="input100 form-control shadow-none"
              pattern="[0-9]{9}$"
                type="text"
                name="tfno"
                placeholder="{{this.user.tfno}}"
                #tfno
                [(ngModel)]="tfnoTemporal"
                required
                 minlength="9"
                 ngModel
                 #tfnoref="ngModel"
                 (keyup)="onKeyTfno($event)"

                 />


              <span class="focus-input100"></span>
            </div>
            <button type="submit" class="btn btn-primary btn-block mt-2 btn-pop btn-edit" (click)=" tfnoEditadoFun();" >
              Confirmar
            </button>
            <a class="btn btn-primary btn-block mt-2 btn-pop btn-edit ms-3 ms-lg-3 ms-md-3" (click)="cancelTfno()">
              Cancelar
            </a>
          </div>
<!-- FIN EMAIL -->

            <!-- <div *ngIf="tfnoValidate === false" class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
              Introduce un teléfono correcto sin espacios
            </div>
            <div *ngIf=" existeTfno === true && tfnoValidate !== false"
              class="alert alert-danger animated fadeIn p-2 mt-2 ps-4">
              Ese número de teléfono ya está registrado
            </div> -->
            <!-- <div class="wrap-input100 mb-3">
              <input class="input100 form-control shadow-none" pattern="[0-9]{9}$" type="text" name="tfno" #tfno
                placeholder="{{this.user.tfno}}" [(ngModel)]="tfnoTemporal" />
              <span class="focus-input100"></span>
            </div> -->
          </div>
          <!-- <button type="button" class="btnEdit px-3 ms-0 mt-3 ">
            Editar datos
          </button> -->






        </form>











      </div>

      <div class="col-md-2" style=" background: url('./assets/images/img_rutas_right.jpg') no-repeat -200px -36px;">
      </div>

    </div>
  </div>



</section>
