
export class PisoModel {

  titulo: string;
  localidad: string;
  precio: string;
  habitaciones: string;
  metros: string;
  creador: object;
  ascensor: boolean;
  mascotas: boolean;
  planta: string;
  tipo: string;
  imgs: string[];
  desc: string;
  pausado: boolean;


}

