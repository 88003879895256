import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ParadasService {

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) { }

  updateMails(email1, email2) {

      //const params = user;
      const url = 'https://docentesenruta.eu-4.evennode.com/paradas/updateMail/' + email1 + '/' + email2;
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      return this.httpClient.put(url, { headers: headers });
  }





}
