import { UsuarioModel } from './../../models/user.model';
import { RutasService } from './../../services/rutas.service';
import { ParadasService } from './../../services/paradas.service';
import { UserService } from 'src/app/services/user.service';
import { NgForm, FormControl, FormControlDirective, FormControlName } from '@angular/forms';
import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';




@Component({
  selector: 'app-micuenta',
  templateUrl: './micuenta.component.html',
  styleUrls: ['./micuenta.component.css']
})
export class MicuentaComponent implements OnInit {

  user: UsuarioModel;
  emailOriginal;
  usuarios;
  emailTemporal;
  tfnoTemporal;
  existeEmail = false;
  existeTfno = false;
  tfnoValidate;
  validateEmail = true;
  editEmail = false;
  editTfno = false;
  tfnoEditado = false;
  constructor(
    private userService: UserService,
    private rutasService: RutasService,
    private paradasService: ParadasService,
    myElement: ElementRef,
    private renderer: Renderer2

  ) { }

  // tslint:disable-next-line:typedef
  getUsers() {
    this.userService.getUsers().subscribe((res: any) => {
      this.usuarios = res.users;
    });
  }

  first(){
    try {
  const errorField = this.renderer.selectRootElement('.first-class');
    errorField.scrollIntoView();
} catch (err) {

}
}
  gotoTop() {
    // let el = this.myElement.nativeElement.ownerDocument.getElementById('container').scrollIntoView({behavior: 'smooth'});
    // el.scrollIntoView();
    document.getElementById('container').scrollIntoView({ behavior: 'smooth' });
  }
  // tslint:disable-next-line:typedef
  checkMailLive(userMail) {
    for (let i = 0; i < this.usuarios.length; i++) {
      let email = this.usuarios[i].email;
      if (email !== userMail) {
        this.existeEmail = false;
      }
    }
  }
  // tslint:disable-next-line:typedef
  onKeyMail(event: any) {
    this.checkMailLive(event.target.value);
  }
  // tslint:disable-next-line:typedef
  cancelEmail() { this.editEmail = false; }

  // tslint:disable-next-line:typedef
  editEmailFun() {

    // this.tfnoEditado = false;

    if (this.editEmail === true) {
      this.editEmail = false;
    } else {
      this.editEmail = true;
    }
    if (this.editTfno === true) {
      this.editTfno = false;

    }

  }

  // TELEFONO-------------------------------//
  // tslint:disable-next-line:typedef
  clickme(value) {
    if (value !== '') {
      if (/\d{9}/.test(value)) {
        this.tfnoValidate = true;
      } else {
        this.tfnoValidate = false;
        return false;
      }
    } else {
    }
  }

  editTfnoFun() {

    if (this.editTfno === true) {
      this.editTfno = false;

    } else {
      this.editTfno = true;

    }
    if (this.editEmail === true) {
      this.editEmail = false;
    }
  }


  // tslint:disable-next-line:typedef
  cancelTfno() { this.editTfno = false; }

    // tslint:disable-next-line:typedef
  onKeyTfno(event: any) {
    this.checkTfnoLive(event.target.value);
  }

  // tslint:disable-next-line:typedef
  checkTfnoLive(userTfno) {
    for (let i = 0; i < this.usuarios.length; i++) {
      let tfno = this.usuarios[i].tfno;
      if (tfno !== userTfno) {
        this.existeTfno = false;
      }
    }
  }

  // tslint:disable-next-line:typedef
  tfnoEditadoFun() {
    this.tfnoEditado = true;
  }


  // FIN TELEFONO-------------------------------//

  // tslint:disable-next-line:typedef
  checkValues() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.usuarios.length; i++) {
      // let usuario = this.usuarios[i].username;

      let email = this.usuarios[i].email.toLowerCase();
      let tfno = this.usuarios[i].tfno;



      /* check username*/
      // if (usuario.indexOf(this.user.username) >= 0) {
      //   this.existeUsuario = true;
      // }

      /* check email*/
      if (email.indexOf(this.emailTemporal) >= 0) {
        this.existeEmail = true;

      }

      /* check tfno*/
      if (tfno !== null && tfno.indexOf(this.tfnoTemporal) >= 0) {
        this.existeTfno = true;

      }
    }
    /**************************/
    // });
  }


  ngOnInit(): void {
    this.user = new UsuarioModel();
    this.user = this.userService.usuarioLogged;
    this.emailOriginal = this.user.email.toLowerCase();
    this.getUsers();
  }

  scrollToTop(el) {

    el.scrollTop = 0;

  }
  onSubmit(form: NgForm) {

    this.checkValues();
    if (form.invalid || this.existeEmail === true || this.existeTfno === true) {

      return;
    } else {


      if (this.editEmail === true) {

        this.emailTemporal = this.emailTemporal.toLowerCase();
        this.user.email = this.emailTemporal.toLowerCase();


        // Get back item "kittens" from local storage
          var userLocal = JSON.parse(localStorage.getItem("usuario"));

          // Change value
          userLocal.email = this.emailTemporal;

          // Save the new item with updated value
          localStorage.setItem("usuario",JSON.stringify(userLocal));

        this.userService.editUser(this.user).subscribe((res: any) => {
          if (res.success === true) {
            this.editEmail = false;
            this.editTfno = false;
            form.resetForm();
            this.getUsers();

          }
        });
        this.rutasService
          .updateMails(this.emailOriginal, this.emailTemporal)
          .subscribe(
            (res: any) => {
              if (res.success === true) {


              }
            },
            (error) => {},
            () => {   }
          );
        this.paradasService
          .updateMails(this.emailOriginal, this.emailTemporal)
          .subscribe(
            (res: any) => {
              if (res.success === true) {
                this.editEmail = false;
                this.editTfno = false;
                form.resetForm();
              }
            },
            (error) => {},
            () => {   }
          );
        this.rutasService
          .updateMailsParadaSoli(this.emailOriginal, this.emailTemporal)
          .subscribe(
            (res: any) => {
              if (res.success === true) {
                this.editEmail = false;
                this.editTfno = false;
                form.resetForm();
              }
            },
            (error) => {},
            () => {   }
          );
        this.rutasService
          .updateMailsUsuarios(this.emailOriginal, this.emailTemporal)
          .subscribe(
            (res: any) => {
              if (res.success === true) {
                this.editEmail = false;
                this.editTfno = false;
                form.resetForm();
              }
            },
            (error) => {},
            () => {   }
          );



      }

      if (this.editTfno === true) {
        this.user.tfno = this.tfnoTemporal;
      }




      // this.userService.editUser(this.user).subscribe((res: any) => {
      //   //console.log(this.user);
      //   if (res.success === true) {

      //     this.editEmail = false;
      //     this.editTfno = false;

      //     if (this.tfnoEditado === false) {

      //       this.emailTemporal = '';
      //       this.getUsers();


            // this.rutasService.updateMails(this.emailOriginal, this.emailTemporal).subscribe(
            //   (res: any) => {

            //   if (res.success === true) {

            //   }
            // },
            // (error) => {

            // },
            // () => {


            // }


            // );

      //       this.paradasService.updateMails(this.emailOriginal, this.emailTemporal).subscribe((res: any) => {
      //         if (res.success === true) {
      //           form.resetForm();
      //         }
      //       });

      //     } else {

      //       form.resetForm();
      //       // alert('tfno editado true, no edita mails')

      //     }

      //   } else {



      //   }
      // });










    }

  }









}
