<footer class="footer text-center mt-5 mt-xl-5">
    <div class="container mt-lg-0 mt-5">

        <div class="row text-center my-2 my-lg-3">


            <!-- Footer Social Icons-->
            <div class="col-sm mb-3 mb-lg-0 d-flex justify-content-center align-items-center">

                <a class="btn  btn-social mx-1 shadow-none" href="https://www.instagram.com/docentesenruta/"> <img class="img-fluid" src="./assets/images/instagram.png" alt="" width="30"></a>
                <a class="btn  btn-social mx-1 shadow-none"  href="https://www.facebook.com/DocentesenRuta/"><img class="img-fluid" src="./assets/images/facebook.png" alt="" width="30"></a>
                <a class="btn  btn-social mx-1 shadow-none" href="https://t.me/docentesenruta"><img class="img-fluid" src="./assets/images/telegram.jpg" alt="" width="30"></a>

            </div>

        </div>
        <div class="row text-center rowPoliticas d-flex justify-content-center mb-3">


            <div class="row gx-sm-0 ">
                <div class="col-sm">
                    <a class="btn  btn-social mx-1 shadow-none col-sm-12 text-right" href="#!" [routerLink]="['avisolegal']">Aviso legal</a>
                </div>
                <div class="col-sm">
                    <a class="btn  btn-social mx-1 shadow-none" href="#!" [routerLink]="['cookies']">Política de cookies</a>
                </div>
                <div class="col-sm">
                    <a class="btn  btn-social mx-1 shadow-none" href="#!" [routerLink]="['privacidad']">Política de privacidad</a>
                </div>
            </div>




        </div>
    </div>
</footer>
<!-- Copyright Section-->
<div class="copyright py-2 text-center text-white">
    <div class="container">

        <small>Copyright &copy; Docentes en ruta 2022</small>


    </div>
</div>
