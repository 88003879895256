<section class="section" id="miPerfil">

  <div class="container">
    <div class="row mt-3 pt-2">
      <div class="col-md-2" style=" background: url('./assets/images/img_rutas_right.jpg') no-repeat 0 -36px;">
      </div>
      <!--col lat izq-->

      <div class="col-md-8 px-lg-4 px-md-4 px-3">

        <h1>Mi cuenta</h1>


      <div class="container-fluid px-0">
        <h5 class="mt-3 mb-3">Tus datos</h5>
        </div>
        <div>Nombre de usuari@<br>
          <div class="bold mt-1">{{user.username}}</div>
        </div>
        <div class="mt-2">Email<br></div>
        <div *ngIf="!editEmail" class="bold mt-1">{{user.email}}

        </div>
        <div *ngIf="!editTfno" class="bold mt-1">{{user.tfno}}

        </div>
        <button type="button" *ngIf="!editTfno" class="btnEdit px-3 ms-0 mt-3 " (click)="editEmailFun()">
          Editar datos
        </button>







      </div>

      <div class="col-md-2" style=" background: url('./assets/images/img_rutas_right.jpg') no-repeat -200px -36px;">
      </div>
      <!--col lat izq-->
    </div>
  </div>

  </section>
