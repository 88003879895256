<section class="section" id="misRutas">
    <div class="container ">
        <div class="row mt-3 pt-2">
            <div class="col-md-2" style=" background: url('./assets/images/img_rutas_right.jpg') no-repeat 0 -36px;">


            </div>
            <!--col lat izq-->
            <div class="col-md-8  px-lg-5 px-md-5 px-3">
                <h1>Mis rutas</h1>

                <!-- modal -->
                <div class="fade none" #exampleModal tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content p-3">
                            <div class="modal-header pb-0">
                                <h5 class="modal-title" id="exampleModalLabel"></h5>

                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
                            </div>
                            <div class="modal-body">
                              <div class="d-flex justify-content-center">
                                <img class="img-fluid" src="./assets/images/alert_1.jpg" alt="" />
                            </div>
                                <p class="text-center mt-4 alert-danger alert">{{mensajePop}}</p>

                                <!-- <button class="btn btn-primary" (click)='confirmaBorrar(rutaBorrar);'>Continuar</button> -->

                            </div>
                            <div class="modal-footer pt-0 justify-content-center">
                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)='confirmaBorrar(rutaBorrar); ' *ngIf="botonPop === 'borrarme'">CONTINUAR</button>
                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)='confirmaEliminar(rutaBorrar); ' *ngIf="botonPop === 'borrar'">CONTINUAR</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!------>

                <div *ngIf="showMessage" class="alert alert-success mt-4 mb-0">{{mensajeConfirma}}</div>
                <div class="row row-cols-1 row-cols-md-1 row-cols-sm-1 g-4 pt-3" data-masonry='{"percentPosition": true }' [ngClass]="{'row-cols-lg-1': misRutas.length < 2,'row-cols-lg-2': misRutas.length > 1 }">

                    <!-- *ngFor="let ruta of rutas; let i=index" -->
                    <!-- <app-ruta-tarjeta (rutaSeleccionada)="verRuta($event)" [ruta]="ruta" [index]="i" *ngFor="let ruta of rutas; let i=index"></app-ruta-tarjeta> -->

                    <div class="col pt-4" *ngFor="let ruta of misRutas; let i=index">
                        <div class="card">
                            <div class="card-body">

                                <div *ngIf="this.misRutas[i].creador._id === this.user">

                                    <div *ngIf="this.misRutas[i].creador._id == this.user" class="alert alert-info">Esta ruta la has creado tú</div>
                                    <h6 class="card-title alert alert-warning" *ngIf="ruta.finDeSemana"><span class="regular">Esta ruta no es diaria, es solo para fines de semana</span></h6>
                                    <h5 *ngIf="this.misRutas[i].creador._id != this.user" class="card-title"><span class="regular">Creador</span> : {{ruta.creador.username}}</h5>
                                    <h5 *ngIf="this.misRutas[i].creador._id != this.user" class="card-title"><span class="regular">Contacto del creador</span></h5>
                                    <p *ngIf="this.misRutas[i].creador._id != this.user" class="card-text"> {{ruta.creador.email}}</p>
                                    <p class="card-text" *ngIf="this.misRutas[i].creador._id !== this.user"> {{ruta.creador.tfno}}</p>
                                    <h6 class="card-title"><span class="regular">Fecha de inicio</span> : {{ruta.fechaInicio}}</h6>
                                    <h6 class="card-title"><span class="regular">Fecha de fin</span> : {{ruta.fechaFin}}</h6>
                                    <h6 class="card-title"><span class="regular">Origen</span> : {{ruta.origenLocalidad}}</h6>
                                    <h6 class="card-title" *ngIf="ruta.destinoCentro"><span class="regular">Destino</span> : {{ruta.destinoCentro}}
                                    </h6>
                                    <h6 class="card-title" *ngIf="ruta.destinoLocalidad && !ruta.destinoCentro"><span class="regular">Destino</span> : {{ruta.destinoLocalidad}}</h6>


                                    <hr>
                                    <p class="card-text mb-0">Plazas disponibles : {{ruta.plazas}}</p>
                                    <p class="card-text mb-2 mt-2" *ngIf="ruta.aceptaParadas == 'si' && ruta.paradas.length > 0">Centros con los que se comparte el trayecto :
                                    </p>
                                    <!-- <div class="card-text mx-2" *ngFor="let user of ruta.paradas | unique; let i=index">
                                    <b>{{ruta.paradas[i].parada}} </b> </div> -->

                                    <div class="card-text mx-2 paradas" *ngFor="let item of ruta.paradas | unique; let i=index">
                                        <b>{{item.parada}}</b>
                                    </div>

                                    <!-- con pipe modificando el TS-->
                                    <!-- <div class="card-text mx-2 paradas" *ngFor="let item of ruta.paradas | unique">
                                       // items
                                        <b>{{item.parada}}</b>
                                    </div> -->


                                    <p class="card-text mb-2 mt-2" *ngIf="ruta.usuarios.length > 0">Usuarios : </p>
                                    <!-- <div class="card-text mx-2" *ngFor="let user of ruta.usuarios; let i=index"> <b>{{ruta.usuarios[i].username}} </b> -</div> -->

                                    <div *ngFor="let user of ruta.usuarios; let i=index">

                                        <div class="ps-2"><img class="me-2" style="width: 18px" src="../assets/images/user.svg" />{{ruta.usuarios[i].username}} <span *ngIf="user.email">-</span> {{user.email}}</div>

                                    </div>
                                    <!-- <div *ngIf="this.misRutas[i].creador._id == this.user">{{ruta.usuarios[i].email}}</div> -->
                                    <!-- <p class="card-text">Creada por : {{ruta.creador}}</p> -->

                                    <br />
                                    <!-- <button [routerLink]="['/ruta',ruta._id]" class="btn btn-primary btn-block">Ver Detalle</button> -->
                                    <button *ngIf="this.misRutas[i].creador._id == this.user" [routerLink]="['/editarRuta',ruta._id]" class="btn  btn-block btn-editar btn-misrutas  ">Editar</button>
                                    <button *ngIf="this.misRutas[i].creador._id == this.user" (click)='borrarRuta(ruta);' class="btn  btn-block ms-3 btn-editar btn-misrutas">Borrar</button>




                                </div>

                                <div *ngIf="this.misRutas[i].creador._id !== this.user">

                                    <div class="col alert alert-success mb-4 mb-lg-3 mb-md-3" *ngFor="let parada of misRutas[i].paradasSolicitadas; let i=index">Has solicitado una parada en esta ruta. Estamos a la espera de que el creador decida si la acepta.</div>
                                    <h6 class="card-title alert alert-warning" *ngIf="ruta.finDeSemana"><span class="regular">Esta ruta no es diaria, es solo para viernes y domingo</span></h6>
                                    <div class="card-title"><span class="regular">Fecha de Inicio</span> : {{ruta.fechaInicio}}</div>
                                    <h6 class="card-title"><span class="regular">Fecha de Fin</span> : {{ruta.fechaFin}}</h6>
                                    <h6 class="card-title"><span class="regular">Origen</span> : {{ruta.origenLocalidad}}</h6>
                                    <h6 class="card-title" *ngIf="ruta.destinoCentro"><span class="regular">Destino</span> : {{ruta.destinoCentro}}
                                    </h6>
                                    <h6 class="card-title" *ngIf="ruta.destinoLocalidad && !ruta.destinoCentro"><span class="regular">Destino</span> : {{ruta.destinoLocalidad}}</h6>
                                    <hr>

                                    <div class="card-title"><span class="regular">Creador</span> : {{ruta.creador.username}}</div>
                                    <div *ngIf="misRutas[i].paradasSolicitadas.length === 0">
                                    <div class="card-title"><span class="regular">Contacto del creador</span></div>
                                    <p class="card-text"> {{ruta.creador.email}}</p>
                                    <p class="card-text"> {{ruta.creador.tfno}}</p>
                                    </div>
                                    <hr>
                                    <p class="card-text">Plazas disponibles : {{ruta.plazas}}</p>
                                    <!-- <p *ngIf="ruta.aceptaParadas == true && ruta.paradas.length > 0">Paradas : {{ruta.paradas.parada}}</p> -->
                                    <p class="card-text" *ngIf="ruta.aceptaParadas == 'si' && ruta.paradas.length > 0">Centros con los que se comparte el trayecto : </p>
                                    <div class="card-text mx-2 paradas" *ngFor="let item of ruta.paradas | unique">
                                        <b>{{item.parada}} </b> </div>

                                    <p class="card-text mt-3" *ngIf="ruta.usuarios.length > 0">Usuarios : </p>
                                    <div class="card-text mx-2" *ngFor="let user of ruta.usuarios; let i=index">
                                        <div><img class="me-2" style="width: 18px" src="../assets/images/user.svg" />{{ruta.usuarios[i].username}} </div>
                                    </div>
                                    <br>
                                    <button *ngIf="this.misRutas[i].creador._id != this.user" class="btn btn-primary btn-block" (click)="borrarmeDeRuta(ruta)">Borrarme de la ruta</button>


                                    <!-- <div *ngFor="let user of ruta.usuarios; let i=index" class="alert alert-success">



                    </div> -->
                                    <!-- <div *ngIf="this.misRutas[i].creador._id == this.user">{{ruta.usuarios[i].email}}</div> -->
                                    <!-- <p class="card-text">Creada por : {{ruta.creador}}</p> -->


                                    <!-- <button [routerLink]="['/ruta',ruta._id]" class="btn btn-primary btn-block">Ver Detalle</button> -->
                                    <button *ngIf="this.misRutas[i].creador._id == this.user" [routerLink]="['/editarRuta',ruta._id]" class="btn btn-primary btn-block  ">Editar</button>



                                </div>
                            </div>
                            <div class="alert alert-warning mt-3 mx-3 " role="alert" *ngIf=" this.ruta.paradasSolicitadas.length > 0 && this.ruta.creador._id == this.userService.usuarioLogged._id">
                                <p>Tienes una solicitud de parada en tu ruta</p>
                                <button [routerLink]="['/ruta',ruta._id]" class="btn  btn-block btn-verSolicitud  ">Ver solicitud</button>
                            </div>
                        </div>
                    </div>
                </div>











            </div>
            <div class="col-md-2" style=" background: url('./assets/images/img_rutas_right_2.jpg') no-repeat -360px -36px;">


            </div>
            <!--col derecha-->
        </div>
    </div>
</section>
