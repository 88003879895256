import { UserService } from './services/user.service';
import { HttpClientModule } from '@angular/common/http';
import {
  Component,
  ViewChild,
  TemplateRef,
  ElementRef,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Keepalive } from '@ng-idle/keepalive';
import { filter } from 'rxjs/operators';

declare let gtag: Function;



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Docentes en ruta';

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  public modalRef: BsModalRef;

  @ViewChild('childModal', { static: false }) childModal: ModalDirective;

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private modalService: BsModalService,
    public userService: UserService
  ) {

    
    // const navEndEvents$ = this.router.events
    // .pipe(
    //   filter(event => event instanceof NavigationEnd)
    // );
  

    // navEndEvents$.subscribe((event: NavigationEnd) => {
    //   gtag('config', 'G-XSQ82FB9YN', {
    //     'page_path': event.urlAfterRedirects
    //   });
    // });


    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(3600);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      // console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      // this.childModal.hide();
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.logout();
      // console.log(this.idleState);
      this.router.navigate(['/']);
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      //  console.log(this.idleState);
      //  this.childModal.show();
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      // console.log(this.idleState);
    });

    idle.watch();

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    // this.appService.getUserLoggedIn().subscribe(userLoggedIn => {
    //   if (userLoggedIn) {
    //     idle.watch()
    //     this.timedOut = false;
    //   } else {
    //     idle.stop();
    //   }
    // })

    // this.reset();
  }
  ngOnInit() {
    this.setUpAnalytics();

}

onActivate(event) {
  // window.scroll(0,0);

  window.scroll({ 
          top: 0, 
          left: 0, 
          behavior: 'auto' 
   });

   //or document.body.scrollTop = 0;
   //or document.querySelector('body').scrollTo(0,0)
   
}
  setUpAnalytics() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
            gtag('config', 'G-XSQ82FB9YN',
                {
                    page_path: event.urlAfterRedirects
                }
            );
        });
}
  reset() {
    this.idle.watch();
    //xthis.idleState = 'Started.';
    this.timedOut = false;
  }

  hideChildModal(): void {
    // this.childModal.hide();
  }

  stay() {
    // this.childModal.hide();
    this.reset();
  }

  logout() {
    //   this.childModal.hide();
    this.userService.logout();
    this.router.navigate(['/']);
  }
}
