import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SendMailService {

  constructor(

    private httpClient: HttpClient,
    private router: Router){



  }



  sendMail(email) {

    // const params = JSON.stringify(email);
    const url = 'https://docentesenruta.eu-4.evennode.com/emails/sendMail/' + email;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, { headers });


  }
  sendMailBorraRuta(email) {

    // const params = JSON.stringify(email);
    const url = 'https://docentesenruta.eu-4.evennode.com/emails/sendMailBorraRuta/' + email;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, { headers });

  }
  sendMailBorradoDeTuRuta(email) {

    // const params = JSON.stringify(email);
    const url = 'https://docentesenruta.eu-4.evennode.com/emails/sendMailBorradoDeTuRuta/' + email;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, { headers });

  }
  sendMailUneATuRuta(email) {

    // const params = JSON.stringify(email);
    const url = 'https://docentesenruta.eu-4.evennode.com/emails/sendMailUneATuRuta/' + email;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, { headers });

  }
  sendMailContacto(emailContacto) {

    const params = JSON.stringify(emailContacto);
    const url = 'https://docentesenruta.eu-4.evennode.com/emails/sendMailContacto' ;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, params, { headers });


  }


}
