import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubirImgsService } from 'src/app/services/subir-imgs.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { MensajePisoModel } from 'src/app/models/mensajePiso.model';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-piso',
  templateUrl: './piso.component.html',
  styleUrls: ['./piso.component.css']
})
export class PisoComponent implements OnInit {

  piso: any = {};
  pisoId;
  image;
  email;
  nombre;
  mensaje;
  enviado = false;
  imgCollection: Array<object> = [
  ];
  mensajePiso: MensajePisoModel;


  constructor(
    private pisosService: SubirImgsService,
    private activateRuta: ActivatedRoute,
    private location: Location,
  ) { }

  ngOnInit(): void {

    this.mensajePiso = new MensajePisoModel();

    this.pisoId = this.activateRuta.snapshot.paramMap.get('id');
    this.getPiso();
    this.mensajePiso.mensaje = 'Estoy interesad@ en el piso'


  }


  getPiso() {
    this.pisosService.getPiso(this.pisoId).subscribe((res: any) => {
      this.piso = res.piso;

      for (let i = 0; i < this.piso.imgs.length; i++) {
        this.imgCollection.push({ image: this.piso.imgs[i], thumbImage: this.piso.imgs[i] });
      }
      this.mensajePiso.destinatario = this.piso.creador.email;
    });
  }

  back(): void {
    this.location.back();
  }

  enviarMensaje() {
    console.log(this.mensajePiso)



    try {
      this.pisosService.sendMailPiso(this.mensajePiso).subscribe((res: any) => {
       this.enviado = true;

      });
    } catch (err) {

    }

    // this.pisosService.sendMailPiso(this.mensajePiso).subscribe((res: any) => {


    // });

  }


}
